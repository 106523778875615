import React, { Component } from "react"

import {
  Button,
  Image,
  OverlayTrigger,
  Table,
  Tooltip,
} from "@themesberg/react-bootstrap"
import Paginator from "../../dashboard/components/Paginator"
import chargerIcon from "../../../assets/tv/chargerIcon@3x.png"

import ac_on from "../../../assets/tv/ac/blue@2x.png"
import ac_off from "../../../assets/tv/ac/grey@2x.png"

import ccs_on from "../../../assets/tv/ccs/blue@2x.png"
import ccs_off from "../../../assets/tv/ccs/grey@2x.png"

import chademo_on from "../../../assets/tv/chademo/blue@2x.png"
import chademo_off from "../../../assets/tv/chademo/grey@2x.png"

export default class ChargersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      chargers: this.props.chargers,
    }
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Table>
          <thead style={{ backgroundColor: "rgba(162, 179, 184, 0.2)" }}>
            <tr>
              <th style={{ width: 100 }} className="border-0"></th>
              <th
                className="border-0"
                style={{ color: "#62757A", textAlign: "left", fontSize: 14 }}
              >
                {window.strings.name}
              </th>
              <th
                className="border-0"
                style={{ textAlign: "left", color: "#62757A", fontSize: 14 }}
              >
                {window.strings.type}
              </th>
              <th
                className="border-0"
                style={{ textAlign: "left", color: "#62757A", fontSize: 14 }}
              >
                {window.strings.location}
              </th>
              {this.props.selectedCharger != null && (
                <th className="border-0"></th>
              )}
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#ffffff" }}>
            {this.props.selectedCharger === null ? (
              this.props.chargers.map((charger, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      this.props.selectCharger(charger)
                    }}
                    style={{
                      cursor: "pointer",
                      borderBottomWidth: 1,
                      borderColor: "rgba(162, 179, 184, 0.2)",
                    }}
                  >
                    <td className="border-0 " style={{ textAlign: "center" }}>
                      <Image
                        src={chargerIcon}
                        style={{ color: "#62757A", width: 32 }}
                      />
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#041114",
                        fontSize: 15,
                        textAlign: "left",
                        paddingLeft: 15,
                      }}
                    >
                      {charger.box_id}
                    </td>

                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {charger.connectors !== undefined &&
                        charger.connectors
                          .sort((a, b) => (a.type_id < b.type_id ? 1 : -1))
                          .map((c, index) => {
                            if (c.type_id != null) {
                              return (
                                <OverlayTrigger
                                  key={index}
                                  placement="bottom"
                                  trigger={["hover", "focus"]}
                                  overlay={
                                    <Tooltip className="custom_tooltip">
                                      <span
                                        key={index}
                                        style={{ color: "#fff", fontSize: 15 }}
                                      >
                                        {c.type}
                                      </span>
                                    </Tooltip>
                                  }
                                >
                                  <Image
                                    src={
                                      (c.status == "Available" ||
                                        c.status == null) &&
                                      c.type == "CHAdeMO"
                                        ? chademo_on
                                        : c.status != "Available" &&
                                          c.status != null &&
                                          c.type == "CHAdeMO"
                                        ? chademo_off
                                        : (c.status == "Available" ||
                                            c.status == null) &&
                                          c.type == "AC"
                                        ? ac_on
                                        : c.status != "Available" &&
                                          c.status != null &&
                                          c.type == "AC"
                                        ? ac_off
                                        : (c.status == "Available" ||
                                            c.status == null) &&
                                          c.type == "CCS"
                                        ? ccs_on
                                        : ccs_off
                                    }
                                    style={{ width: 30, marginRight: 15 }}
                                  />
                                </OverlayTrigger>
                              )
                            }
                          })}
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {charger.location !== undefined && charger.location}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr
                style={{
                  borderBottomWidth: 1,
                  borderColor: "rgba(162, 179, 184, 0.2)",
                }}
              >
                <td className="border-0 " style={{ textAlign: "center" }}>
                  <Image
                    src={chargerIcon}
                    style={{ color: "#62757A", width: 32 }}
                  />
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{
                    color: "#041114",
                    fontSize: 15,
                    textAlign: "left",
                    paddingLeft: 15,
                  }}
                >
                  {this.props.selectedCharger.box_id}
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{ color: "#62757A", fontSize: 15, paddingLeft: 15 }}
                >
                  {this.props.selectedCharger.connectors !== undefined &&
                    this.props.selectedCharger.connectors
                      .sort((a, b) => (a.type_id < b.type_id ? 1 : -1))
                      .map((c, index) => {
                        if (c.type_id != null) {
                          return (
                            <Image
                              key={index}
                              src={
                                (c.status == "Available" || c.status == null) &&
                                c.type == "CHAdeMO"
                                  ? chademo_on
                                  : c.status != "Available" &&
                                    c.status != null &&
                                    c.type == "CHAdeMO"
                                  ? chademo_off
                                  : (c.status == "Available" ||
                                      c.status == null) &&
                                    c.type == "AC"
                                  ? ac_on
                                  : c.status != "Available" &&
                                    c.status != null &&
                                    c.type == "AC"
                                  ? ac_off
                                  : (c.status == "Available" ||
                                      c.status == null) &&
                                    c.type == "CCS"
                                  ? ccs_on
                                  : ccs_off
                              }
                              style={{ width: 30, marginRight: 15 }}
                            />
                          )
                        }
                      })}
                </td>

                <td
                  className="border-0 fw-bold"
                  style={{ color: "#62757A", fontSize: 15, paddingLeft: 15 }}
                >
                  {this.props.selectedCharger.location !== undefined &&
                    this.props.selectedCharger.location}
                </td>

                <td className="border-0 fw-bold">
                  <Button
                    variant="close"
                    aria-label="Close"
                    onClick={this.props.closeSelected}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Paginator
          items={Array.from(Array(this.props.total_results).keys())}
          onChangePage={(page) => {
            this.setState({ loading: true }, () => {
              this.props.updateChargersResultForPage(page, () => {
                this.setState({ loading: false })
              })
            })
          }}
        />
        {this.state.loading && (
          <div
            className="loading"
            style={{
              position: "absolute",
              textAlign: "center",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              top: 0,
              left: 0,
              zIndex: 10,
            }}
          >
            <div
              className="spinner-border text-light"
              role="status"
              style={{ position: "absolute", top: "50%" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    )
  }
}
