import React, { Component } from "react"

import {
  Table,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons"
import EditModal from "./EditModal"
import UserModal from "./UserModal"
import User from "../../../Service/User"
export default class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: this.props.users,
      selectedUserIndex: null,
      selectedSearchUser: null,
      showEditUser: false,
      showUserModal: false,
      selected_user: {},
    }
    this.isOperatorRole = this.props.profile.role === "operator"
  }

  closeOverlay() {
    this.setState({ selectedUserIndex: null, selectedSearchUser: null })
  }

  selectUserBox(index) {
    this.setState({ selectedUserIndex: index })
  }

  selectSearchUserBox(data) {
    this.setState({ selectedSearchUser: data })
  }

  closeEditUserModal() {
    this.setState({ showEditUser: false })
  }

  closeUserModal() {
    this.setState({ showUserModal: false })
  }

  editUser(error, msg) {
    if (!error) {
      // let data = {
      //   corporate_id:this.props.active_corporate.id,
      // }
      // this.props.getUsers(data)
      this.props.getUsers()
    }
    this.props.showAlert(error, msg)
  }

  deleteUser(user) {
    let data = {
      email: user.email,
      // corporate_id: this.props.active_corporate.id,
    }
    // let corporate = {
    //   corporate_id: this.props.active_corporate.id,
    // }
    User.deleteUser(data, (res) => {
      if (!res.error) {
        // this.props.getUsers(corporate)
        this.props.getUsers()
        this.closeOverlay()
        this.props.showAlert(res.error, res.message)
      } else {
        this.props.showAlert(res.error, res.message)
      }
    })
  }

  resendPw(user) {
    let data = {
      email: user.email,
      // corporate_id: this.props.active_corporate.id,
    }
    User.resendPw(data, (res) => {
      if (!res.error) {
        this.closeOverlay()
        this.props.showAlert(res.error, res.message)
      } else {
        this.props.showAlert(res.error, res.message)
      }
    })
  }

  showEditModal() {
    this.setState({ showEditUser: true, showUserModal: false })
  }

  render() {
    return (
      <div>
        <Table>
          <thead style={{ backgroundColor: "rgba(162, 179, 184, 0.2)" }}>
            <tr>
              <th className="border-0"></th>
              <th
                className="border-0"
                style={{ color: "#62757A", textAlign: "left", fontSize: 14 }}
              >
                {window.strings.name}
              </th>
              <th
                className="border-0"
                style={{ textAlign: "left", color: "#62757A", fontSize: 14 }}
              >
                EMAIL
              </th>
              <th
                className="border-0"
                style={{ textAlign: "left", color: "#62757A", fontSize: 14 }}
              >
                {window.strings.location}
              </th>
              <th
                className="border-0"
                style={{
                  textAlign: "left",
                  color: "#62757A",
                  fontSize: 14,
                }}
              >
                {!this.isOperatorRole ? window.strings.state : ""}
              </th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#ffffff" }}>
            {this.props.selectedUser === null ? (
              this.props.users.map((user, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      borderBottomWidth: 1,
                      borderColor: "rgba(162, 179, 184, 0.2)",
                    }}
                  >
                    <td
                      onClick={() => {
                        this.setState({
                          showUserModal: true,
                          selected_user: user,
                        })
                      }}
                      className="border-0 "
                      style={{ textAlign: "center", cursor: "pointer" }}
                    >
                      <span
                        style={{
                          marginRight: 12,
                          color: "#01B4E0",
                          fontSize: 17,
                          padding: 10,
                          alignSelf: "center",
                          textAlign: "center",
                          display: "inline-block",
                          minWidth: 50,
                          backgroundColor: "rgba(1, 180, 224, 0.1)",
                          textTransform: "uppercase",
                        }}
                      >
                        <>
                          {user.first_name[0]}
                          {user.last_name[0]}
                        </>
                      </span>
                    </td>
                    <td
                      onClick={() => {
                        this.setState({
                          showUserModal: true,
                          selected_user: user,
                        })
                      }}
                      className="border-0 fw-bold"
                      style={{
                        color: "#041114",
                        fontSize: 15,
                        textAlign: "left",
                        paddingLeft: 15,
                        paddingTop: 25,
                        alignSelf: "center",
                        cursor: "pointer",
                      }}
                    >
                      {user.first_name} {user.last_name}
                    </td>
                    <td
                      onClick={() => {
                        this.setState({
                          showUserModal: true,
                          selected_user: user,
                        })
                      }}
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingTop: 25,
                        paddingLeft: 15,
                        cursor: "pointer",
                      }}
                    >
                      {user.email}
                    </td>
                    <td
                      onClick={() => {
                        this.setState({
                          showUserModal: true,
                          selected_user: user,
                        })
                      }}
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingTop: 25,
                        paddingLeft: 15,
                        cursor: "pointer",
                      }}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        trigger={["hover", "focus"]}
                        overlay={
                          <Tooltip className="tooltip">
                            {user.locations !== undefined &&
                              user.locations.map((location, index) => {
                                return (
                                  <span
                                    key={index}
                                    style={{ color: "#fff", fontSize: 15 }}
                                  >
                                    {index !== 0 && ", "}
                                    {location.name}
                                  </span>
                                )
                              })}
                          </Tooltip>
                        }
                      >
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: 250,
                          }}
                        >
                          {user.locations !== undefined &&
                            user.locations.map((location, index) => {
                              return (
                                <span
                                  key={index}
                                  style={{ color: "#62757A", fontSize: 15 }}
                                >
                                  {index !== 0 && ", "}
                                  {location.name}
                                </span>
                              )
                            })}
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td className="border-0 fw-bold" style={{ paddingTop: 25 }}>
                      {!this.isOperatorRole ? (
                        <span
                          onClick={() => {
                            this.setState({
                              showUserModal: true,
                              selected_user: user,
                            })
                          }}
                          style={{
                            fontWeight: "bold",
                            color: user.active === 1 ? "#07B865" : "#D0342C",
                            fontSize: 13,
                            paddingLeft: 15,
                            paddingTop: 25,
                            cursor: "pointer",
                          }}
                        >
                          {user.active === 1
                            ? window.strings.active
                            : window.strings.inactive}
                        </span>
                      ) : null}
                      <div
                        className={"custom-drop"}
                        style={{
                          cursor: "pointer",
                          display:
                            this.state.selectedUserIndex === index
                              ? "flex"
                              : "none",
                          position: "absolute",
                          backgroundColor: "#ffffff",
                          flexDirection: "column",
                          zIndex: 9999,
                          minWidth: 220,
                          transitionProperty: "all",
                          transitionDuration: "1s",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#041114",
                            fontSize: 15,
                            padding: 23,
                          }}
                          onClick={() => {
                            this.closeOverlay()
                            this.setState({
                              showEditUser: true,
                              selected_user: user,
                            })
                          }}
                        >
                          {window.strings.editUser}
                        </span>
                        <span
                          style={{
                            border: "1px solid rgba(162, 179, 184, 0.2)",
                          }}
                        />
                        <span
                          onClick={this.deleteUser.bind(this, user)}
                          style={{
                            fontWeight: "bold",
                            color: "#041114",
                            fontSize: 15,
                            padding: 23,
                          }}
                        >
                          {window.strings.removeUser}
                        </span>
                        <span
                          style={{
                            border: "1px solid rgba(162, 179, 184, 0.2)",
                          }}
                        />
                        <span
                          onClick={this.resendPw.bind(this, user)}
                          style={{
                            fontWeight: "bold",
                            color: "#041114",
                            fontSize: 15,
                            padding: 23,
                          }}
                        >
                          {window.strings.resendPassword}
                        </span>
                      </div>
                    </td>

                    <td
                      onClick={this.selectUserBox.bind(this, index)}
                      className="border-0 "
                    >
                      {!this.isOperatorRole ? (
                        <FontAwesomeIcon
                          icon={faEllipsisH}
                          style={{
                            cursor: "pointer",
                            color: "#A2B3B8",
                            marginTop: 15,
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr
                style={{
                  borderBottomWidth: 1,
                  borderColor: "rgba(162, 179, 184, 0.2)",
                }}
              >
                <td className="border-0 " style={{ textAlign: "center" }}>
                  <span
                    style={{
                      marginRight: 12,
                      color: "#01B4E0",
                      fontSize: 17,
                      padding: 10,
                      alignSelf: "center",
                      textAlign: "center",
                      display: "inline-block",
                      minWidth: 50,
                      backgroundColor: "rgba(1, 180, 224, 0.1)",
                      textTransform: "uppercase",
                    }}
                  >
                    {this.props.selectedUser.first_name[0]}
                    {this.props.selectedUser.last_name[0]}
                  </span>
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{
                    color: "#041114",
                    fontSize: 15,
                    textAlign: "left",
                    paddingLeft: 15,
                    paddingTop: 25,
                    alignSelf: "center",
                  }}
                >
                  {this.props.selectedUser.first_name}
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{
                    color: "#62757A",
                    fontSize: 15,
                    paddingTop: 25,
                    paddingLeft: 15,
                  }}
                >
                  {this.props.selectedUser.email}
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{
                    color: "#62757A",
                    fontSize: 15,
                    paddingTop: 25,
                    paddingLeft: 15,
                  }}
                >
                  {this.props.selectedUser.location}
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{
                    fontWeight: "bold",
                    color:
                      this.props.selectedUser.status === 1
                        ? "#07B865"
                        : "#D0342C",
                    fontSize: 13,
                    paddingLeft: 15,
                    paddingTop: 25,
                  }}
                >
                  {this.props.selectedUser.status === 1
                    ? window.strings.active
                    : window.strings.inactive}
                  <div
                    className={"custom-drop"}
                    style={{
                      cursor: "pointer",
                      display:
                        this.state.selectedSearchUser === true
                          ? "flex"
                          : "none",
                      position: "absolute",
                      backgroundColor: "#ffffff",
                      flexDirection: "column",
                      zIndex: 9999,
                      minWidth: 220,
                      transitionProperty: "all",
                      transitionDuration: "1s",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#041114",
                        fontSize: 15,
                        padding: 23,
                      }}
                      onClick={() => {
                        this.closeOverlay()
                        this.setState({
                          showEditUser: true,
                          selected_user: this.props.selectedUser,
                        })
                      }}
                    >
                      {window.strings.editUser}
                    </span>

                    <span
                      style={{
                        border: "1px solid rgba(162, 179, 184, 0.2)",
                      }}
                    />
                    <span
                      onClick={this.deleteUser.bind(
                        this,
                        this.props.selectedUser
                      )}
                      style={{
                        fontWeight: "bold",
                        color: "#041114",
                        fontSize: 15,
                        padding: 23,
                      }}
                    >
                      {window.strings.removeUser}
                    </span>
                    <span
                      style={{
                        border: "1px solid rgba(162, 179, 184, 0.2)",
                      }}
                    />
                    <span
                      onClick={this.resendPw.bind(
                        this,
                        this.props.selectedUser
                      )}
                      style={{
                        fontWeight: "bold",
                        color: "#041114",
                        fontSize: 15,
                        padding: 23,
                      }}
                    >
                      {window.strings.resendPassword}
                    </span>
                  </div>
                </td>

                <td
                  onClick={this.selectSearchUserBox.bind(this, true)}
                  className="border-0 "
                >
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    style={{
                      cursor: "pointer",
                      color: "#A2B3B8",
                      marginTop: 15,
                    }}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {this.state.showEditUser && (
          <EditModal
            user={this.state.selected_user}
            selected_locations={this.props.selected_locations}
            // active_corporate={this.props.active_corporate}
            editUser={this.editUser.bind(this)}
            showEditUser={this.state.showEditUser}
            closeEditUserModal={this.closeEditUserModal.bind(this)}
          />
        )}
        {this.state.showUserModal && (
          <UserModal
            user={this.state.selected_user}
            // active_corporate={this.props.active_corporate}
            showEditModal={this.showEditModal.bind(this)}
            showUserModal={this.state.showUserModal}
            closeUserModal={this.closeUserModal.bind(this)}
          />
        )}
        {(this.state.selectedUserIndex !== null ||
          this.state.selectedSearchUser !== null) && (
          <div onClick={this.closeOverlay.bind(this)} id={"custom-overlay"}>
            {" "}
          </div>
        )}
      </div>
    )
  }
}
