import React, { Component, Fragment } from "react"
import { Modal, Accordion } from "@themesberg/react-bootstrap"
import { Calendar } from "react-modern-calendar-datepicker"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import stringfile from "../utils/stringfile"
window.strings = stringfile.getStrings()
export default class CalendarModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: this.props.showModal,
      locations: this.props.locations,

      freqs: [
        {
          id: "anual",
          type: window.strings.freq1,
          selected: false,
        },
        {
          id: "mensal",
          type: window.strings.freq2,
          selected: true,
        },
        {
          id: "semanal",
          type: window.strings.freq3,
          selected: false,
        },
        {
          id: "diario",
          type: window.strings.freq4,
          selected: false,
        },
        {
          id: "horaria",
          type: window.strings.freq5,
          selected: false,
        },
      ],
      fromHour: 0,
      toHour: 24,
      selectedDates: this.props.selectedDates,
      frequency: this.props.frequency,
      defaultActiveToHour: 0,
      defaultActiveFromHour: 0,
      myCustomLocale: {
        // months list by order
        months: window.strings.months,

        // week days by order
        weekDays: window.strings.weekDays,

        weekStartingIndex: 0,

        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject) {
          return gregorainTodayObject
        },

        // return a native JavaScript date here
        toNativeDate(date) {
          return new Date(date.year, date.month - 1, date.day)
        },

        // return a number for date's month length
        getMonthLength(date) {
          return new Date(date.year, date.month, 0).getDate()
        },

        // return a transformed digit to your locale
        transformDigit(digit) {
          return digit
        },

        nextMonth: window.strings.nextMonth,
        previousMonth: window.strings.previousMonth,
        openMonthSelector: window.strings.openMonthSelector,
        openYearSelector: window.strings.openYearSelector,
        closeMonthSelector: window.strings.closeMonthSelector,
        closeYearSelector: window.strings.closeYearSelector,
        defaultPlaceholder: window.strings.defaultPlaceholder,

        // for input range value
        from: "from",
        to: "to",

        // used for input value when multi dates are selected
        digitSeparator: ",",

        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,

        // is your language rtl or ltr?
        isRtl: false,
      },
    }
    this.oncloseModal = this.oncloseModal.bind(this)
    this.apply = this.apply.bind(this)
  }

  componentDidMount() {
    let freq = this.state.frequency
    for (let index = 0; index < this.state.freqs.length; index++) {
      if (this.state.freqs[index].id === freq) {
        this.changeFrequency(index)
      }
    }
  }

  oncloseModal() {
    this.props.closeModal(() => {
      this.setState({ showModal: false })
    })
  }

  apply() {
    let selectedDates = this.state.selectedDates
    let freq = this.state.frequency
    let date = {
      fromHour: this.state.fromHour,
      toHour: this.state.toHour === 24 ? 0 : this.state.toHour,
      from:
        selectedDates.from.year +
        "-" +
        selectedDates.from.month +
        "-" +
        selectedDates.from.day +
        " " +
        this.state.fromHour +
        ":00:00.000",
      to:
        selectedDates.to.year +
        "-" +
        selectedDates.to.month +
        "-" +
        selectedDates.to.day +
        " " +
        (this.state.toHour === 24 ? 23 : this.state.toHour) +
        ":59:59.000",
    }
    this.props.selectDates(date, freq, () => {
      this.oncloseModal()
    })
  }

  changeFrequency(index) {
    let freqs = this.state.freqs
    let freq = this.state.frequency
    let data = this.state.selectedDates

    freqs.forEach((element) => {
      element.selected = false
    })

    freqs[index].selected = !freqs[index].selected
    freq = freqs[index].id

    this.setState({ freqs, frequency: freq, selectedDates: data })
  }

  pickDates(data) {
    this.setState({ selectedDates: data })
  }

  renderBody() {
    return (
      <div style={{ margin: "25px 30px" }}>
        <span style={{ fontSize: 15, color: "#62757A" }}>
          {window.strings.freq}
        </span>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            borderRadius: 2,
            border: "1px solid rgba(162, 179, 184, 0.4)",
            borderLeft: 0,
            marginTop: 8,
          }}
        >
          {this.state.freqs !== undefined &&
            this.state.freqs.map((freq, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flex: 1,
                    backgroundColor: freq.selected ? "#00B8EB" : "transparent",
                    borderLeft:
                      !freq.selected && "1px solid rgba(162, 179, 184, 0.4)",
                    borderRight: 0,
                    padding: "14px 0px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={this.changeFrequency.bind(this, index)}
                >
                  <span
                    style={{
                      fontSize: 17,
                      color: freq.selected ? "#ffffff" : "#62757A",
                      fontWeight: "bold",
                    }}
                  >
                    {freq.id === "anual"
                      ? window.strings.freq1
                      : freq.id === "mensal"
                      ? window.strings.freq2
                      : freq.id === "semanal"
                      ? window.strings.freq3
                      : freq.id === "diario"
                      ? window.strings.freq4
                      : freq.id === "horaria"
                      ? window.strings.freq5
                      : window.strings.freq5}
                  </span>
                </div>
              )
            })}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            // minHeight: 80,
            alignItems: "flex-start",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Accordion
            defaultActiveKey={"0"}
            // onSelect={(e) => {
            //   if (this.state.defaultActiveFromHour == 0) {
            //     this.state.defaultActiveFromHour = 1;
            //     this.setState({ defaultActiveFromHour: 1 });
            //   } else {
            //     this.state.defaultActiveFromHour = 0;
            //     this.setState({ defaultActiveFromHour: 0 });
            //   }
            // }}
            style={{
              display: "flex",
              flex: 1,
              marginRight: 10,
            }}
          >
            <Accordion.Item
              style={{ flex: 1 }}
              eventKey={this.state.defaultActiveFromHour}
            >
              <Accordion.Button className={"accordion-custom-header"}>
                <span style={{}}>{String(this.state.fromHour) + ":00"}</span>
              </Accordion.Button>

              <Accordion.Body
                style={{
                  backgroundColor: "#ffffff",
                }}
              >
                <div
                  style={{
                    maxHeight: 250,
                    overflow: "scroll",
                    overflowX: "hidden",
                    backgroundColor: "white",
                  }}
                >
                  {[...Array(24).keys()].map((item, index) => {
                    return (
                      <Fragment key={`from-hour-${item}`}>
                        {this.state.fromHour === 0 ||
                        item < this.state.toHour ? (
                          <div
                            disabled={item > this.state.fromHour}
                            onClick={() => {
                              this.setState({
                                fromHour: item,
                                defaultActiveFromHour: index,
                              })
                            }}
                            style={{
                              cursor: "pointer",
                              padding: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                              backgroundColor:
                                item === this.state.fromHour
                                  ? "rgba(0, 184, 235, 0.1)"
                                  : "transparent",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {String(item) + ":00"}
                            </span>
                          </div>
                        ) : (
                          <div
                            style={{
                              padding: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                              backgroundColor: "transparent",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "rgba(162, 179, 184, 0.2)",
                              }}
                            >
                              {String(item) + ":00"}
                            </span>
                          </div>
                        )}
                      </Fragment>
                    )
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion
            defaultActiveKey={"0"}
            // onSelect={(e) => {
            //   if (this.state.defaultActiveToHour == 0) {
            //     this.state.defaultActiveToHour = 1;
            //     this.setState({ defaultActiveToHour: 1 });
            //   } else {
            //     this.state.defaultActiveToHour = 0;
            //     this.setState({ defaultActiveToHour: 0 });
            //   }
            // }}
            style={{
              display: "flex",
              flex: 1,
              marginLeft: 10,
            }}
          >
            <Accordion.Item
              style={{ flex: 1 }}
              eventKey={this.state.defaultActiveToHour}
            >
              <Accordion.Button className={"accordion-custom-header"}>
                <span style={{}}>
                  {String(this.state.toHour === 24 ? 0 : this.state.toHour) +
                    ":00"}
                </span>
              </Accordion.Button>
              <Accordion.Body
                style={{
                  backgroundColor: "#ffffff",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    maxHeight: 250,
                    overflow: "scroll",
                    overflowX: "hidden",
                    backgroundColor: "white",
                  }}
                >
                  {[...Array(25).keys()].map((item, index) => {
                    return (
                      <Fragment key={`to-hour-${item}`}>
                        {item > this.state.fromHour ? (
                          <div
                            // key={index}
                            // disabled={item < this.state.toHour}
                            onClick={() => {
                              this.setState({
                                toHour: item,
                                defaultActiveToHour: index,
                              })
                            }}
                            style={{
                              cursor: "pointer",
                              padding: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                              backgroundColor:
                                item === this.state.toHour
                                  ? "rgba(0, 184, 235, 0.1)"
                                  : "transparent",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {String(item === 24 ? 0 : item) + ":00"}
                            </span>
                          </div>
                        ) : (
                          <div
                            // key={index}
                            style={{
                              padding: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                              backgroundColor: "transparent",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "rgba(162, 179, 184, 0.2)",
                              }}
                            >
                              {String(item === 24 ? 0 : item) + ":00"}
                            </span>
                          </div>
                        )}
                      </Fragment>
                    )
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <Calendar
          locale={{
            // months list by order
            months: window.strings.months,

            // week days by order
            weekDays: window.strings.weekDays,

            weekStartingIndex: 0,

            // return a { year: number, month: number, day: number } object
            getToday(gregorainTodayObject) {
              return gregorainTodayObject
            },

            // return a native JavaScript date here
            toNativeDate(date) {
              return new Date(date.year, date.month - 1, date.day)
            },

            // return a number for date's month length
            getMonthLength(date) {
              return new Date(date.year, date.month, 0).getDate()
            },

            // return a transformed digit to your locale
            transformDigit(digit) {
              return digit
            },

            nextMonth: window.strings.nextMonth,
            previousMonth: window.strings.previousMonth,
            openMonthSelector: window.strings.openMonthSelector,
            openYearSelector: window.strings.openYearSelector,
            closeMonthSelector: window.strings.closeMonthSelector,
            closeYearSelector: window.strings.closeYearSelector,
            defaultPlaceholder: window.strings.defaultPlaceholder,

            // for input range value
            from: "from",
            to: "to",

            // used for input value when multi dates are selected
            digitSeparator: ",",

            // if your provide -2 for example, year will be 2 digited
            yearLetterSkip: 0,

            // is your language rtl or ltr?
            isRtl: false,
          }}
          calendarClassName={"calendar-content"}
          colorPrimary={"#00B8EB"}
          calendarSelectedDayClassName={"calendar-selected"}
          colorPrimaryLight={"rgba(0, 184, 235, 0.4)"}
          value={this.state.selectedDates}
          onChange={this.pickDates.bind(this)}
        />
      </div>
    )
  }

  renderFooter() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <div
          onClick={this.oncloseModal.bind(this)}
          style={{
            flex: 1,
            padding: "18px 0px",
            border: "1px solid rgba(162, 179, 184, 0.4)",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <span style={{ fontSize: 17, fontWeight: "bold", color: "#62757A" }}>
            {window.strings.cancel}
          </span>
        </div>

        <div
          onClick={this.apply.bind(this)}
          style={{
            padding: "18px 0px",
            backgroundColor: "#00B8EB",
            cursor: "pointer",
            flex: 1,
            textAlign: "center",
          }}
        >
          <span style={{ fontSize: 17, fontWeight: "bold", color: "#ffffff" }}>
            {window.strings.apply}
          </span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Modal
        as={Modal.Dialog}
        show={this.props.showModal}
        onHide={this.oncloseModal.bind(this)}
        contentClassName={"location-modal"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: 21, color: "#041114" }}
          >
            {window.strings.selectTime}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, borderRadius: 2 }}>
          {this.renderBody()}
        </Modal.Body>
        {this.renderFooter()}
      </Modal>
    )
  }
}
