// [
//     'corporate_id'		'required|integer', //do teu perfil
//     'locations_id'	=>	'array', //do teu perfil
//     'frequency'	=>	'required|string', //do teu perfil
//     'from'	=>	'required|string', //do teu perfil Y-m-d H:m:s
//     'to'	=>	'required|string', //do teu perfil Y-m-d H:m:s
// ];

import Service from "./Service"
import Tools from "./../utils/Tools"
var host = process.env.REACT_APP_POWERDOT_TV_BACKEND_HOST + "/tv/v1/charts"
class Dashboards {
  getDashboards(data, callback) {
    //data_last_freq
    // Tools.removeData(JSON.stringify(data));
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/get/resume?token=" + token,
        data,
        (response) => {
          let k
          let final_data = {
            frequency:
              data.frequency === "horaria"
                ? "horaria"
                : data.frequency === "diario"
                ? "dia"
                : data.frequency === "semanal"
                ? "semanal"
                : data.frequency === "mensal"
                ? "mês"
                : data.frequency === "anual"
                ? "ano"
                : "semanal",
            res: response,
            labels: [],
            charges: [],
            charges_var: [],
            consumed: [],
            consumed_var: [],
            time: [],
            time_var: [],
            spared: [],
            spared_var: [],

            best_charges_date: {
              date: {},
              total: 0,
            },
            best_consumed_date: {
              date: {},
              total: 0,
            },
            best_time_date: {
              date: {},
              total: 0,
            },
            best_spared_date: {
              date: {},
              total: 0,
            },
          }
          if (response.data.data.length > 0) {
            final_data.charges_stats = {
              lastMonth:
                response.data.last_period_total_numero_de_carregamentos,
              lastMonth_perc:
                response.data.last_period_perc_total_numero_de_carregamentos,
            }
            final_data.consumed_stats = {
              lastMonth: response.data.last_period_total_energia_consumida,
              lastMonth_perc:
                response.data.last_period_perc_total_energia_consumida,
            }
            final_data.time_stats = {
              lastMonth: response.data.last_period_total_duracao_carregamento,
              lastMonth_perc:
                response.data.last_period_perc_total_duracao_carregamento,
            }
            final_data.spared_stats = {
              lastMonth: response.data.last_period_total_co2,
              lastMonth_perc: response.data.last_period_perc_total_co2,
            }
          } else {
            final_data.charges_stats = {
              lastMonth: 0,
              lastMonth_perc: 0,
            }
            final_data.consumed_stats = {
              lastMonth: 0,
              lastMonth_perc: 0,
            }
            final_data.time_stats = {
              lastMonth: 0,
              lastMonth_perc: 0,
            }
            final_data.spared_stats = {
              lastMonth: 0,
              lastMonth_perc: 0,
            }
          }

          if (data.frequency === "horaria") {
            for (k = 0; k < response.data.data.length; k++) {
              if (
                final_data.best_charges_date.total <
                response.data.data[k].total_numero_de_carregamentos
              ) {
                final_data.best_charges_date.total =
                  response.data.data[k].total_numero_de_carregamentos
                final_data.best_charges_date.date.hora =
                  response.data.data[k].hora
              }
              if (
                final_data.best_consumed_date.total <
                response.data.data[k].total_energia_consumida
              ) {
                final_data.best_consumed_date.total =
                  response.data.data[k].total_energia_consumida
                final_data.best_consumed_date.date.hora =
                  response.data.data[k].hora
              }
              if (
                final_data.best_time_date.total <
                response.data.data[k].total_duracao_carregamento
              ) {
                final_data.best_time_date.total =
                  response.data.data[k].total_duracao_carregamento
                final_data.best_time_date.date.hora = response.data.data[k].hora
              }
              if (
                final_data.best_spared_date.total <
                response.data.data[k].total_co2
              ) {
                final_data.best_spared_date.total =
                  response.data.data[k].total_co2
                final_data.best_spared_date.date.hora =
                  response.data.data[k].hora
              }

              final_data.charges.push(
                response.data.data[k].total_numero_de_carregamentos
              )
              final_data.charges_var.push({
                last: response.data.data[k][
                  "total_numero_de_carregamentos #change"
                ],
                evo: response.data.data[k][
                  "total_numero_de_carregamentos % change"
                ],
              })
              final_data.consumed.push(
                response.data.data[k].total_energia_consumida
              )
              final_data.consumed_var.push({
                last: response.data.data[k]["total_energia_consumida #change"],
                evo: response.data.data[k]["total_energia_consumida % change"],
              })
              final_data.time.push(
                response.data.data[k].total_duracao_carregamento
              )
              final_data.time_var.push({
                last: response.data.data[k][
                  "total_duracao_carregamento #change"
                ],
                evo: response.data.data[k][
                  "total_duracao_carregamento % change"
                ],
              })

              final_data.spared.push(response.data.data[k].total_co2)
              final_data.spared_var.push({
                last: response.data.data[k]["total_co2 #change"],
                evo: response.data.data[k]["total_co2 % change"],
              })
              final_data.labels.push(response.data.data[k].hora + "h ")
            }
          } else if (data.frequency === "diario") {
            for (k = 0; k < response.data.data.length; k++) {
              if (
                final_data.best_charges_date.total <
                response.data.data[k].total_numero_de_carregamentos
              ) {
                final_data.best_charges_date.total =
                  response.data.data[k].total_numero_de_carregamentos
                final_data.best_charges_date.date.dia =
                  response.data.data[k].dia
                final_data.best_charges_date.date.mes =
                  response.data.data[k].mes
                final_data.best_charges_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_consumed_date.total <
                response.data.data[k].total_energia_consumida
              ) {
                final_data.best_consumed_date.total =
                  response.data.data[k].total_energia_consumida
                final_data.best_consumed_date.date.dia =
                  response.data.data[k].dia
                final_data.best_consumed_date.date.mes =
                  response.data.data[k].mes
                final_data.best_consumed_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_time_date.total <
                response.data.data[k].total_duracao_carregamento
              ) {
                final_data.best_time_date.total =
                  response.data.data[k].total_duracao_carregamento
                final_data.best_time_date.date.dia = response.data.data[k].dia
                final_data.best_time_date.date.mes = response.data.data[k].mes
                final_data.best_time_date.date.ano = response.data.data[k].ano
              }
              if (
                final_data.best_spared_date.total <
                response.data.data[k].total_co2
              ) {
                final_data.best_spared_date.total =
                  response.data.data[k].total_co2
                final_data.best_spared_date.date.dia = response.data.data[k].dia
                final_data.best_spared_date.date.mes = response.data.data[k].mes
                final_data.best_spared_date.date.ano = response.data.data[k].ano
              }

              final_data.charges.push(
                response.data.data[k].total_numero_de_carregamentos
              )
              final_data.charges_var.push({
                last: response.data.data[k][
                  "total_numero_de_carregamentos #change"
                ],
                evo: response.data.data[k][
                  "total_numero_de_carregamentos % change"
                ],
              })
              final_data.consumed.push(
                response.data.data[k].total_energia_consumida
              )
              final_data.consumed_var.push({
                last: response.data.data[k]["total_energia_consumida #change"],
                evo: response.data.data[k]["total_energia_consumida % change"],
              })
              final_data.time.push(
                response.data.data[k].total_duracao_carregamento
              )
              final_data.time_var.push({
                last: response.data.data[k][
                  "total_duracao_carregamento #change"
                ],
                evo: response.data.data[k][
                  "total_duracao_carregamento % change"
                ],
              })

              final_data.spared.push(response.data.data[k].total_co2)
              final_data.spared_var.push({
                last: response.data.data[k]["total_co2 #change"],
                evo: response.data.data[k]["total_co2 % change"],
              })
              final_data.labels.push(
                response.data.data[k].dia +
                  "-" +
                  response.data.data[k].mes +
                  "-" +
                  response.data.data[k].ano
              )
            }
          } else if (data.frequency === "semanal") {
            for (k = 0; k < response.data.data.length; k++) {
              if (
                final_data.best_charges_date.total <
                response.data.data[k].total_numero_de_carregamentos
              ) {
                final_data.best_charges_date.total =
                  response.data.data[k].total_numero_de_carregamentos
                final_data.best_charges_date.date.week =
                  response.data.data[k].week
                final_data.best_charges_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_consumed_date.total <
                response.data.data[k].total_energia_consumida
              ) {
                final_data.best_consumed_date.total =
                  response.data.data[k].total_energia_consumida
                final_data.best_consumed_date.date.week =
                  response.data.data[k].week
                final_data.best_consumed_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_time_date.total <
                response.data.data[k].total_duracao_carregamento
              ) {
                final_data.best_time_date.total =
                  response.data.data[k].total_duracao_carregamento
                final_data.best_time_date.date.week = response.data.data[k].week
                final_data.best_time_date.date.ano = response.data.data[k].ano
              }
              if (
                final_data.best_spared_date.total <
                response.data.data[k].total_co2
              ) {
                final_data.best_spared_date.total =
                  response.data.data[k].total_co2
                final_data.best_spared_date.date.week =
                  response.data.data[k].week
                final_data.best_spared_date.date.ano = response.data.data[k].ano
              }

              final_data.charges.push(
                response.data.data[k].total_numero_de_carregamentos
              )
              final_data.charges_var.push({
                last: response.data.data[k][
                  "total_numero_de_carregamentos #change"
                ],
                evo: response.data.data[k][
                  "total_numero_de_carregamentos % change"
                ],
              })
              final_data.consumed.push(
                response.data.data[k].total_energia_consumida
              )
              final_data.consumed_var.push({
                last: response.data.data[k]["total_energia_consumida #change"],
                evo: response.data.data[k]["total_energia_consumida % change"],
              })
              final_data.time.push(
                response.data.data[k].total_duracao_carregamento
              )
              final_data.time_var.push({
                last: response.data.data[k][
                  "total_duracao_carregamento #change"
                ],
                evo: response.data.data[k][
                  "total_duracao_carregamento % change"
                ],
              })
              final_data.spared.push(response.data.data[k].total_co2)
              final_data.spared_var.push({
                last: response.data.data[k]["total_co2 #change"],
                evo: response.data.data[k]["total_co2 % change"],
              })
              final_data.labels.push(`${window.strings.week} ${response.data.data[k].semana}- ${response.data.data[k].ano}`
              )
            }
          } else if (data.frequency === "mensal") {
            for (k = 0; k < response.data.data.length; k++) {
              if (
                final_data.best_charges_date.total <
                response.data.data[k].total_numero_de_carregamentos
              ) {
                final_data.best_charges_date.total =
                  response.data.data[k].total_numero_de_carregamentos
                final_data.best_charges_date.date.mes =
                  response.data.data[k].mes
                final_data.best_charges_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_consumed_date.total <
                response.data.data[k].total_energia_consumida
              ) {
                final_data.best_consumed_date.total =
                  response.data.data[k].total_energia_consumida
                final_data.best_consumed_date.date.mes =
                  response.data.data[k].mes
                final_data.best_consumed_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_time_date.total <
                response.data.data[k].total_duracao_carregamento
              ) {
                final_data.best_time_date.total =
                  response.data.data[k].total_duracao_carregamento
                final_data.best_time_date.date.mes = response.data.data[k].mes
                final_data.best_time_date.date.ano = response.data.data[k].ano
              }
              if (
                final_data.best_spared_date.total <
                response.data.data[k].total_co2
              ) {
                final_data.best_spared_date.total =
                  response.data.data[k].total_co2
                final_data.best_spared_date.date.mes = response.data.data[k].mes
                final_data.best_spared_date.date.ano = response.data.data[k].ano
              }

              final_data.charges.push(
                response.data.data[k].total_numero_de_carregamentos
              )
              final_data.charges_var.push({
                last: response.data.data[k][
                  "total_numero_de_carregamentos #change"
                ],
                evo: response.data.data[k][
                  "total_numero_de_carregamentos % change"
                ],
              })
              final_data.consumed.push(
                response.data.data[k].total_energia_consumida
              )
              final_data.consumed_var.push({
                last: response.data.data[k]["total_energia_consumida #change"],
                evo: response.data.data[k]["total_energia_consumida % change"],
              })
              final_data.time.push(
                response.data.data[k].total_duracao_carregamento
              )
              final_data.time_var.push({
                last: response.data.data[k][
                  "total_duracao_carregamento #change"
                ],
                evo: response.data.data[k][
                  "total_duracao_carregamento % change"
                ],
              })

              final_data.spared.push(response.data.data[k].total_co2)
              final_data.spared_var.push({
                last: response.data.data[k]["total_co2 #change"],
                evo: response.data.data[k]["total_co2 % change"],
              })

              let switch_variable = response.data.data[k].mes
              let labelspush = final_data.labels

              let year_data = response.data.data[k].ano

              switch (switch_variable) {
                case 1:
                  labelspush.push("Jan. " + String(year_data).slice(-2))
                  break
                case 2:
                  labelspush.push("Fev. " + String(year_data).slice(-2))
                  break
                case 3:
                  labelspush.push("Mar. " + String(year_data).slice(-2))
                  break
                case 4:
                  labelspush.push("Abr. " + String(year_data).slice(-2))
                  break
                case 5:
                  labelspush.push("Mai. " + String(year_data).slice(-2))
                  break
                case 6:
                  labelspush.push("Jun. " + String(year_data).slice(-2))
                  break
                case 7:
                  labelspush.push("Jul. " + String(year_data).slice(-2))
                  break
                case 8:
                  labelspush.push("Ago. " + String(year_data).slice(-2))
                  break
                case 9:
                  labelspush.push("Set. " + String(year_data).slice(-2))
                  break
                case 10:
                  labelspush.push("Out. " + String(year_data).slice(-2))
                  break
                case 11:
                  labelspush.push("Nov. " + String(year_data).slice(-2))
                  break
                case 12:
                  labelspush.push("Dez. " + String(year_data).slice(-2))
                  break
                default:
                  labelspush.push("Jan. " + String(year_data).slice(-2))
              }
            }
          } else if (data.frequency === "anual") {
            for (k = 0; k < response.data.data.length; k++) {
              if (
                final_data.best_charges_date.total <
                response.data.data[k].total_numero_de_carregamentos
              ) {
                final_data.best_charges_date.total =
                  response.data.data[k].total_numero_de_carregamentos
                final_data.best_charges_date.date.mes =
                  response.data.data[k].mes
                final_data.best_charges_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_consumed_date.total <
                response.data.data[k].total_energia_consumida
              ) {
                final_data.best_consumed_date.total =
                  response.data.data[k].total_energia_consumida
                final_data.best_consumed_date.date.mes =
                  response.data.data[k].mes
                final_data.best_consumed_date.date.ano =
                  response.data.data[k].ano
              }
              if (
                final_data.best_time_date.total <
                response.data.data[k].total_duracao_carregamento
              ) {
                final_data.best_time_date.total =
                  response.data.data[k].total_duracao_carregamento
                final_data.best_time_date.date.mes = response.data.data[k].mes
                final_data.best_time_date.date.ano = response.data.data[k].ano
              }
              if (
                final_data.best_spared_date.total <
                response.data.data[k].total_co2
              ) {
                final_data.best_spared_date.total =
                  response.data.data[k].total_co2
                final_data.best_spared_date.date.mes = response.data.data[k].mes
                final_data.best_spared_date.date.ano = response.data.data[k].ano
              }

              final_data.charges.push(
                response.data.data[k].total_numero_de_carregamentos
              )
              final_data.charges_var.push({
                last: response.data.data[k][
                  "total_numero_de_carregamentos #change"
                ],
                evo: response.data.data[k][
                  "total_numero_de_carregamentos % change"
                ],
              })
              final_data.consumed.push(
                response.data.data[k].total_energia_consumida
              )
              final_data.consumed_var.push({
                last: response.data.data[k]["total_energia_consumida #change"],
                evo: response.data.data[k]["total_energia_consumida % change"],
              })
              final_data.time.push(
                response.data.data[k].total_duracao_carregamento
              )
              final_data.time_var.push({
                last: response.data.data[k][
                  "total_duracao_carregamento #change"
                ],
                evo: response.data.data[k][
                  "total_duracao_carregamento % change"
                ],
              })

              final_data.spared.push(response.data.data[k].total_co2)
              final_data.spared_var.push({
                last: response.data.data[k]["total_co2 #change"],
                evo: response.data.data[k]["total_co2 % change"],
              })
              final_data.labels.push(response.data.data[k].ano)
            }
          }
          callback(final_data)
        }
      )
    })
  }

  getLastPeriod(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/get/last?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }
}
export default new Dashboards()
