export default {
  successMsg: "Sukces",
  errorMsg: "Błąd",
  passwordsNotMatching: "Błędne hasło",
  insertPassword: "Wpisz hasło",
  insertEmail: "Wpisz e-mail",
  repeat: "Powtórz",
  complete: "Ukończone",
  password: "Hasło",
  definePassword: "Ustaw hasło",
  passwordEmailSent:
    "Wiadomość e-mail z szczegółami dotyczącymi aktywacji konta została wysłana",
  terms: "Zasady i warunki",
  privacyPolicy: "Polityka prywatności",
  recover: "Odzyskaj",
  insert: "Wstaw",
  errorInf: "Błąd podczas uzyskiwania informacji",
  chargers: "Stacje ładowania",
  allChargers: "Wszystkie ładowarki",
  historyCharges: "Historia sesji ładowania",
  name: "Nazwa",
  type: "Typ",
  location: "Lokalizacja",
  last: "ostatni",
  days: "dni",
  from: "z ",
  to: "do",
  revenue: "Dochód",
  mostUsedCharger: "Najczęściej używana stacja ładowania",
  charges: "Sesje ładowania",
  charges2: "Sesje ładowania",
  compareToLastPeriod: "porównaniu do ostatniego okresu",
  evolution: "Wzrost",
  searchForCharger: "Wyszukaj stacje ładowania",
  searchForUser: "Wyszukaj użytkownika",
  totalCharges: "Wszytskie sesje",
  hour: "Godzina",
  day: "Dzień",
  week: "Tydzień",
  metrics: "Metryka",
  month: "Miesiąc",
  year: "Rok",
  consumed: "Wykorzystane",
  minutesCharged: "Czas ładowania",
  chargersLastYear: "Sesje ładowania (ubiegły rok)",
  totalMinutesCharged: "Całkowity czas ładowania",
  totalCO2Spared: "Zaoszczędzone CO2",
  spared: "zaoszczędzone",
  user: "użytkownik",
  users: "Użytkownicy",
  charger: "Stacja ładowania",
  date: "Data",
  time: "Czas",
  consumption: "Zużycie",
  sendFeedback: "Wyślij opinię",
  subject: "Temat",
  subjectPlaceholder: "Wybierz temat",
  message: "Wiadomość",
  goback: "Wstecz",
  sendMessage: "Wyślij wiadomość",
  newUser: "Nowy użytkownik",
  allUsers: "Wszyscy użytkownicy",
  emptyUsersList: "Dodaj nowych użytkowników do swojego zespołu",
  emptyUsersListDesc:
    "Po dodaniu użytkownika zostanie automatycznie wysłana wiadomość e-mail z danymi aktywacyjnymi konta",
  warningInvalidName: "Niewłaściwe imię!",
  warningInvalidSurName: "Niewłaściwe nazwisko!",
  warningInvalidEmail: "Niewłaściwy e-mail!",
  warningLocations: "Wprowadź przynajmniej jedną lokalizację!",
  createUser: "Stwórz nowego użytkownika",
  surname: "Nazwisko",
  endEmail: "Adres e-mail",
  manageLocations: "Zarządzane lokalizacje",
  chooseOption: "Wybierz opcje",
  createUser2: "Stwórz Użytkownika",
  cancel: "Anuluj",
  editUser: "Edytuj użytkownika",
  saveChanges: "Zapisz zmiany",
  state: "Status",
  removeUser: "Usuń użytkownika",
  resendPassword: "Wyślij hasło ponownie",
  noLocations: "Brak dostępnych lokalizacji",
  noLocationsDesc: "Brak dostępnych lokalizacji",
  corporate: "Partner",
  corporates: "Partnerzy",
  hello: "Witaj!",
  overview: "Widok ogólny",
  locations: "Lokalizacje",
  location2: "Lokalizacje",
  seeAll: "Zobacz wszystko",
  logout: "Wyloguj",
  applyFilter: "Filtruj",
  selectLocations: "Wybierz lokalizacje do wyświetlenia",
  anyLocation: "Wszystkie lokalizacje",
  resume: "Podsumowanie",
  period: "Okres",
  editAccountData: "Edytuj dane konta",
  editData: "Edytuj dane",
  idioma: "Język",
  portuguese: "Polski",
  months: [
    "January Styczeń",
    "February Luty",
    "March Marzec",
    "April Kwiecień",
    "May Maj",
    "June Czerwiec",
    "July Lipiec",
    "August Sierpień",
    "September Wrzesień",
    "October Październik",
    "November Listopad",
    "December Grudzień",
  ],
  weekDays: [
    {
      name: "Niedziela",
      short: "Nd",
      isWeekend: true,
    },
    {
      name: "Poniedziałek",
      short: "Pon ",
    },
    {
      name: "Wtorek",
      short: "Wt ",
    },
    {
      name: "Środa",
      short: "Śr",
    },
    {
      name: "Czwartek",
      short: "Czw ",
    },
    {
      name: "Piątek",
      short: "Pt ",
    },
    {
      name: "Sobota",
      short: "Sob ",
      isWeekend: true,
    },
  ],
  nextMonth: "Kolejny miesiąc",
  previousMonth: "Poprzedni miesiąc",
  freq1: "Roczny",
  freq2: "Miesięćzny",
  freq3: "Tygodniowy",
  freq4: "Dzienny",
  freq5: "Godzinowy",
  freq: "Częstotliwość",
  apply: "Zatwierdź",
  selectTime: "Wybierz częstotliwość",
  welcomeToPowerdot: "Witamy w Power Dot TV!",
  step1_desc:
    "W tym portalu możesz zobaczyć statystyki stacji ładowania w Twojej lokalizacji!",
  continue: "Kontynuuj",
  step2_title: "Poznaj statystki",
  step2_desc: "Pomiń samouczek",
  start: "Dalej",
  step3_1_desc:
    "Na ekranie widoku ogólnego możesz sprawdzić główne wskaźniki dotyczące liczby sesji, zużytej energii, czasu ładowania i zaoszczędzonego C02!",
  step3_2_desc: "Filtruj dane według lokalizacji",
  step3_3_desc:
    "Filtruj dane według stacji ładowania. Dowiedz się, która ładowarka ma najlepszą wydajność.",
  export: "Eksportuj",
  sameTimeofLastYear: "Ten sam okres roku poprzedniego",
  contact: "Numer telefonu",
  management: "Zarządzaj",
  langs: {
    PT: "Portugalski",
    EN: "Angielski",
    FR: "Francuski",
    PL: "Polski",
    ES: "Hiszpański",
  },
  signinWarningTitle: "Niepoprawny e-mail lub hasło",
  signinWarningDesc:
    "Wprowadzone dane są niepoprawne, spróbuj ponownie lub odzyskaj hasło",
  signin: "Zaloguj się do Power Dot TV",
  emailSent:
    "Użyj swoich danych, aby się zalogować. To jest platforma wyłącznie dla partnerów. Jeśli nie masz dostępu, skontaktuj się z Power Dot.",
  saveEmail: "zapisz mój e-mail",
  forgotPassword: "Zapomniałeś hasła?",
  compareToLastPoint: "W porównaniu do poprzedniego punktu",
  compareToPreviousPeriod: "porównaniu do ostatniego okresu",
  sendFeedbackType1: "Problem z danymi",
  sendFeedbackType2: "Problem z użytkowaniem platformy",
  sendFeedbackType3: "Problem z dostępem",
  sendFeedbackType4: "Inny",
  sendFeedbackMsgPlaceholder: "Napisz wiadomość",
  withMoreCharges: "z + sesjami",
  withMorekWh: "przy zużyciu + kWh",
  withMoreMinutes: "z naładowanymi + minutami",
  withMoreSpared: "z + CO2 oszczędzonym",
  last30days: "Ostatnie 30 dni",
  lastYearNoData: "rak danych za okres roku poprzedniego",
  active: "Aktywny",
  inactive: "Nieaktywny",
  minutes: "Minuty",
  loading: "Ładowanie",
  faq: "NZP",
  faqLink: "https://5796726.hs-sites.com/pl/faqs",
  contactUs: "Skontaktuj się z nami",
  contactUsLink: "https://5796726.hs-sites.com/pl/kb-tickets/new",
}
