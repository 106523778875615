import Service from "./Service"
import Tools from "./../utils/Tools"

var host = process.env.REACT_APP_POWERDOT_TV_BACKEND_HOST + "/tv/v1/chargers"

class Chargers {
  getBestCharger(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/get/best?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  getChargers(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/get/all?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  getChargersByLocation(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/get/locations?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }
}
export default new Chargers()
