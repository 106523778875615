import React, { Component } from "react";
import {
  Button,
  Modal,
  Image,
} from "@themesberg/react-bootstrap";
import powerdotIcon from "../../../assets/tv/powerdot-Icon.png";

export default class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: this.props.showUserModal,
      user: this.props.user,
      warningName: "",
      warningSurname: "",
      warningEmail: "",
      warningSelected_locations: "",
      selected_locations: this.props.selected_locations,
    };
    this.oncloseModal = this.oncloseModal.bind(this);
  }

  edituser(){
    this.props.showEditModal(() => {
      this.setState({ showUserModal: false });
    });
  }

  oncloseModal() {
    this.props.closeUserModal(() => {
      this.setState({ showUserModal: false });
    });
  }

  render() {
    return (
      <Modal
        as={Modal.Dialog}
        show={this.props.showUserModal}
        onHide={this.oncloseModal.bind(this)}
        id={"user-modal"}
        centered
      >
        <Modal.Header className="h5" style={{ alignItems: "flex-start", borderWidth:0, }}>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 1 }}>
            <span
              style={{
                position: "absolute",
                color: "#01B4E0",
                fontSize: 17,
                padding: "60px 50px",
                alignSelf: "center",
                textAlign: "center",
                display: "inline-block",
                minWidth: 150,
                backgroundColor: "#E6F8FC",
                top: -20,
                zIndex: 9999999,
                textTransform:"uppercase",
              }}
            >
              <>
                {this.props.user.first_name[0]}
                {this.props.user.last_name[0]}
              </>
            </span>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Button
              style={{ padding: 36 }}
              variant="close"
              aria-label="Close"
              onClick={this.oncloseModal.bind(this)}
            />
          </div>
        </Modal.Header>
        <Modal.Body style={{padding:0}}>
          <div style={{display:"flex", flexDirection:"column",padding:0,paddingTop:30,paddingBottom:28}}>
            <div style={{padding:36,paddingBottom:24,display:"flex",flexDirection:"row",alignItems:"center"}}>
              <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                <span style={{fontSize:20,color:"#041114"}}>{this.props.user.first_name[0]}{" "}{this.props.user.last_name}</span>
                <span style={{marginTop:4,fontSize:17,color:"#62757A"}}>{this.props.user.name}</span>
              </div>
              <div style={{alignItems:"center",padding:10,cursor:"pointer"}}
              onClick={this.edituser.bind(this)}
              >
              <span style={{padding:"14px 25px", color:"#041114", fontSize:15, fontWeight:"bold", border:"1px solid rgba(162, 179, 184, 0.4)", borderRadius:16,}}>{window.strings.editData}</span>
              </div>
            </div>
            <span style={{height:1,width:"100%", backgroundColor:"rgba(162, 179, 184, 0.2)", borderRadius:1, }} />
            <div style={{padding:36,paddingBottom:0,display:"flex",flexDirection:"column"}}>
                <span style={{fontSize:15,color:"#62757A"}}>Email</span>
                <span style={{marginTop:4,fontSize:17,color:"#041114"}}>{this.props.user.email}</span>
            </div>
            <div style={{padding:36,paddingBottom:0,display:"flex",flexDirection:"column"}}>
                <span style={{fontSize:15,color:"#62757A"}}>{window.strings.contact}</span>
                <span style={{marginTop:4,fontSize:17,color:"#041114"}}>{this.props.user.telefone}</span>
            </div>
            {this.props.user.locations != undefined && this.props.user.locations.length>0 &&
            <div style={{padding:36,display:"flex",flexDirection:"column"}}>
                <span style={{fontSize:15,color:"#62757A"}}>{window.strings.management}</span>
                <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",maxHeight:150,overflowY:"scroll",marginTop:10}}>
                {this.props.user.locations.map((location,index)=>{
                  return(
                    <div style={{display:"flex",flexDirection:"column",padding:15, alignItems:"center", justifyContent:"center"}} key={index}>
                    <Image src={location.image === null ? powerdotIcon : location.image} style={{width:60, flex:1}} />
                    <span  style={{fontSize:17,color:"#041114", marginTop:8}}>{location.name}</span>
                    </div>
                  )
                })}
                 </div>
            </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
