import React, { useState, useEffect } from "react"
import { Route } from "react-router-dom"
import { hotjar } from "react-hotjar"

import User from "../../Service/User"

import Sidebar from "../../components/Sidebar"
import DashboardsNavbar from "../../components/DashboardsNavbar"

import Tools from "../../utils/Tools"
import stringfile from "../../utils/stringfile"

export default function RouteWithSidebar({ component: Component, ...rest }) {
  const [profile, setProfile] = useState(undefined)
  const [navLocation, setNavLocation] = useState({})

  useEffect(() => {
    User.getProfile((res) => {
      if (!res.error) {
        window.language = res.profile.user.lang
        window.strings = stringfile.getStrings()

        if (window.location.href.split(".")[2] !== undefined) {
          let lang = (
            window.location.href.split(".")[2][0] +
            window.location.href.split(".")[2][[1]]
          ).toUpperCase()

          if (lang === "FR") {
            hotjar.initialize(2732277, 6)
          } else if (lang === "ES") {
            hotjar.initialize(2763140, 6)
          } else if (lang === "PL") {
            hotjar.initialize(2763135, 6)
          } else {
            hotjar.initialize(2649909, 6)
          }
        } else {
          hotjar.initialize(2649909, 6)
        }

        setProfile(res.profile)
      } else {
        window.location.href = "/Signin"
      }
    })
  }, [])

  function changeNavLocation(data) {
    setNavLocation(data)
    Tools.setData("saved_navLocation", data)
    window.location.href = "/"
  }

  const totalChargers = (profile?.user.locations ?? []).reduce(
    (totalChargers, location) => {
      return totalChargers + location.number_chargers
    },
    0
  )

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {profile?.user ? (
            <>
              <Sidebar
                profile={profile.user}
                navLocation={navLocation}
                totalChargers={totalChargers}
                locations={profile.user.locations.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                changeNavLocation={changeNavLocation}
              />

              <main className="content">
                <DashboardsNavbar profile={profile.user} />

                <Component
                  {...props}
                  changeNavLocation={changeNavLocation}
                  totalChargers={totalChargers}
                  locations={profile.user.locations}
                  profile={profile.user}
                />
              </main>
            </>
          ) : null}
        </>
      )}
    />
  )
}
