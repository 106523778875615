import dayjs from "dayjs"

class Tools {
  setData(key, data) {
    localStorage.setItem(key, data)
  }

  setDataWithTTL(key, data, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: data,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  getDataWithTTL(key, callback) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (itemStr == null || itemStr == undefined || itemStr == "") {
      callback && callback(null)
    } else {
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and callback && callback(null)
        localStorage.removeItem(key)
        callback && callback(null)
      } else {
        callback && callback(item.value)
      }
    }
  }

  getData(key, callback) {
    callback(localStorage.getItem(key))
  }

  returnData(key) {
    return localStorage.getItem(key)
  }

  removeData(key) {
    localStorage.removeItem(key)
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  getDefaultCurrency() {
    // get the window.location.href and get the currency from the url .pt, .pl, .be., .fr, .es
    // if the currency is not in the url, should be euro
    let defaultCountry = /:\/\/([^\/]+)/.exec(window.location.href)[1]

    if (defaultCountry.indexOf("pt") > -1) {
      return "€"
    }
    if (defaultCountry.indexOf("pl") > -1) {
      return "zł"
    }
    if (defaultCountry.indexOf("be") > -1) {
      return "€"
    }
    if (defaultCountry.indexOf("fr") > -1) {
      return "€"
    }
    if (defaultCountry.indexOf("es") > -1) {
      return "€"
    }
    return "€"
  }
  getMonthString(m) {
    let month = ""
    switch (m) {
      case 1:
        month = "Jan."
        break
      case 2:
        month = "Fev."
        break
      case 3:
        month = "Mar."
        break
      case 4:
        month = "Abr."
        break
      case 5:
        month = "Mai."
        break
      case 6:
        month = "Jun."
        break
      case 7:
        month = "Jul."
        break
      case 8:
        month = "Ago."
        break
      case 9:
        month = "Set."
        break
      case 10:
        month = "Out."
        break
      case 11:
        month = "Nov."
        break
      case 12:
        month = "Dez."
        break
      default:
        month = "Jan."
    }
    return month
  }

  frequencyUnitMapper(frequency) {
    const frequencies = {
      horaria: "hour",
      diario: "day",
      semanal: "week",
      mensal: "month",
      anual: "year",
    }

    return frequencies[frequency]
  }

  isPastDate(date, frequency) {
    const frequencyUnit = this.frequencyUnitMapper(frequency)

    return dayjs(date)
      .endOf("day")
      .isBefore(dayjs().endOf("day"), frequencyUnit)
  }

  styliseDataMarkers(dataSource, colors) {
    return (dataSource ?? []).map(
      (_, idx, list) => colors[idx < list.length - 1 ? "default" : "last"]
    )
  }
}

export default new Tools()
