import React,{Component} from 'react';
import {
    Button,
    Modal,
    Form,
    InputGroup,
  } from "@themesberg/react-bootstrap";
import User from "../Service/User"
export default class EditModal extends Component {
constructor(props) {
    super(props);
    this.state = {
        showEditUser:this.props.showEditUser,
        name:this.props.user.first_name,
        surname:this.props.user.last_name,
        email:this.props.user.email,
        warningName:"",
        warningSurname:"",
        warningEmail:"",
    };
    this.oncloseModal = this.oncloseModal.bind(this);
  }

  oncloseModal(message) {
    this.props.closeEditUserModal(true,message,()=>{
      this.setState({ showEditUser: false });
    })
  }


  handleName(event){
    if(this.state.warningName !== ""){
    this.setState({name:event.target.value,warningName:""})
    }else{
    this.setState({name:event.target.value})
    }
}
    
handleSurname(event){
    if(this.state.warningSurname !== ""){
        this.setState({surname:event.target.value,warningSurname:""})
        }else{
        this.setState({surname:event.target.value})
        }
    }


  handleEmail(event){
    if(this.state.warningEmail !== ""){
        this.setState({email:event.target.value,warningEmail:""})
        }else{
        this.setState({email:event.target.value})
        }
    }



  edit(){
    let error = false;
    // if(this.state.name.length==0){
    //     this.setState({warningName:"Nome Inválido!"});
    //     error = true;
    // }  
    // if(this.state.surname.length==0){
    //     this.setState({warningSurname:"Apelido Inválido!"});
    //     error = true;
    // } 
    // if(this.state.email.length==0){
    //     this.setState({warningEmail:"Email Inválido!"});
    //     error = true;
    // }
    // let selected_locations = this.state.selected_locations;
    // if(selected_locations.filter((e)=>e.selected === true)==0){
    //     this.setState({warningSelected_locations:"Introduza um espaço no mínimo!"});
    //     error = true;
    // } 

    if(!error){
        let data = {
            first_name:this.state.name,
            last_name:this.state.surname,
            email:this.state.email,
        }
        
        User.edit(data,(res)=>{
            if(!res.error){
              this.oncloseModal(res.message);
              
            }else{
              this.props.closeEditUserModal(false,res.message)
            }
          })

    }


  }

  
    render() {
        return (
            <Modal
            as={Modal.Dialog}
            show={this.props.showEditUser}
            onHide={this.oncloseModal.bind(this,undefined)}
          >
                <Modal.Header className="h5" style={{alignItems:"flex-start"}} >
               <div style={{display:"flex", flexDirection:"column",padding:20,paddingBottom:0}}>
               <div style={{display:"flex", flexDirection:"row",marginBottom:40}}>
               <div
                          style={{
                            marginRight: 12,
                            alignSelf:"center",
                            textAlign:"center",
                            minWidth:50,
                            backgroundColor: "rgba(1, 180, 224, 0.1)",
                            padding:10,
                          }}
                        >
                        <span
                          style={{
                            color:"#01B4E0",
                            fontSize:17,
                            textTransform:"uppercase",
                          }}
                        >
                          {this.props.user.first_name[0]}
                          {this.props.user.last_name[0]}
                        </span>
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                <span style={{fontSize:20,color:"#041114"}}>{this.props.user.first_name[0]}{" "}{this.props.user.last_name}</span>
                <span style={{marginTop:4,fontSize:17,color:"#62757A"}}>{this.props.user.email}</span>
              </div>

                </div>
               <span>{window.strings.editUser}</span>
               </div>
                <Button
                style={{padding:36}}
                variant="close"
                aria-label="Close"
                onClick={this.oncloseModal.bind(this,undefined)}
              />
              </Modal.Header>
            <Modal.Body style={{paddingLeft:30,paddingRight:30}}>
            <Form style={{display:"flex",flexDirection:"row"}}>
                    <Form.Group id="email" className="mb-4" style={{marginRight:15}}>
                      <Form.Label>{window.strings.name}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          onChange={this.handleName.bind(this)}
                          autoFocus
                          required
                          
                          value={this.state.name}
                          placeholder={window.strings.name}
                        />
                      </InputGroup>
                      {this.state.warningName.length>0 && 
                      <Form.Label style={{color:"#D0342C"}}>{this.state.warningName}</Form.Label>
                      }
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="text" className="mb-4">
                        <Form.Label>{window.strings.surname}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            onChange={this.handleSurname.bind(this)}
                            required
                            value={this.state.surname}
                            placeholder={window.strings.surname}
                          />
                        </InputGroup>
                        {this.state.warningSurname.length>0 && 
                     <Form.Label style={{color:"#D0342C"}}>{this.state.warningSurname}</Form.Label>}
                      </Form.Group>
                    </Form.Group>

                  </Form>
                  <Form  >
                    <Form.Group id="email" className="mb-4" style={{marginRight:15}}>
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <Form.Control
                          onChange={this.handleEmail.bind(this)}
                          autoFocus
                          required
                          type="email"
                          value={this.state.email}
                          placeholder={this.props.user_email}
                        />
                      </InputGroup>
                      {this.state.warningEmail.length>0 && 
                     <Form.Label style={{color:"#D0342C"}}>{this.state.warningEmail}</Form.Label>}
                    </Form.Group>
                  </Form>
            
            </Modal.Body>
            <Modal.Footer style={{padding:0,margin:0}}>
            <div
                style={{flex:1,margin:0,cursor:"pointer",padding:"23px 0px",alignItems:"center",justifyContent:"center",textAlign:"center",color:"#62757A",fontSize:17}}
                onClick={this.oncloseModal.bind(this,undefined)}
              >
             {window.strings.cancel}
                  </div>
                <div
                style={{flex:1,margin:0,cursor:"pointer",padding:"23px 0px",alignItems:"center",justifyContent:"center",textAlign:"center",color:"#ffffff",fontSize:17,backgroundColor:"#00B8EB"}}
                onClick={this.edit.bind(this)}
              >
            {window.strings.saveChanges}
            </div>
             </Modal.Footer>
          </Modal>

        );
    }
}
