import React, { Component } from "react"

export default class LocationDataTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#ffffff",
          padding: 16,
          minHeight: 250,
        }}
      >
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          {/* <div style={{flex:1, textAlign:"end"}}>
                <Image src={exportIcon} style={{width:24}} />
            </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: "5px 0px",
              backgroundColor: "#ffffff",
              flex: 1,
            }}
          >
            <span style={{ fontSize: 17, color: "#62757A" }}>
              {this.props.metricTotalTitle}
            </span>
            <span
              style={{
                fontSize: this.props.subtitle?.length > 0 ? 20 : 40,
                color: "#041114",
              }}
            >
              {this.props.metricTotal}{" "}
              <span style={{ fontSize: 20, color: "#62757A" }}>
                {" "}
                {this.props.unit}
              </span>
            </span>
            <span style={{ fontSize: 17, color: "#62757A" }}>
              {this.props.subtitle}
            </span>
          </div>
          {this.props.evo != undefined ? (
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    this.props.evo > 0
                      ? "rgba(7,184,101,0.08)"
                      : "rgba(208, 52, 44, 0.08)",
                  border:
                    this.props.evo > 0
                      ? "1px solid rgba(7,184,101,0.08)"
                      : "1px solid rgba(208, 52, 44, 0.08)",
                  borderRadius: 2,
                  padding: "6px 12px",
                  marginBottom: 4,
                }}
              >
                <span
                  style={{
                    flex: 1,
                    fontSize: 17,
                    color: this.props.evo > 0 ? "#07B865" : "#D0342C",
                    fontWeight: "bold",
                  }}
                >
                  {this.props.evo > 0
                    ? "+ " + this.props.lastMonth
                    : this.props.evo < 0
                    ? this.props.lastMonth.toString().slice(0, 1) +
                      " " +
                      this.props.lastMonth.toString().slice(1)
                    : this.props.lastMonth}
                </span>
                <span style={{ fontSize: 14, color: "#537A68" }}>
                  {window.strings.compareToLastPeriod}
                </span>
              </div>
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    this.props.evo > 0
                      ? "rgba(7,184,101,0.08)"
                      : "rgba(208, 52, 44, 0.08)",
                  border:
                    this.props.evo > 0
                      ? "1px solid rgba(7,184,101,0.08)"
                      : "1px solid rgba(208, 52, 44, 0.08)",
                  borderRadius: 2,
                  padding: "6px 12px",
                  marginBottom: 4,
                }}
              >
                <span
                  style={{
                    flex: 1,
                    fontSize: 17,
                    color: this.props.evo > 0 ? "#07B865" : "#D0342C",
                    fontWeight: "bold",
                  }}
                >
                  {this.props.evo > 0
                    ? "+ " + this.props.evo + "%"
                    : this.props.evo < 0
                    ? this.props.evo.toString().slice(0, 1) +
                      " " +
                      this.props.evo.toString().slice(1) +
                      "%"
                    : this.props.evo + "%"}
                </span>
                <span style={{ fontSize: 14, color: "#537A68" }}>
                  {window.strings.evolution}
                </span>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#f5f8fb",
                  border: "1px solid #f5f8fb",
                  borderRadius: 2,
                  padding: "6px 12px",
                  flex: 1,
                  marginBottom: 4,
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#f5f8fb",
                  border: "1px solid #f5f8fb",
                  borderRadius: 2,
                  padding: "6px 12px",
                  flex: 1,
                  marginBottom: 4,
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
