import React, { Component } from "react"
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Accordion,
} from "@themesberg/react-bootstrap"
import User from "../../../Service/User"
// import Tools from "../../../utils/Tools"
export default class EditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEditUser: this.props.showEditUser,
      name: this.props.user.first_name,
      surname: this.props.user.last_name,
      email: this.props.user.email,
      user_email: this.props.user.email,
      warningName: "",
      warningSurname: "",
      warningEmail: "",
      warningSelected_locations: "",
      selected_locations: this.props.selected_locations,
      locations: this.props.user.locations,
    }
    this.oncloseModal = this.oncloseModal.bind(this)
  }

  componentDidMount() {
    let selected_locations = this.props.selected_locations
    selected_locations.forEach((element) => {
      element.selected = false
    })
    this.setState({ selected_locations })
  }

  oncloseModal() {
    this.props.closeEditUserModal(() => {
      this.setState({ showEditUser: false })
    })
  }

  handleName(event) {
    if (this.state.warningName !== "") {
      this.setState({ name: event.target.value, warningName: "" })
    } else {
      this.setState({ name: event.target.value })
    }
  }

  handleSurname(event) {
    if (this.state.warningSurname !== "") {
      this.setState({ surname: event.target.value, warningSurname: "" })
    } else {
      this.setState({ surname: event.target.value })
    }
  }

  handleEmail(event) {
    if (this.state.warningEmail !== "") {
      this.setState({ email: event.target.value, warningEmail: "" })
    } else {
      this.setState({ email: event.target.value })
    }
  }

  selectLocation(loc_index) {
    let selected_locations = this.state.selected_locations
    if (selected_locations[loc_index].selected === true) {
      selected_locations[loc_index].selected = false
    } else {
      selected_locations[loc_index].selected = true
    }
    if (this.state.warningSelected_locations !== "") {
      this.setState({
        selected_locations: selected_locations,
        warningSelected_locations: "",
      })
    } else {
      this.setState({ selected_locations: selected_locations })
    }
  }

  edit() {
    let error = false
    // if(this.state.name.length==0){
    //     this.setState({warningName:"Nome Inválido!"});
    //     error = true;
    // }
    // if(this.state.surname.length==0){
    //     this.setState({warningSurname:"Apelido Inválido!"});
    //     error = true;
    // }
    // if(this.state.email.length==0){
    //     this.setState({warningEmail:"Email Inválido!"});
    //     error = true;
    // }
    // let selected_locations = this.state.selected_locations;
    // if(selected_locations.filter((e)=>e.selected === true)==0){
    //     this.setState({warningSelected_locations:"Introduza um espaço no mínimo!"});
    //     error = true;
    // }

    let locations = this.state.locations
    let selected_locations = this.state.selected_locations.filter(
      (location) => location.selected
    )
    if (selected_locations.length > 0) {
      locations = selected_locations
    }

    if (!error) {
      let data = {
        first_name: this.state.name,
        last_name: this.state.surname,
        email: this.state.user_email,
        change_email: this.state.email,
        // corporate_id:this.props.active_corporate.id,
        locations: JSON.stringify(locations),
      }

      User.adminEditUser(data, (res) => {
        if (!res.error) {
          this.props.editUser(res.error, res.message)
          this.oncloseModal()
        } else {
          this.props.editUser(res.error, res.message)
        }
      })
    }
  }

  render() {
    return (
      <Modal
        as={Modal.Dialog}
        show={this.props.showEditUser}
        onHide={this.oncloseModal.bind(this)}
      >
        <Modal.Header className="h5">
          {window.strings.editUser}
          <Button
            style={{ padding: 36 }}
            variant="close"
            aria-label="Close"
            onClick={this.oncloseModal.bind(this)}
          />
        </Modal.Header>
        <Modal.Body>
          <Form style={{ display: "flex", flexDirection: "row" }}>
            <Form.Group id="email" className="mb-4" style={{ marginRight: 15 }}>
              <Form.Label>{window.strings.name}</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={this.handleName.bind(this)}
                  autoFocus
                  required
                  type="email"
                  value={this.state.name}
                  placeholder={window.strings.name}
                />
              </InputGroup>
              {this.state.warningName.length > 0 && (
                <Form.Label style={{ color: "#D0342C" }}>
                  {this.state.warningName}
                </Form.Label>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Group id="text" className="mb-4">
                <Form.Label>{window.strings.surname}</Form.Label>
                <InputGroup>
                  <Form.Control
                    onChange={this.handleSurname.bind(this)}
                    required
                    value={this.state.surname}
                    placeholder={window.strings.surname}
                  />
                </InputGroup>
                {this.state.warningSurname.length > 0 && (
                  <Form.Label style={{ color: "#D0342C" }}>
                    {this.state.warningSurname}
                  </Form.Label>
                )}
              </Form.Group>
            </Form.Group>
          </Form>

          <Form>
            <Form.Group id="email" className="mb-4" style={{ marginRight: 15 }}>
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={this.handleEmail.bind(this)}
                  autoFocus
                  required
                  type="email"
                  value={this.state.email}
                  placeholder={this.props.user_email}
                />
              </InputGroup>
              {this.state.warningEmail.length > 0 && (
                <Form.Label style={{ color: "#D0342C" }}>
                  {this.state.warningEmail}
                </Form.Label>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Group id="password" className="mb-4">
                <Form.Label>{window.strings.manageLocations}</Form.Label>
                <Accordion defaultActiveKey={false}>
                  <Accordion.Item>
                    <Accordion.Button className={"accordion-custom-header"}>
                      <span style={{}}>{window.strings.chooseOption}</span>
                    </Accordion.Button>
                    <Accordion.Body
                      style={{
                        width: "100%",
                        padding: 0,
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          maxHeight: 250,
                          overflow: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {this.state.selected_locations &&
                          this.state.selected_locations.map(
                            (location, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={this.selectLocation.bind(
                                    this,
                                    index
                                  )}
                                  style={{
                                    cursor: "pointer",
                                    padding: 20,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    borderBottom:
                                      "1px solid rgba(162, 179, 184, 0.2)",
                                    backgroundColor:
                                      location.selected === true
                                        ? "rgba(0, 184, 235, 0.1)"
                                        : "transparent",
                                  }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {location.name}
                                  </span>
                                </div>
                              )
                            }
                          )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {this.state.warningSelected_locations.length > 0 && (
                  <Form.Label style={{ color: "#D0342C" }}>
                    {this.state.warningSelected_locations}
                  </Form.Label>
                )}
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ padding: 0, margin: 0 }}>
          <div
            style={{
              flex: 1,
              margin: 0,
              cursor: "pointer",
              padding: "23px 0px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              color: "#62757A",
              fontSize: 17,
            }}
            onClick={this.oncloseModal.bind(this)}
          >
            {window.strings.cancel}
          </div>
          <div
            style={{
              flex: 1,
              margin: 0,
              cursor: "pointer",
              padding: "23px 0px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              color: "#ffffff",
              fontSize: 17,
              backgroundColor: "#041114",
            }}
            onClick={this.edit.bind(this)}
          >
            {window.strings.saveChanges}
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}
