import Service from "./Service"
import Tools from "./../utils/Tools"

var host = process.env.REACT_APP_POWERDOT_TV_BACKEND_HOST + "/tv/v1/users"

class User {
  update(data, callback) {
    data = {
      email: "outdare@powerdot.pt",
      name: "outdare",
      locations: [],
    }
    Service.makePostRequest(host + "/update", data, (result) => {
      //token=token
      callback(result)
    })
  }

  createUser(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/create?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  validate(callback) {
    Tools.getData("token", (token) => {
      Service.makeGetRequest(host + "/validate?token=" + token, (response) => {
        callback(response)
      })
    })
  }

  getProfile(callback) {
    Tools.getData("token", (token) => {
      Service.makeGetRequest(
        host + "/get/profile?token=" + token,
        (response) => {
          callback(response)
        }
      )
    })
  }

  getAllUsers(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/get?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  adminEditUser(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/admin/edit?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  edit(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/edit?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  deleteUser(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/delete?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  logout(callback) {
    Tools.getData("token", (token) => {
      Service.makeGetRequest(host + "/logout?token=" + token, (response) => {
        callback(response)
      })
    })
  }

  sendFeedback(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/sendfeedback?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  resendPw(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/resendpassword?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }

  completeOnboarding(callback) {
    Tools.getData("token", (token) => {
      Service.makeGetRequest(
        host + "/tutorial/done?token=" + token,
        (response) => {
          callback(response)
        }
      )
    })
  }

  getLangs(callback) {
    Tools.getData("token", (token) => {
      Service.makeGetRequest(host + "/locales?token=" + token, (response) => {
        callback(response)
      })
    })
  }

  setLang(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/set/locale?token=" + token,
        data,
        (response) => {
          callback(response)
        }
      )
    })
  }
}
export default new User()
