import React, { Component } from "react"
import { Modal, Table } from "@themesberg/react-bootstrap"
import powerdotIcon from "../assets/tv/powerdot-Icon.png"
import { Image } from "@themesberg/react-bootstrap"
export default class SpaceModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: this.props.showModal,
      locations: this.props.locations,
    }
    this.oncloseModal = this.oncloseModal.bind(this)
    this.apply = this.apply.bind(this)
  }

  oncloseModal() {
    this.props.closeModal(() => {
      this.setState({ showModal: false })
    })
  }

  apply() {
    let selectedLocations = this.props.locations
    selectedLocations = selectedLocations.filter((e) => e.selected == true)
    this.props.selectLocations(selectedLocations, () => {
      this.oncloseModal()
    })
  }

  addLocation(index) {
    let locations = this.props.locations
    locations[index].selected = !locations[index].selected
    this.setState({ locations })
  }

  renderLocationsTable() {
    return (
      <Table>
        <thead style={{ backgroundColor: "rgba(162, 179, 184, 0.2)" }}>
          <tr>
            <th className="border-0"></th>
            <th className="border-0"></th>
            <th
              className="border-0"
              style={{ color: "#62757A", textAlign: "left", fontSize: 14 }}
            >
              {window.strings.name}
            </th>
            <th
              className="border-0"
              style={{ color: "#62757A", fontSize: 14, textAlign: "center" }}
            >
              {window.strings.chargers}
            </th>
          </tr>
        </thead>

        <tbody style={{ backgroundColor: "#ffffff" }}>
          {this.props.locations.map((location, index) => {
            return (
              <tr
                key={index}
                onClick={this.addLocation.bind(this, index)}
                style={{
                  borderBottomWidth: 1,
                  borderColor: "rgba(162, 179, 184, 0.2)",
                  cursor: "pointer",
                }}
              >
                <td
                  className="border-0 "
                  style={{ padding: "20px 0px 0px 20px", width: 50 }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: 24,
                      height: 24,
                      padding: 2,
                      borderRadius: 4,
                      border: "solid 1px rgba(162, 179, 184, 0.4)",
                    }}
                  >
                    {" "}
                    {location.selected && (
                      <div
                        style={{
                          backgroundColor: "#00b8eb",
                          flex: 1,
                          borderRadius: 4,
                        }}
                      />
                    )}
                  </div>
                </td>
                <td
                  className="border-0 "
                  style={{ textAlign: "center", paddingTop: 15 }}
                >
                  <Image
                    src={
                      location.image === null ? powerdotIcon : location.image
                    }
                    style={{ width: 32 }}
                  />
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 400,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  <span
                    style={{
                      fontSize: 17,
                      color: "#041114",
                      fontWeight: "bold",
                    }}
                  >
                    {location.name}
                  </span>
                  <span style={{ fontSize: 15, color: "#62757A" }}>
                    {location.address}
                  </span>
                </td>
                <td
                  className="border-0 fw-bold"
                  style={{
                    color: "#62757A",
                    fontSize: 15,
                    paddingTop: 15,
                    textAlign: "center",
                  }}
                >
                  {location.number_chargers}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  renderModalFooter() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 5,
          marginRight: 10,
        }}
      >
        <div
          onClick={this.oncloseModal.bind(this)}
          style={{
            padding: "12px 50px",
            border: "1px solid rgba(162, 179, 184, 0.4)",
            marginRight: 16,
            cursor: "pointer",
          }}
        >
          <span style={{ fontSize: 15, color: "#62757A" }}>
            {window.strings.cancel}
          </span>
        </div>

        <div
          onClick={this.apply.bind(this)}
          style={{
            padding: "12px 50px",
            backgroundColor: "#00B8EB",
            cursor: "pointer",
          }}
        >
          <span style={{ fontSize: 17, color: "#ffffff" }}>
            {window.strings.applyFilter}
          </span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Modal
        as={Modal.Dialog}
        show={this.props.showModal}
        onHide={this.oncloseModal.bind(this)}
        contentClassName={"location-modal"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: 21, color: "#041114" }}
          >
            {window.strings.selectLocations}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, borderRadius: 2 }}>
          <div style={{ overflowY: "scroll", maxHeight: 350 }}>
            {this.renderLocationsTable()}
          </div>
        </Modal.Body>
        <Modal.Footer>{this.renderModalFooter()}</Modal.Footer>
      </Modal>
    )
  }
}
