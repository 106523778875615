import React, { Component } from "react"
import Preloader from "../../components/Preloader"
import { Col, Image, Row } from "@themesberg/react-bootstrap"
import Metric from "./components/Metric"
import ChargeList from "./components/ChargeList"
import ChargersList from "../Chargers/components/ChargersList"

import Charges from "../../Service/Charges"
import downloadIcon from "../../assets/tv/download.png"

export default class Overview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: this.props.profile,
      // active_corporate: this.props.active_corporate,
      compare: true,
      dashboards_data: this.props.dashboards_data,
      charges: this.props.charges_data,
      selectedCharger: null,
      mount: false,
    }
    this.chargesRef = React.createRef()
  }

  componentDidMount() {
    this.setState({ mount: true })
  }

  selectCharger(charger, page, callback) {
    let charger_tmp = charger
    if (page === undefined) page = 1
    if (charger === null) charger = []
    else charger = [charger.box_id]
    let data = {
      from: this.props.from, //do teu perfil
      to: this.props.to,
      box_names: JSON.stringify(charger), //todos : box_name,
      page: page,
      // corporate_id: this.props.active_corporate.id,
      items_per_page: 10,
    }
    Charges.getChargesByCharger(data, (res) => {
      if (!res.error) {
        this.setState({
          loading: false,
          charges: res,
          selectedCharger: charger_tmp,
        })
        if (res.data.length > 0) {
          this.chargesRef.current.scrollIntoView()
        }
        callback && callback()
      } else {
        // alert(window.strings.errorInf);
        console.log(window.strings.errorInf)
      }
    })
  }

  render() {
    return (
      <div
        className="dashboards-content"
        style={{ display: "flex", flexDirection: "column", paddingBottom: 80 }}
      >
        <div style={{ marginBottom: "24px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
     <span
       style={{
         fontSize: 21,
         color: "#041114",
         marginBottom: 15,
         fontWeight: "bold",
       }}
     >
            {window.strings.metrics}
          </span>
              <span style={{ fontSize: 14, color: "#537A68", marginLeft: "4px" }}>
            ({window.strings.compareToLastPeriod})
          </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                this.props.exportCharges()
              }}
            >
            <span
              style={{
                fontSize: 15,
                color: "#62757A",
                fontWeight: "bold",
                marginRight: 10,
              }}
            >
              {window.strings.export}
            </span>
              <Image src={downloadIcon} style={{ width: 24 }} />
            </div>
          </div>

        </div>

        {this.props.dashboards_data !== undefined &&
          this.props.dashboards_data !== {} &&
          this.props.dashboards_data.res !== undefined && (
            <div>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Metric
                    metricTotalTitle={window.strings.totalCharges + ":"}
                    unit={window.strings.charges}
                    metricTotal={
                      Math.round(
                        this.props.dashboards_data.res.data
                          .total_numero_de_carregamentos * 10,
                      ) / 10
                    }
                    lastMonth={
                      this.props.dashboards_data.charges_stats.lastMonth
                    }
                    evo={
                      Math.round(
                        this.props.dashboards_data.charges_stats
                          .lastMonth_perc * 10,
                      ) / 10
                    }
                    frequency={this.props.dashboards_data.frequency}
                  />
                </Col>
                <Col>
                  <Metric
                    metricTotalTitle={"kWh " + window.strings.consumed + ":"}
                    unit={"kWh"}
                    metricTotal={
                      Math.round(
                        this.props.dashboards_data.res.data
                          .total_energia_consumida * 10,
                      ) / 10
                    }
                    lastMonth={
                      this.props.dashboards_data.consumed_stats.lastMonth
                    }
                    evo={
                      Math.round(
                        this.props.dashboards_data.consumed_stats
                          .lastMonth_perc * 10,
                      ) / 10
                    }
                    frequency={this.props.dashboards_data.frequency}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Metric
                    metricTotalTitle={window.strings.minutesCharged + ":"}
                    unit={"min"}
                    metricTotal={
                      Math.round(
                        this.props.dashboards_data.res.data
                          .total_duracao_carregamento * 10,
                      ) / 10
                    }
                    lastMonth={this.props.dashboards_data.time_stats.lastMonth}
                    evo={
                      Math.round(
                        this.props.dashboards_data.time_stats.lastMonth_perc *
                        10,
                      ) / 10
                    }
                    frequency={this.props.dashboards_data.frequency}
                  />
                </Col>
                <Col>
                  <Metric
                    metricTotalTitle={"CO2 " + window.strings.spared + ":"}
                    unit={"t"}
                    containBc={true}
                    metricTotal={
                      Math.round(
                        this.props.dashboards_data.res.data.total_co2 * 10,
                      ) / 10
                    }
                    lastMonth={
                      this.props.dashboards_data.spared_stats.lastMonth
                    }
                    evo={
                      Math.round(
                        this.props.dashboards_data.spared_stats.lastMonth_perc *
                        10,
                      ) / 10
                    }
                    frequency={this.props.dashboards_data.frequency}
                  />
                </Col>
              </Row>
            </div>
          )}

        {this.props.chargers_data !== undefined &&
          this.props.chargers_data.data !== undefined &&
          this.props.chargers_data.data.length > 0 && (
            <>
              <span
                style={{
                  fontSize: 21,
                  color: "#041114",
                  fontWeight: "bold",
                  marginTop: 50,
                }}
              >
                {window.strings.chargers}
              </span>
              <span
                style={{
                  height: 1,
                  width: "100%",
                  backgroundColor: "rgba(162, 179, 184, 0.2)",
                  borderRadius: 1,
                  margin: "15px 0px 32px 0px",
                }}
              ></span>
              <Row>
                <ChargersList
                  total_results={this.props.chargers_data.total_data}
                  selectCharger={this.selectCharger.bind(this)}
                  chargers={this.props.chargers_data.data}
                  selectedCharger={null}
                  updateChargersResultForPage={
                    this.props.updateChargersResultForPage
                  }
                />
              </Row>
            </>
          )}

        {this.props.charges_data !== undefined &&
          this.props.charges_data.data !== undefined &&
          this.props.charges_data.data.length > 0 && (
            <>
              <span
                style={{
                  fontSize: 21,
                  color: "#041114",
                  fontWeight: "bold",
                  marginTop: 50,
                }}
                ref={this.chargesRef}
              >
                {window.strings.historyCharges}
              </span>
              <span
                style={{
                  height: 1,
                  width: "100%",
                  backgroundColor: "rgba(162, 179, 184, 0.2)",
                  borderRadius: 1,
                  margin: "15px 0px 32px 0px",
                }}
              ></span>
              <Row>
                <ChargeList
                  charges={this.props.charges_data.data}
                  total_results={this.props.charges_data.total_data}
                  updateResultsForPage={(page, callback) => {
                    if (this.state.selectedCharger != null) {
                      this.selectCharger(
                        this.state.selectedCharger,
                        page,
                        callback,
                      )
                    } else {
                      this.props.updateResultsForPage(page, callback)
                    }
                  }}
                />
              </Row>
            </>
          )}
        {this.state.loading && <Preloader show={true} />}
      </div>
    )
  }
}
