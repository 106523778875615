class Service {
  makeGetRequest(url,callback){
    
		console.log('GET to > ' + url)

		fetch(url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				// // 'Language': window.language,
				'Content-Type': 'application/json'
			}
		})
		.then((response) => response.json())
		.then((responseJson) => {
			console.log(responseJson)
			callback(responseJson)
		})
		.catch((error) => {
			console.log(error);
			callback({
				error: true,
				message: "App fatal error",
				catch: error,
			});	
		});
	}

	makePostRequest(url,data,callback){
		console.log('POST to > ' + url)


		fetch(url, {
			method: 'POST',
			timeout: 5,
			headers: {
				'Accept': 'application/json',
				// 'Language': window.language,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((responseJson) => {
			console.log(responseJson)
			callback(responseJson);
		})
		.catch((error) => {
			console.log(error);
			callback(null);	
		});
	}

}

export default new Service();
