import React from "react"
import { Nav, Image } from "@themesberg/react-bootstrap"
import { useLocation, Link } from "react-router-dom"
import logoNegative from "../../assets/img/brand/logo-negative.png"

export default function NavItem(props) {
  const {
    header,
    title,
    withNumber,
    subtitle,
    link,
    external,
    target,
    withActiveClass,
    subTab,
    setShow,
  } = props

  const { pathname } = useLocation()

  const classNames = "text-black"
  const navItemClassName =
    link === pathname && withActiveClass === true ? "active" : ""
  const linkProps = external ? { href: link } : { as: Link, to: link }

  return (
    <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
      <Nav.Link
        {...linkProps}
        target={target}
        style={{
          backgroundColor: header && "#000",
          marginBottom: header && 30,
          borderBottom:
            !header && !external && "1px solid rgba(162, 179, 184, 0.2)",
        }}
        className={classNames}
      >
        {header !== undefined ? (
          <span>
            <Image
              src={logoNegative}
              height="64"
              className="sidebar-icon svg-icon"
              style={{ padding: "16px 0px" }}
            />
          </span>
        ) : title !== undefined && subtitle !== undefined ? (
          <span style={{ flexDirection: "column", display: "flex" }}>
            <span className="sidebar-text-custom">{title}</span>
            <span
              className="sidebar-text-custom"
              style={{ fontWeight: "bold" }}
            >
              {subtitle}
            </span>
          </span>
        ) : external && title !== undefined ? (
          <span
            className="activeContent"
            style={{ flexDirection: "row", display: "flex" }}
          >
            <span
              className="sidebar-text-custom"
              style={{ fontWeight: subTab ? "normal" : "bold" }}
            >
              {title}
            </span>
          </span>
        ) : withNumber && title !== undefined ? (
          <span
            className="activeContent"
            style={{ flexDirection: "row", display: "flex" }}
          >
            <span className="activeContentBar"></span>
            <span
              className="sidebar-text-custom"
              style={{ fontWeight: subTab ? "normal" : "bold" }}
            >
              {title}
            </span>
            <span
              style={{
                borderRadius: 16,
                backgroundColor: "#041114",
                fontSize: 14,
                textAlign: "center",
                color: "#ffffff",
                padding: "3px 9px",
                marginLeft: 5,
              }}
            >
              {withNumber}
            </span>
          </span>
        ) : (
          title !== undefined && (
            <span
              className="activeContent"
              style={{ flexDirection: "row", display: "flex" }}
            >
              <span className="activeContentBar"></span>
              <span
                className="sidebar-text-custom"
                style={{ fontWeight: subTab ? "normal" : "bold" }}
              >
                {title}
              </span>
            </span>
          )
        )}
      </Nav.Link>
    </Nav.Item>
  )
}
