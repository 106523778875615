import React, { Component } from "react"

import { Image, Table } from "@themesberg/react-bootstrap"

import dayjs from "dayjs"

import chargerIcon from "../../../assets/tv/chargerIcon@3x.png"
import Paginator from "./Paginator"
import Tools from "../../../utils/Tools"
export default class ChargeList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      default_currency: Tools.getDefaultCurrency(),
    }
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <>
          <Table>
            <thead
              style={{ backgroundColor: "rgba(162, 179, 184, 0.2)" }}
              className="table table-striped table-bordered"
            >
              <tr>
                <th style={{ width: 80 }} className="border-0"></th>
                <th
                  className="border-0"
                  style={{
                    color: "#62757A",
                    fontSize: 14,
                    textTransform: "uppercase",
                  }}
                >
                  {window.strings.date}
                </th>
                <th
                  className="border-0"
                  style={{
                    color: "#62757A",
                    fontSize: 14,
                    textTransform: "uppercase",
                  }}
                >
                  {window.strings.time}
                </th>
                <th
                  className="border-0"
                  style={{
                    color: "#62757A",
                    fontSize: 14,
                    textTransform: "uppercase",
                  }}
                >
                  {window.strings.revenue}
                </th>
                <th
                  className="border-0"
                  style={{
                    color: "#62757A",
                    fontSize: 14,
                    textTransform: "uppercase",
                  }}
                >
                  {window.strings.consumption}
                </th>
                <th
                  className="border-0"
                  style={{
                    color: "#62757A",
                    fontSize: 14,
                    textTransform: "uppercase",
                  }}
                >
                  {window.strings.charger}
                </th>
                <th
                  className="border-0"
                  style={{
                    color: "#62757A",
                    fontSize: 14,
                    textTransform: "uppercase",
                  }}
                >
                  {window.strings.location}
                </th>
                <th
                  className="border-0"
                  style={{ color: "#62757A", textAlign: "left", fontSize: 14 }}
                >
                  ID
                </th>
                <th
                  className="border-0"
                  style={{
                    color: "#62757A",
                    fontSize: 14,
                    textTransform: "uppercase",
                  }}
                >
                  {window.strings.user} ID
                </th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#ffffff" }}>
              {this.props.charges.map((charge, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      borderBottomWidth: 1,
                      borderColor: "rgba(162, 179, 184, 0.2)",
                    }}
                  >
                    <td className="border-0 " style={{ textAlign: "center" }}>
                      <Image
                        src={chargerIcon}
                        style={{ color: "#62757A", width: 32 }}
                      />
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#000",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {dayjs(charge.data).format("D MMM, HH:mm")}
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#000",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {charge.total_duracao_carregamento} min
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#000",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {Math.round(charge.revenue * 10) / 10}{" "}
                      {this.state.default_currency}
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {Math.round(charge.total_energia_consumida * 10) / 10} kWh
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {charge.box_id}
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {charge.location_name}
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        textAlign: "left",
                        paddingLeft: 15,
                      }}
                    >
                      {charge.charge_id}
                    </td>
                    <td
                      className="border-0 fw-bold"
                      style={{
                        color: "#62757A",
                        fontSize: 15,
                        paddingLeft: 15,
                      }}
                    >
                      {charge.user_id}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Paginator
            items={Array.from(Array(this.props.total_results).keys())}
            onChangePage={(page) => {
              this.setState({ loading: true }, () => {
                this.props.updateResultsForPage(page, () => {
                  this.setState({ loading: false })
                })
              })
            }}
          />
        </>
        {this.state.loading && (
          <div
            className="loading"
            style={{
              position: "absolute",
              textAlign: "center",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              top: 0,
              left: 0,
              zIndex: 10,
            }}
          >
            <div
              className="spinner-border text-light"
              role="status"
              style={{ position: "absolute", top: "50%" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    )
  }
}
