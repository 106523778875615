import React, { Component } from "react"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import downloadIcon from "../../../src/assets/tv/download.png"
import { Image } from "@themesberg/react-bootstrap"
import ToggleButton from "react-toggle-button"

/**
 *
 */

export default class MultitypeChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dashboard_data: this.props.dashboard_data,
      data: {
        labels: this.props.labels,
        datasets: this.props.datasets,
        teste: "teste",
      },
      options: {
        maintainAspectRatio: false,
        showTooltips: true,
        responsive: true,
        scales: {
          "left-y-axis": {
            type: "linear",
            position: "left",
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
          x: {
            ticks: {
              font: {
                size: 15,
                color: "#62757A",
              },
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            usePointStyle: true,
            callbacks: {
              title: function (tooltipItem) {
                if (
                  tooltipItem[0].dataset.freq === "dia" ||
                  tooltipItem[0].dataset.freq === "horaria"
                ) {
                  let month
                  switch (tooltipItem[0].label.split("-")[1]) {
                    case "1":
                      month = "Jan."
                      break
                    case "2":
                      month = "Fev."
                      break
                    case "3":
                      month = "Mar."
                      break
                    case "4":
                      month = "Abr."
                      break
                    case "5":
                      month = "Mai."
                      break
                    case "6":
                      month = "Jun."
                      break
                    case "7":
                      month = "Jul."
                      break
                    case "8":
                      month = "Ago."
                      break
                    case "9":
                      month = "Set."
                      break
                    case "10":
                      month = "Out."
                      break
                    case "11":
                      month = "Nov."
                      break
                    case "12":
                      month = "Dez."
                      break
                    default:
                      month = "Jan."
                  }
                  return (
                    tooltipItem[0].label.split("-")[0] +
                    " " +
                    month +
                    " " +
                    tooltipItem[0].label.split("-")[2]
                  )
                } else {
                  return tooltipItem[0].label
                }
              },
              label: function (tooltipItem) {
                return (
                  "  " +
                  tooltipItem.formattedValue +
                  " " +
                  tooltipItem.dataset.label
                )
              },
              labelTextColor: function () {
                return "#fff"
              },
              afterLabel: function (tooltipItem) {
                return (
                  "\n" +
                  window.strings.compareToLastPoint +
                  ": " +
                  (tooltipItem.dataset.evo[tooltipItem.dataIndex].last > 0
                    ? "+"
                    : "") +
                  tooltipItem.dataset.evo[
                    tooltipItem.dataIndex
                  ].last.toLocaleString() +
                  "\n" +
                  window.strings.evolution +
                  ": " +
                  (tooltipItem.dataset.evo[tooltipItem.dataIndex].evo > 0
                    ? "+"
                    : "") +
                  tooltipItem.dataset.evo[tooltipItem.dataIndex].evo +
                  "%"
                )
              },
            },
            backgroundColor: "#041114",
            titleFontSize: 14,
            titleFontColor: "#fff",
            bodyFontColor: "#fff",
            bodyFontSize: 17,

            footerFontColor: "#fff",
            footerFontSize: 17,
            padding: 16,
            displayColors: true,
          },
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
      },
    }
  }

  componentDidUpdate() {
    let data = this.state.data
    if (data.datasets !== this.props.datasets) {
      data.datasets = this.props.datasets
      this.setState({ data })
    }
    if (data.labels !== this.props.labels) {
      data.labels = this.props.labels
      this.setState({ data })
    }
  }

  render() {
    return (
      <div
        className="shadow-sm border-light"
        style={{
          padding: 55,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginBottom: 22,
            padding: 18,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <span style={{ fontSize: 15, color: "#62757A" }}>
              {this.props.totalTitle}
            </span>
            <span style={{ fontSize: 40, color: "#041114" }}>
              {this.props.total.toLocaleString()}
              <span style={{ fontSize: 20, color: "#62757A" }}>
                {this.props.unit}
              </span>
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                this.props.exportCharges()
              }}
            >
            <span
              style={{
                fontSize: 15,
                color: "#62757A",
                fontWeight: "bold",
                marginRight: 10,
              }}
            >
              {window.strings.export}
            </span>
              <Image src={downloadIcon} style={{ width: 24 }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor:
                  this.props.lastMonth > 0
                    ? "rgba(7,184,101,0.08)"
                    : "rgba(208, 52, 44, 0.08)",
                border:
                  this.props.lastMonth > 0
                    ? "1px solid rgba(7,184,101,0.08)"
                    : "1px solid rgba(208, 52, 44, 0.08)",
                borderRadius: 2,
                padding: "6px 12px",
                marginBottom: 4,
              }}
            >
              <span
                style={{
                  flex: 1,
                  fontSize: 17,
                  color: this.props.evo > 0 ? "#07B865" : "#D0342C",
                  fontWeight: "bold",
                }}
              >
                <span>
                  {this.props.evo > 0
                    ? "+ " + this.props.lastMonth.toLocaleString()
                    : this.props.evo < 0
                    ? this.props.lastMonth.toString().slice(0, 1) +
                      " " +
                      this.props.lastMonth.toString().slice(1).toLocaleString()
                    : this.props.lastMonth.toLocaleString()}
                </span>
                <span style={{ margin: "0 5px 0 5px" }}>{this.props.unit}</span>
                <span>
                  (
                  {this.props.evo > 0
                    ? "+ " + this.props.evo + "%"
                    : this.props.evo < 0
                    ? this.props.evo.toString().slice(0, 1) +
                      " " +
                      this.props.evo.toString().slice(1) +
                      "%"
                    : this.props.evo + "%"}
                  )
                </span>
              </span>
            </div>
          </div>
        </div>

        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          {this.props.infoTitle !== undefined &&
            this.props.infoDesc !== undefined && (
              <div style={{ alignSelf: "flex-start" }}>
                <InfoPopOver
                  title={this.props.infoTitle}
                  desc={this.props.infoDesc}
                  large={true}
                />
              </div>
            )}
          <p
            onClick={() => {
              this.changeHandler();
            }}
            style={{
              fontSize: "1.2rem",
              flex: 1,
              borderWidth: 1,
              textAlign: "end",
              cursor: "pointer",
            }}
          >
            Reset Filters
          </p>
        </div> */}
        <div style={{ height: 350 }}>
          <Bar
            id={this.props.id && this.props.id}
            key={Math.random()}
            plugins={[ChartDataLabels]}
            data={this.state.data}
            options={this.state.options}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 18,
            marginTop: 20,
          }}
        >
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            {/* {this.props.currentMonth && (
              <>
                <span
                  style={{
                    fontSize: 15,
                    color: "#00B8EB",
                    fontWeight: "bold",
                    marginRight: 12,
                  }}
                >
                  Mês atual
                </span>
                <div
                  style={{
                    backgroundColor: "rgba(0, 184, 235, 0.2)",
                    width: 28,
                    height: 20,
                    borderTop: "2px solid #00B8EB",
                  }}
                ></div>
              </>
            )} */}
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 80,
            }}
          >
            <span
              style={{
                fontSize: 15,
                color: "#62757A",
                fontWeight: "bold",
                marginRight: 10,
              }}
            >
              Partilhar
            </span>
            <FontAwesomeIcon
              icon={faShareAlt}
              style={{ color: "rgba(98, 117, 122, 0.5)" }}
            />
          </div> */}
          {this.props.allowToggle && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: 15,
                  color: "#62757A",
                  fontWeight: "bold",
                  marginRight: 10,
                }}
              >
                {window.strings.sameTimeofLastYear}
              </span>
              <ToggleButton
                inactiveLabel={""}
                activeLabel={""}
                colors={{
                  activeThumb: {
                    base: "#ffffff",
                  },
                  inactiveThumb: {
                    base: "#ffffff",
                  },
                  active: {
                    base: "rgba(0, 184, 235, 0.7)",
                    hover: "rgba(0, 184, 235, 1)",
                  },
                  inactive: {
                    base: "rgba(177, 191, 215,0.4)",
                    hover: "rgba(177, 191, 215,0.7)",
                  },
                }}
                value={this.props.compare}
                onToggle={this.props.onToggle}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
