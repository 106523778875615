import PT from "./lang/PT";
import EN from "./lang/EN";
import FR from "./lang/FR";
import PL from "./lang/PL";
import ES from "./lang/ES";
class stringfile {
  getStrings() {
    switch (window.language) {
      case "PT":
        return PT;
      case "EN":
        return EN;
      case "FR":
        return FR;
      case "PL":
        return PL;
      case "ES":
        return ES;
      default:
        return PT;
    }
  }
}
export default new stringfile();
