import React, { useState, useEffect } from "react"
import { Route } from "react-router-dom"
import { hotjar } from "react-hotjar"
import dayjs from "dayjs"

import DashboardsNavbar from "../../components/DashboardsNavbar"
import Sidebar from "../../components/Sidebar"
import LocationBar from "../../components/LocationBar"
import Filters from "../../components/Filters"
import AlertModal from "../../components/AlertModal"
import Preloader from "../../components/Preloader"

import Dashboards from "../../Service/Dashboards"
import ChargesService from "../../Service/Charges"
import ChargersService from "../../Service/Chargers"
import User from "../../Service/User"

import Tools from "../../utils/Tools"
import stringfile from "../../utils/stringfile"
import { DATE_FORMAT } from "../../utils/constants"

export default function RouteWithSidebarWithFilters({
  component: Component,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState({})
  const [profile, setProfile] = useState(undefined)
  const [locations, setLocations] = useState([])
  const [locations_ids, setLocationsIds] = useState([])
  const [from, setFrom] = useState([])
  const [to, setTo] = useState([])
  const [fromHour, setFromHour] = useState(0)
  const [toHour, setToHour] = useState(0)
  const [active_locations, setActiveLocations] = useState([])
  const [dashboards_data, setDashboards_data] = useState({})
  const [charges_data, setCharges_data] = useState({})
  const [chargers_data, setChargers_data] = useState({})
  const [bestCharger_data, setBestCharger_data] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalDesc, setModalDesc] = useState("")
  const [frequency, setCurrentFrequency] = useState("semanal")
  const [modalType, setModalType] = useState(false)

  const [navLocation, setNavLocation] = useState(null)

  useEffect(() => {
    User.validate((res) => {
      if (!res.error) {
        window.language = res.profile.user.lang
        window.strings = stringfile.getStrings()
        window.locations = res.profile.user.locations

        if (window.location.href.split(".")[2] != undefined) {
          let lang = (
            window.location.href.split(".")[2][0] +
            window.location.href.split(".")[2][[1]]
          ).toUpperCase()

          if (lang == "FR") {
            hotjar.initialize(2732277, 6)
          } else if (lang == "ES") {
            hotjar.initialize(2763140, 6)
          } else if (lang == "PL") {
            hotjar.initialize(2763135, 6)
          } else {
            hotjar.initialize(2649909, 6)
          }
        } else {
          hotjar.initialize(2649909, 6)
        }
        Tools.setData("token", res.token)

        setLocations(
          res.profile.user.locations
            .map((location) => ({
              ...location,
              selected: true,
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        )
        setActiveLocations(res.profile.user.locations)

        setProfile(res.profile.user)

        let today_temp = new Date()

        let datelastYear = new Date(
          today_temp.getFullYear(),
          today_temp.getMonth() - 1,
          today_temp.getDate(),
          0,
          0,
          0
        )

        datelastYear = datelastYear
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, "")
        const today = dayjs().endOf("day").format(DATE_FORMAT)
        setFrom(datelastYear)
        setTo(today)

        let locations_ids = JSON.stringify(
          res.profile.user.locations.map((value) => value.id)
        )
        setLocationsIds(locations_ids)

        let data = {
          locations_id: locations_ids,
          frequency: frequency,
          fromHour: fromHour,
          toHour: toHour,
          from: datelastYear,
          to: today,
          data_last_freq: false,
        }
        let data_charges = {
          locations_id: locations_ids,
          from: datelastYear,
          to: today,
          page: 1,
          items_per_page: 10,
        }

        let navLocation_aux = Tools.returnData("saved_navLocation")
        if (
          navLocation_aux !== "null" &&
          navLocation_aux !== null &&
          navLocation_aux !== undefined
        ) {
          changeToActiveNavLocation(
            res.profile.user.locations.filter(
              (e) => e.id == navLocation_aux
            )[0],
            data,
            data_charges
          )
          Tools.setData("saved_navLocation", null)
        }
        asyncGetChargersByLocation(data_charges)
        asyncGetChargesByLocation(data_charges)
        asyncGetDashboards(data)

        if (Tools.returnData("saved_activeCorporate")) {
          localStorage.removeItem("saved_activeCorporate")
        }
      } else {
        window.location.href = "/Signin"
      }
    })
  }, [])

  function updateDate(from, to, fromHour, toHour, freq, callback) {
    setFrom(from)
    setTo(to)
    setFromHour(fromHour)
    setToHour(toHour)
    setCurrentFrequency(freq)
    callback && callback()
  }

  function updateLocationsIds(data, callback) {
    setLocationsIds(data)
    callback && callback()
  }

  async function updateData(data) {
    asyncGetDashboards(data)
    asyncGetBestCharger(data)
    asyncGetChargersByLocation(data)
    asyncUpdateResultsForPage(data)
  }

  function changeActiveLocations(data) {
    setActiveLocations(data)
  }

  function changeToActiveNavLocation(
    data_navLocation,
    data_dashboards,
    data_charges
  ) {
    setNavLocation(data_navLocation)
    if (data_navLocation != null) {
      data_dashboards.locations_id = JSON.stringify([data_navLocation.id])
      data_charges.locations_id = JSON.stringify([data_navLocation.id])
    }
    setLocationsIds(data_charges.locations_id)

    asyncGetBestCharger(data_charges)
    asyncGetChargesByLocation(data_charges)
    asyncGetChargersByLocation(data_charges)
    asyncGetDashboards(data_dashboards)
  }

  //ASYNC
  async function asyncGetBestCharger(data_charges) {
    ChargersService.getBestCharger(data_charges, (res) => {
      if (!res.error) {
        setBestCharger_data(res)
      } else {
        console.log(window.strings.errorInf)
      }
    })
  }

  async function asyncGetChargesByLocation(data_charges) {
    setIsLoading((prevState) => ({ ...prevState, charges: true }))

    ChargesService.getChargesByLocation(data_charges, (res) => {
      if (!res.error) {
        setCharges_data(res)
      } else {
        // alert(window.strings.errorInf);
        console.log(window.strings.errorInf)
      }
      setIsLoading((prevState) => ({ ...prevState, charges: false }))
    })
  }

  async function asyncGetChargersByLocation(data_charges) {
    setIsLoading((prevState) => ({ ...prevState, chargers: true }))

    ChargersService.getChargersByLocation(data_charges, (res) => {
      if (!res.error) {
        setChargers_data(res)
      } else {
        // alert(window.strings.errorInf);
        console.log(window.strings.errorInf)
      }
      setIsLoading((prevState) => ({ ...prevState, chargers: false }))
    })
  }

  async function asyncGetDashboards(data_dashboards) {
    setIsLoading((prevState) => ({ ...prevState, dashboards: true }))

    Dashboards.getDashboards(data_dashboards, (res) => {
      if (!res.error) {
        setDashboards_data(res)

        setIsLoading((prevState) => ({ ...prevState, dashboards: false }))
      } else {
        window.location.href = "/Signin"
      }
    })
  }

  function changeNavLocation(data) {
    setNavLocation(data)

    let locations_ids = JSON.stringify(locations.map((value) => value.id))
    if (data == null) {
      locations_ids = JSON.stringify(locations.map((value) => value.id))
      setLocationsIds(locations_ids)
    } else {
      locations_ids = JSON.stringify([data.id])
      setLocationsIds(locations_ids)
    }

    let dt = {
      locations_id: locations_ids,
      frequency: frequency,
      fromHour: fromHour,
      toHour: toHour,
      from: from,
      to: to,
      data_last_freq: false,
    }
    let data_charges = {
      locations_id: locations_ids,
      fromHour: fromHour,
      toHour: toHour,
      from: from,
      to: to,
      page: 1,
      items_per_page: 10,
    }
    if (data != null) {
      asyncGetBestCharger(data_charges)
    }
    asyncGetChargesByLocation(data_charges)
    asyncGetChargersByLocation(data_charges)
    asyncGetDashboards(dt)
  }

  function exportCharges() {
    let data = {
      locations_id:
        navLocation != null ? JSON.stringify([navLocation.id]) : locations_ids,
      from: from,
      to: to,
    }

    ChargesService.exportCharges(data, (res) => {
      if (!res.error) {
        setShowModal(true)
        setModalTitle(window.strings.successMsg)
        setModalDesc(res.message)
        setModalType(true)
      } else {
        setShowModal(true)
        setModalTitle(window.strings.errorMsg)
        setModalDesc(res.message)
        setModalType(false)
      }
    })
  }

  async function asyncUpdateResultsForPage(data) {
    asyncGetChargesByLocation(data)
  }

  function updateResultsForPage(page, callback) {
    const data_charges = {
      locations_id:
        navLocation != null ? JSON.stringify([navLocation.id]) : locations_ids,
      from: from,
      to: to,
      page: page,
      items_per_page: 10,
    }
    ChargesService.getChargesByLocation(data_charges, (res) => {
      if (!res.error) {
        setCharges_data(res)
      } else {
        // alert(window.strings.errorInf);
        console.log(window.strings.errorInf)
      }
      callback && callback()
    })
  }

  function updateChargersResultForPage(page, callback) {
    const data_chargers = {
      locations_id:
        navLocation != null ? JSON.stringify([navLocation.id]) : locations_ids,
      from: from,
      to: to,
      page: page,
      items_per_page: 10,
    }
    ChargersService.getChargersByLocation(data_chargers, (res) => {
      if (!res.error) {
        setChargers_data(res)
      } else {
        console.log(window.strings.errorInf)
      }
      callback && callback()
    })
  }

  function updateLastPeriodResults(callback) {
    let dt = {
      locations_id: locations_ids,
      frequency: frequency,
      fromHour: fromHour,
      toHour: toHour,
      from: from,
      to: to,
    }
    Dashboards.getLastPeriod(dt, (res) => {
      if (!res.error && res.data != null && res.data.length > 0) {
        let datasboard_data = dashboards_data
        let datasboard_data_last = res.data.length
        let diff_len = datasboard_data.labels.length - datasboard_data_last
        datasboard_data.last_freq_charges = []
        datasboard_data.last_freq_consumed = []
        datasboard_data.last_freq_time = []
        datasboard_data.last_freq_spared = []
        datasboard_data.last_freq_labels = []
        for (let index = 0; index < diff_len; index++) {
          res.data.unshift({
            hora: res.data[index].hora,
            dia: res.data[index].dia,
            mes: res.data[index].mes,
            ano: res.data[index].ano - 1,
            total_numero_de_carregamentos: 0,
            total_energia_consumida: 0,
            total_duracao_carregamento: 0,
            total_co2: 0,
          })
        }
        for (let k = 0; k < res.data.length; k++) {
          datasboard_data.last_freq_charges.push(
            res.data[k].total_numero_de_carregamentos
          )
          datasboard_data.last_freq_consumed.push(
            res.data[k].total_energia_consumida
          )
          datasboard_data.last_freq_time.push(
            res.data[k].total_duracao_carregamento
          )
          datasboard_data.last_freq_spared.push(res.data[k].total_co2)
          datasboard_data.last_freq_labels.push(
            res.data[k].semana + "-" + res.data[k].ano
          )
        }
        setDashboards_data(datasboard_data)
        callback && callback()
      } else {
        callback && callback(true)
      }
    })
  }

  function closeModal() {
    setShowModal(false)
  }

  const totalChargers = locations.reduce((totalChargers, location) => {
    return totalChargers + location.number_chargers
  }, 0)

  const pointsList = (dataSource, colors) => {
    return Tools.isPastDate(to, frequency)
      ? colors.default
      : Tools.styliseDataMarkers(dataSource, colors)
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={Object.values(isLoading).some(Boolean)} fullPage />

          <Sidebar
            profile={profile}
            locations={locations}
            totalChargers={totalChargers}
            changeNavLocation={changeNavLocation}
            navLocation={navLocation}
          />

          <main className="content">
            {profile && <DashboardsNavbar profile={profile} />}

            {navLocation && <LocationBar navLocation={navLocation} />}

            <Filters
              updateData={updateData}
              updateDate={updateDate}
              currentFrequency={frequency}
              updateLocationsIds={updateLocationsIds}
              locations={locations}
              active_locations={active_locations}
              changeActiveLocations={changeActiveLocations}
              navLocation={navLocation}
            />

            <Component
              {...props}
              active_locations={active_locations}
              from={from}
              to={to}
              navLocation={navLocation}
              profile={profile}
              dashboards_data={dashboards_data}
              charges_data={charges_data}
              chargers_data={chargers_data}
              bestCharger_data={bestCharger_data}
              updateResultsForPage={updateResultsForPage}
              updateLastPeriodResults={updateLastPeriodResults}
              exportCharges={exportCharges}
              updateChargersResultForPage={updateChargersResultForPage}
              pointsList={pointsList}
            />
          </main>
          <AlertModal
            showModal={showModal}
            closeModal={closeModal}
            title={modalTitle}
            desc={modalDesc}
            success={modalType}
          />
        </>
      )}
    />
  )
}
