export default {
  successMsg: "Succès",
  errorMsg: "Erreur",
  passwordsNotMatching: "Les mots de passe ne correspondent pas",
  insertPassword: "Insérer le mot de passe",
  insertEmail: "Insérer votre e-mail",
  repeat: "Répéter",
  complete: "Complété",
  password: "Mot de passe",
  definePassword: "Choisir un mot de passe",
  passwordEmailSent: "Un email a été envoyé avec vos informations de connexion",
  terms: "Termes et conditions",
  privacyPolicy: "Politique de confidentialité",
  recover: "Récupérer",
  insert: "Insérer",
  errorInf: "Echec d'obtention d'informations !",
  chargers: "Bornes de recharge",
  allChargers: "Toutes les bornes de recharge",
  historyCharges: "Historique des sessions de charge",
  name: "Prénom",
  type: "Modèle",
  location: "sites",
  last: "Dernier",
  days: "jours",
  from: "de",
  to: "à",
  revenue: "C.A. (y.c. coûts élec)",
  mostUsedCharger: "Borne la plus utilisée",
  charges: "Sessions",
  charges2: "Sessions",
  compareToLastPeriod: "comparé à la période précédente",
  evolution: "évolution",
  searchForCharger: "Chercher une borne",
  searchForUser: "Rechercher un utilisateur",
  totalCharges: "Nombre de sessions",
  hour: "Heure",
  day: "Jour",
  week: "Semaine",
  metrics: "Données",
  month: "Mois",
  year: "Année",
  consumed: "Consommés",
  minutesCharged: "Minutes de charge",
  chargersLastYear: "Sessions (l'an dernier)",
  totalMinutesCharged: "Total des minutes de charge",
  totalCO2Spared: "Total de CO2 économisé",
  spared: "économisé",
  user: "utilisateur",
  users: "Utilisateurs",
  charger: "Hub de recharge",
  date: "Date",
  time: "Session",
  consumption: "Consommation",
  sendFeedback: "Envoyer une suggestion ou remarque",
  subject: "Sujet",
  subjectPlaceholder: "Sélectionner un sujet",
  message: "Message",
  goback: "Retour",
  sendMessage: "Envoyer le message",
  newUser: "Nouvel utilisateur",
  allUsers: "Tous les utilisateurs",
  emptyUsersList: "Commencez par ajouter des utilisateurs à votre équipe",
  emptyUsersListDesc:
    "Après avoir ajouté un utilisateur, un e-mail avec les détails d'activation de compte sera automatiquement envoyé",
  warningInvalidName: "Nom incorrect",
  warningInvalidSurName: "Nom de famille invalide",
  warningInvalidEmail: "Email invalide",
  warningLocations: "Renseignez au moins un sites",
  createUser: "Créer un nouvel utilisateur",
  surname: "Nom de famille",
  endEmail: "Adresse e-mail",
  manageLocations: "Gérer les sites",
  chooseOption: "Choisir une option",
  createUser2: "Créer un utilisateur",
  cancel: "Annuler",
  editUser: "Modifier l'utilisateur",
  saveChanges: "Sauvegarder les modifications",
  state: "Statut",
  removeUser: "Supprimer l'utilisateur",
  resendPassword: "Renvoyer le mot de passe",
  noLocations: "Il n'y a pas d'sites disponible",
  noLocationsDesc: "Il n'y a pas d'sites disponible",
  corporate: "Enseigne",
  corporates: "Enseigne",
  hello: "Bonjour",
  overview: "Aperçu général",
  locations: "Sites",
  location2: "Sites",
  seeAll: "Voir tout",
  logout: "Se déconnecter",
  applyFilter: "Appliquer le filtre",
  selectLocations: "Sélectionnez les sites à afficher",
  anyLocation: "Tous les sites",
  resume: "Récapitulatif",
  period: "Période",
  editAccountData: "Modifier les paramètres de mon compte",
  editData: "Modifier les données",
  idioma: "Langue",
  portuguese: "portugais",
  months: [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ],
  weekDays: [
    {
      name: "dimanche",
      short: "dim",
      isWeekend: true,
    },
    {
      name: "lundi",
      short: "lun",
    },
    {
      name: "mardi",
      short: "mar",
    },
    {
      name: "mercredi",
      short: "mer",
    },
    {
      name: "jeudi",
      short: "jeu",
    },
    {
      name: "vendredi",
      short: "ven",
    },
    {
      name: "samedi",
      short: "sam",
      isWeekend: true,
    },
  ],
  nextMonth: "Le mois prochain",
  previousMonth: "Le mois dernier",
  openMonthSelector: "Sélectionner mois",
  openYearSelector: "Sélectionner année",
  closeMonthSelector: "Fermer l'affichage des mois",
  closeYearSelector: "Fermer l'affichage de l'année",
  defaultPlaceholder: "Sélectionner",
  freq1: "Annuel",
  freq2: "Mensuel",
  freq3: "Hebdomadaire",
  freq4: "Journalier",
  freq5: "Par heure",
  freq: "Fréquence",
  apply: "Appliquer",
  selectTime: "Sélectionnez la fréquence",
  welcomeToPowerdot: "Bienvenue sur la nouvelle plateforme PowerDot TV !",
  step1_desc:
    "Grâce à ce portail, vous pourrez voir des métriques de charge sur votre site !",
  continue: "Continuer",
  step2_title: "Découvrez les métriques",
  step2_desc:
    "Découvrez quelle borne a réalisé la meilleure performance, à quelle heure de la journée a lieu le plus de sessions de charge, ou quel montant de C02 votre hub a permis d'économiser. Organisez vos métriques par fréquence et analysez les sessions de votre sites.",
  jumpIntro: "Ignorer le tutoriel",
  start: "Prochain",
  step3_1_desc:
    "Sur la page d'accueil, vous pouvez visualiser les données absolues et relatives du nombre de sessions de charge, d'énergie consommée, de minutes chargées et de CO2 économisé !",
  step3_2_desc: "Voir les informations sélectionnées par sites.",
  step3_3_desc:
    "Voir les informations sélectionnées par borne. Découvrez la borne avec les meilleures performances.",
  export: "Exporter",
  sameTimeofLastYear: "Même période de l'année précédente",
  contact: "Numéro de téléphone",
  management: "Paramètres",
  langs: {
    PT: "Portugais",
    EN: "Anglais",
    FR: "Français",
    PL: "Polonais",
    ES: "Espagnol",
  },
  signinWarningTitle: "E-mail ou mot de passe incorrect",
  signinWarningDesc:
    "Vos identifiants sont incorrects, veuillez réessayer ou réinitialiser le mot de passe",
  signin: "Se connecter à Power Dot TV",
  emailSent:
    "Utilisez vos identifiants pour vous connecter. Cette plateforme est exclusivement réservée aux partenaires de Power Dot. Si vous n'avez pas vos accès, veuillez contacter partenaires@powerdot.fr",
  saveEmail: "Se rappeler de moi",
  forgotPassword: "Mot de passe oublié?",
  compareToLastPoint: "comparé au point précédent",
  sendFeedbackType1: "Problème avec les données",
  sendFeedbackType2: "Problème d'utilisation de la plateforme",
  sendFeedbackType3: "Problème d'accès",
  sendFeedbackType4: "Autre",
  sendFeedbackMsgPlaceholder: "Ecrivez votre message",
  last30days: "Les 30 derniers jours",
  withMoreCharges: "avec + sessions",
  withMorekWh: "avec + kWh consommés",
  withMoreMinutes: "avec + minutes facturées",
  withMoreSpared: "avec + de CO2 épargné",
  lastYearNoData:
    "Il n'y a pas de données pour la période de l'année précédente.",
  active: "Actif",
  inactive: "Inactif",
  minutes: "Minutes",
  loading: "Chargement",
  faq: "Questions fréquemment posée",
  faqLink: "https://5796726.hs-sites.com/fr/faqs",
  contactUs: "Contactez-nous",
  contactUsLink: "https://5796726.hs-sites.com/fr/kb-tickets/new",
}
