import Service from "./Service";
import Tools from "./../utils/Tools";

var host = process.env.REACT_APP_POWERDOT_TV_BACKEND_HOST + "/tv/v1/charges";

class Charges {
  getChargesByCharger(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/charger?token=" + token,
        data,
        (response) => {
          callback(response);
        }
      );
    });
  }
  getChargesByLocation(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/location?token=" + token,
        data,
        (response) => {
          callback(response);
        }
      );
  });
  }
  exportCharges(data, callback) {
    Tools.getData("token", (token) => {
      Service.makePostRequest(
        host + "/export?token=" + token,
        data,
        (response) => {
          callback(response);
        }
      );
    });
  }
}
export default new Charges();
