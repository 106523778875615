
import React, { Component } from "react";
import { Col, Row, Form, Card, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import iconPowerdot from "../../assets/tv/power-dot-in-partnership-uber-artboard-1.png";
import User from "../../Service/User";
import AlertModal from "./../../components/AlertModal";
import stringfile from "../../utils/stringfile";
import Preloader from "../../components/Preloader";
window.strings = stringfile.getStrings();
export default class SendFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      message: "",
      showModal: false,
      modalTitle: "",
      formValues: [
        "Problema com dados",
        "Problema com a utilização da plataforma",
        "Problema com acessos ",
        "Outros"
      ],
      selectedValue: "",
      modalDesc: "",
      modalType: false,
      loading:false,
    };
    this.sendFeedback = this.sendFeedback.bind(this)
  }


  componentDidMount(){
    User.getProfile((res) => {
      if (!res.error) {
        window.language = res.profile.user.lang;
        window.strings = stringfile.getStrings();
        this.setState({
          loading:true,
          title: window.strings.sendFeedbackType1
        });
      } 
    });
  }


  sendFeedback() {
    let data = {
      title: this.state.title,
      message: this.state.message,
    };
    User.sendFeedback(data, (res) => {
      if (!res.error) {
        this.setState({
          showModal: true,
          modalTitle: window.strings.successMsg,
          modalDesc: res.message,
          modalType: true,
        })
      } else {
        this.setState({
          showModal: true,
          modalTitle: window.strings.errorMsg,
          modalDesc: res.message,
          modalType: false,
        })
      }
    })
  }



  handleTitle(event) {
    this.setState({ title: event.target.value });
  }

  handleMessage(event) {
    this.setState({ message: event.target.value });
  }

  closeModal() {
    this.setState({ showModal: false });
    if (this.state.modalTitle != "" && this.state.modalType == true) {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <main>
        <span>
          <img
            style={{ resize: "contain", marginLeft: 50, marginTop: 50 }}
            src={iconPowerdot}
          />
        </span>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center"></p>
            <Row className="justify-content-center form-bg-image">
              <Col
                xs={12} xl={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-700">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">{window.strings.sendFeedback}</h3>
                    <span>

                    </span>
                  </div>
                  <Form className="mt-4">

                    <Form.Group id="assunto" className="mb-4">
                      <Form.Label>{window.strings.subject}</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        type="assunto"
                        placeholder={window.strings.subjectPlaceholder}
                        onChange={this.handleTitle.bind(this)}
                        value={this.state.title == "" ? window.strings.sendFeedbackType1 :  this.state.title}
                      >
                            <option  value={window.strings.sendFeedbackType1}>{window.strings.sendFeedbackType1}</option>
                            <option  value={window.strings.sendFeedbackType2}>{window.strings.sendFeedbackType2}</option>
                            <option  value={window.strings.sendFeedbackType3}>{window.strings.sendFeedbackType3}</option>
                            <option  value={window.strings.sendFeedbackType4}>{window.strings.sendFeedbackType4}</option>
                      </Form.Control>

                    </Form.Group>


                    <Form.Group id="mensagem" className="mb-4">
                      <Form.Label>{window.strings.message}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          as="textarea" rows={6}
                          type="mensagem"
                          placeholder={window.strings.sendFeedbackMsgPlaceholder}
                          onChange={this.handleMessage.bind(this)}
                          value={this.state.message}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "center", alignItems: "center" }}>

                      <Card.Link
                        style={{ flex: 1, textAlign: "center", color: "#62757A", fontSize: 14, textTransform: "uppercase" }}
                        as={Link}
                        to={Routes.Overview.path}
                      >
                        {window.strings.goback}
                      </Card.Link>

                      <div
                        style={{ flex: 1 }}
                        className="auth-button"
                        onClick={this.sendFeedback}
                      >
                        {/* <Card.Link
                          // to={Routes.Overview.path}
                          className="fw-bold text-white"
                          style={{ fontSize: 17 }}
                        > */}
                          {window.strings.sendMessage}
                        {/* </Card.Link> */}
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <div
        style={{
          margin: 50,
          marginTop:120,
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <a
            style={{
              marginRight: 30,
              fontSize: 17,
              color: "#62757A",
              cursor: "pointer",
            }}
          >
            Termos e Condições
          </a>
          <a
            style={{
              marginRight: 30,
              fontSize: 17,
              color: "#62757A",
              cursor: "pointer",
            }}
          >
            Política de Privacidade
          </a>
        </div>
      </div> */}
        <AlertModal showModal={this.state.showModal} closeModal={this.closeModal.bind(this)} title={this.state.modalTitle} desc={this.state.modalDesc} success={this.state.modalType} />
        <Preloader show={!this.state.loading} />
      </main>
    );
  }
}
