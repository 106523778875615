import Service from "./Service";
import Tools from "./../utils/Tools"
var host = process.env.REACT_APP_POWERDOT_TV_BACKEND_HOST + "/tv/v1/users";

class Auth {
  login(data, callback) {
    Service.makePostRequest(host + "/authenticate", data, (result) => {
      callback(result);
    });
  }

  register(data, callback) {
    Tools.getData('token',(token) => {
			Service.makePostRequest(host+'/create?token='+token,data,(response) => {
				callback(response);
			});	
		})
	}

  changePassword(data, callback) {
    Service.makePostRequest(host + "/changepassword", data, (response) => {
      callback(response);
    });
  }

  recover(data, callback) {
    Service.makePostRequest(host + "/recoverpassword", data, (response) => {
      callback(response);
    });
  }

}
export default new Auth();
