import React, { Component } from "react"
import Preloader from "../../components/Preloader"
import SearchBar from "../components/SearchBar"
import UsersList from "./components/UsersList"
import CreateModal from "./components/CreateModal"
import AlertModal from "./../../components/AlertModal"
import Users_Service from "../../Service/User"

export default class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUser: null,
      showNewUser: false,
      users: [],
      loading: true,
      showModal: false,
      modalTitle: "",
      modalDesc: "",
      modalType: false,
    }
  }

  componentDidMount() {
    this.getUsers()
  }

  getUsers(data) {
    this.setState({ loading: true })
    Users_Service.getAllUsers(data, (res) => {
      if (!res.error) {
        this.setState({
          users: res.users,
          loading: false,
        })
      } else {
        console.log(window.strings.errorInf)
        window.history.back()
      }
    })
  }

  selectUser(data) {
    console.log("data:", data)
    this.setState({ selectedUser: data })
  }

  showNewUser() {
    this.setState({ showNewUser: !this.state.showNewUser })
  }
  closeCreateModal() {
    this.setState({ showNewUser: false })
  }

  showAlert(error, msg) {
    if (!error) {
      this.setState({
        showModal: true,
        modalTitle: window.strings.successMsg,
        modalDesc: msg,
        modalType: true,
      })
    } else {
      this.setState({
        showModal: true,
        modalTitle: window.strings.errorMsg,
        modalDesc: msg,
        modalType: false,
      })
    }
  }

  createUser(error, msg) {
    if (!error) {
      this.setState({ loading: true })

      this.getUsers()
      this.setState({
        showModal: true,
        modalTitle: window.strings.successMsg,
        modalDesc: msg,
        modalType: true,
      })
    } else {
      this.setState({
        showModal: true,
        modalTitle: window.strings.errorMsg,
        modalDesc: msg,
        modalType: false,
      })
    }
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <div
        className="dashboards-content"
        style={{ display: "flex", flexDirection: "column", paddingBottom: 350 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 32,
              color: "#041114",
              fontWeight: "bold",
              flex: 1,
            }}
          >
            {window.strings.users}
          </span>
          <span
            className="new-user-btn"
            onClick={this.showNewUser.bind(this)}
            style={{
              fontWeight: "bold",
              fontSize: 15,
              color: this.state.showNewUser ? "#ffffff" : "#041114",
              padding: "18px 55px",
              backgroundColor: this.state.showNewUser
                ? "rgb(4, 17, 20)"
                : "#ffffff",
              cursor: "pointer",
              border: "1px solid rgba(162, 179, 184, 0.4)",
              borderRadius: 16,
            }}
          >
            {window.strings.newUser}
          </span>
        </div>
        <span
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "rgba(162, 179, 184, 0.2)",
            borderRadius: 1,
            margin: "15px 0px 15px 0px",
          }}
        />
        <span
          style={{
            fontSize: 20,
            color: "#041114",
            fontWeight: "bold",
            marginTop: 50,
          }}
        >
          {window.strings.allUsers}{" "}
          <span
            style={{
              borderRadius: 16,
              backgroundColor: "#041114",
              fontSize: 15,
              textAlign: "center",
              color: "#ffffff",
              padding: "3px 8px",
            }}
          >
            {this.state.users.length}
          </span>
        </span>
        <span
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "rgba(162, 179, 184, 0.2)",
            borderRadius: 1,
            margin: "15px 0px 32px 0px",
          }}
        />
        <SearchBar
          users={this.state.users}
          selectUser={this.selectUser.bind(this)}
        />
        <span style={{ marginTop: 20 }} />
        {this.state.users.length > 0 ? (
          <UsersList
            profile={this.props.profile}
            selected_locations={this.props.locations}
            getUsers={this.getUsers.bind(this)}
            showAlert={this.showAlert.bind(this)}
            selectedUser={this.state.selectedUser}
            users={this.state.users}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 80,
              alignItems: "center",
            }}
          >
            <span
              style={{ fontSize: 20, color: "#041114", textAlign: "center" }}
            >
              {window.strings.emptyUsersList}
            </span>
            <span
              style={{
                marginTop: 30,
                fontSize: 17,
                color: "#62757A",
                textAlign: "center",
              }}
            >
              {window.strings.emptyUsersListDesc}
            </span>
            <div
              className="new-user-btn"
              onClick={this.showNewUser.bind(this)}
              style={{
                marginTop: 50,
                fontWeight: "bold",
                fontSize: 15,
                color: this.state.showNewUser ? "#ffffff" : "#041114",
                padding: "18px 55px",
                backgroundColor: this.state.showNewUser
                  ? "rgb(4, 17, 20)"
                  : "#ffffff",
                cursor: "pointer",
                border: "1px solid rgba(162, 179, 184, 0.4)",
                borderRadius: 16,
              }}
            >
              {window.strings.newUser}
            </div>
          </div>
        )}
        {this.state.showNewUser && (
          <CreateModal
            createUser={this.createUser.bind(this)}
            // active_corporate={this.props.active_corporate}
            selected_locations={this.props.locations}
            showNewUser={this.state.showNewUser}
            closeCreateModal={this.closeCreateModal.bind(this)}
          />
        )}
        <AlertModal
          showModal={this.state.showModal}
          closeModal={this.closeModal.bind(this)}
          title={this.state.modalTitle}
          desc={this.state.modalDesc}
          success={this.state.modalType}
        />
        <Preloader show={this.state.loading} />
      </div>
    )
  }
}
