import React, { Component } from "react"
import { Nav } from "@themesberg/react-bootstrap"
import dayjs from "dayjs"
import { faAngleDown, faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { Routes } from "../routes"
import LocationsModal from "./LocationsModal"
import CalendarModal from "./CalendarModal"
import { DATE_FORMAT } from "../utils/constants"

export default class Filters extends Component {
  constructor(props) {
    super(props)
    let today_temp = new Date()
    let today = new Date(
      today_temp.getFullYear(),
      today_temp.getMonth(),
      today_temp.getDate(),
      0,
      0,
      0
    )
    let datelastYear = new Date(
      today_temp.getFullYear(),
      today_temp.getMonth() - 1,
      today_temp.getDate(),
      0,
      0,
      0
    )

    let datelastYearStr = dayjs().subtract(1, "M").format(DATE_FORMAT)
    let todayStr = dayjs().endOf("day").format(DATE_FORMAT)

    this.state = {
      showLocationsModal: false,
      showCalendarModal: false,
      selectedSpaceString: "",
      locations: this.props.locations,
      // active_corporate: this.props.active_corporate,
      selectedDateString: null,
      selectedDatesObj: {
        from: {
          year: datelastYear.getFullYear(),
          month: datelastYear.getMonth() + 1,
          day: datelastYear.getDate(),
        },

        to: {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate(),
        },
      },
      selectedDates: {
        from: datelastYearStr,
        fromHour: 0,
        to: todayStr,
        toHour: 0,
      },
      frequency: this.props.currentFrequency,
    }
    this.closeLocationsModal = this.closeLocationsModal.bind(this)
    this.closeCalendarModal = this.closeCalendarModal.bind(this)
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.active_corporate !== this.props.active_corporate) {
  //     this.setState(
  //       {
  //         // active_corporate: nextProps.active_corporate,
  //         // locations: nextProps.active_corporate.locations,
  //         selectedSpaceString: window.strings.anyLocation,
  //         selectedDateString:
  //           null,
  //       },
  //       () => this.getData()
  //     );
  //   }

  //   return true;
  // }

  closeLocationsModal() {
    this.setState({ showLocationsModal: false })
  }

  closeCalendarModal() {
    this.setState({ showCalendarModal: false })
  }

  selectLocations(data, callback) {
    this.props.changeActiveLocations(data)
    this.setState(
      {
        locations: data,
        selectedSpaceString: data.map((value) => value.name).join(", "),
      },
      () =>
        callback(
          this.props.updateLocationsIds(
            JSON.stringify(data.map((value) => value.id)),
            () => {
              this.getData()
            }
          )
        )
    )
  }

  getPeriodString(dates) {
    return (
      window.strings.from +
      " " +
      dates.from.split(" ")[0] +
      " " +
      window.strings.from +
      " " +
      dates.to.split(" ")[0]
    )
  }

  selectDates(date, freq, callback) {
    let selectedDateString = this.getPeriodString(date)
    this.setState(
      { selectedDates: date, frequency: freq, selectedDateString },
      () =>
        callback(
          this.props.updateDate(
            this.state.selectedDates.from,
            dayjs(this.state.selectedDates.to).endOf("day").format(DATE_FORMAT),
            this.state.selectedDates.fromHour,
            this.state.selectedDates.toHour,
            this.state.frequency,
            () => {
              this.getData()
            }
          )
        )
    )
  }

  getData() {
    if (this.state.locations !== null) {
      let data = {
        // corporate_id: this.props.active_corporate.id,
        locations_id:
          this.props.navLocation != null
            ? JSON.stringify([this.props.navLocation.id])
            : JSON.stringify(this.state.locations.map((value) => value.id)),
        frequency: this.state.frequency,
        from: this.state.selectedDates.from,
        to: this.state.selectedDates.to,
        fromHour: this.state.selectedDates.fromHour,
        toHour: this.state.selectedDates.toHour,
        data_last_freq: false,
        page: 1,
        items_per_page: 10,
      }
      this.props.updateData(data)
    }
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 25 }}>
        {this.props.navLocation == null && (
          <span
            style={{
              color: "#041114",
              fontSize: 32,
              marginLeft: 15,
              paddingLeft: 100,
              paddingRight: 100,
            }}
          >
            {window.strings.overview}
          </span>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 12,
            backgroundColor: "rgba(1,180,224,0.04)",
            paddingLeft: 100,
            paddingRight: 100,
          }}
        >
          <Nav fill defaultActiveKey={"resumo"}>
            <Nav.Item>
              <Nav.Link
                eventKey="resumo"
                as={Link}
                to={Routes.Overview.path}
                className="dashboard-filter-tabs"
                active={window.location.pathname == Routes.Overview.path}
              >
                {window.strings.resume}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={Routes.Charges.path}
                eventKey="carregamentos"
                className="dashboard-filter-tabs"
                active={window.location.pathname == Routes.Charges.path}
              >
                {window.strings.charges2}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={Routes.Consumed.path}
                eventKey="consumo"
                className="dashboard-filter-tabs"
                active={window.location.pathname == Routes.Consumed.path}
              >
                kWh {window.strings.consumed}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={Routes.Minutes.path}
                eventKey="minutos"
                className="dashboard-filter-tabs"
                active={window.location.pathname == Routes.Minutes.path}
              >
                {window.strings.minutesCharged}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={Routes.Spared.path}
                eventKey="poupado"
                className="dashboard-filter-tabs"
                active={window.location.pathname == Routes.Spared.path}
              >
                CO2 {window.strings.spared}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#ffffff",
            paddingLeft: 120,
            paddingRight: 100,
            paddingTop: 12,
            paddingBottom: 12,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {!this.props.navLocation && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginRight: 38,
                }}
              >
                <span
                  style={{ color: "#62757A", fontSize: 15, marginRight: 15 }}
                >
                  {window.strings.location2}:
                </span>
                <div
                  onClick={() => {
                    this.setState({ showLocationsModal: true })
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: 16,
                    borderWidth: 1,
                    borderColor: "#A2B3B8",
                    borderStyle: "solid",
                    padding: "10px 20px",
                    minWidth: 250,
                    maxWidth: 450,
                  }}
                >
                  <span
                    style={{
                      flex: 1,
                      marginRight: 20,
                      fontWeight: "bold",
                      color: "#62757A",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      fontSize: 15,
                    }}
                  >
                    {this.state.selectedSpaceString ||
                      window.strings.anyLocation}
                  </span>
                  <FontAwesomeIcon color={"#A2B3B8"} icon={faAngleDown} />
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginRight: 38,
              }}
            >
              <span style={{ color: "#62757A", fontSize: 15, marginRight: 15 }}>
                {window.strings.period}:
              </span>
              <div
                onClick={() => {
                  this.setState({ showCalendarModal: true })
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: 16,
                  borderWidth: 1,
                  borderColor: "#A2B3B8",
                  borderStyle: "solid",
                  padding: "10px 20px",
                  minWidth: 250,
                  maxWidth: 450,
                }}
              >
                <span
                  style={{
                    flex: 1,
                    marginRight: 20,
                    fontWeight: "bold",
                    color: "#62757A",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: 15,
                  }}
                >
                  {this.state.selectedDateString == null
                    ? window.strings.last30days
                    : this.state.selectedDateString}
                </span>
                <FontAwesomeIcon color={"#A2B3B8"} icon={faCalendarAlt} />
              </div>
            </div>
          </div>
        </div>
        <LocationsModal
          locations={this.props.locations}
          selectLocations={this.selectLocations.bind(this)}
          showModal={this.state.showLocationsModal}
          closeModal={this.closeLocationsModal.bind(this)}
        />
        <CalendarModal
          frequency={this.state.frequency}
          selectedDates={this.state.selectedDatesObj}
          selectDates={this.selectDates.bind(this)}
          showModal={this.state.showCalendarModal}
          closeModal={this.closeCalendarModal.bind(this)}
        />
      </div>
    )
  }
}
