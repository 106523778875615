import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons"
import {
  Col,
  Row,
  Form,
  Card,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap"
import { Link } from "react-router-dom"
import ReactLoading from "react-loading"
import { Routes } from "../../routes"
import powerdotLogo from "../../assets/img/brand/logo.png"
import AlertModal from "../../components/AlertModal"
import Auth from "../../Service/Auth"
import Tools from "../../utils/Tools"

export default class Signin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email:
        localStorage.getItem("login_email") !== undefined &&
        localStorage.getItem("login_email") !== null
          ? localStorage.getItem("login_email")
          : "",
      password: "",
      // password: "",
      remember: false,
      profile: undefined,
      modalTitle: "",
      modalDesc: "",
      modalType: false,
      showModal: false,
      loginLoading: false,
    }
    this.login = this.login.bind(this)
    this.handlePassword = this.handlePassword.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  login() {
    let email = this.state.email
    let password = this.state.password
    this.setState({ loginLoading: true })

    let data = {
      email,
      password,
    }
    Auth.login(data, (response) => {
      console.log(response)
      if (!response.error) {
        this.setState({ profile: response.profile, loginLoading: false })
        Tools.setData("token", response.token)
        if (this.state.remember === true) {
          Tools.setData("login_email", this.state.email)
        } else {
          localStorage.removeItem("login")
        }
        window.location.href = "/"
      } else {
        this.setState({
          showModal: true,
          modalTitle: window.strings.signinWarningTitle,
          modalDesc: window.strings.signinWarningDesc,
          modalType: false,
          loginLoading: false,
        })
      }
    })
  }

  handlePassword(event) {
    this.setState({ password: event.target.value })
  }

  handleEmail(event) {
    this.setState({ email: event.target.value })
  }

  handleRemember(event) {
    this.setState({ remember: event.target.checked })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center mb-5"
              >
                <img src={powerdotLogo} height="32px" />
              </Col>

              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">{window.strings.signin}</h3>
                    <span>{window.strings.emailSent}</span>
                  </div>
                  <Form
                    className="mt-4"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.login()
                      }
                    }}
                  >
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={this.handleEmail.bind(this)}
                          autoFocus
                          required
                          type="email"
                          value={this.state.email}
                          placeholder={window.strings.insertEmail}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>{window.strings.password}</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            onChange={this.handlePassword.bind(this)}
                            required
                            value={this.state.password}
                            type="password"
                            placeholder={window.strings.insertPassword}
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Form.Check type="checkbox">
                          <FormCheck.Input
                            id="defaultCheck5"
                            value={this.state.remember}
                            onChange={this.handleRemember.bind(this)}
                            className="me-2"
                          />
                          <FormCheck.Label
                            htmlFor="defaultCheck5"
                            className="mb-0"
                          >
                            {window.strings.saveEmail}
                          </FormCheck.Label>
                        </Form.Check>
                      </div>
                    </Form.Group>

                    <div className="auth-button" onClick={this.login}>
                      {window.strings.signin}
                      {this.state.loginLoading && (
                        <ReactLoading
                          className="auth-loading"
                          type={"spin"}
                          color={"#ffffff"}
                          height={25}
                          width={25}
                        />
                      )}
                    </div>
                  </Form>

                  <div className="mt-3 mb-4 text-center">
                    <Card.Link
                      className="small text-end"
                      as={Link}
                      to={Routes.LostPassword.path}
                      style={{ textTransform: "uppercase" }}
                    >
                      {window.strings.forgotPassword}
                    </Card.Link>
                  </div>
                </div>
              </Col>
            </Row>
            <AlertModal
              showModal={this.state.showModal}
              closeModal={this.closeModal.bind(this)}
              title={this.state.modalTitle}
              desc={this.state.modalDesc}
              success={this.state.modalType}
            />
          </Container>
        </section>
        {/* <div
          style={{
            margin: 50,
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <a
              style={{
                marginRight: 30,
                fontSize: 17,
                color: "#62757A",
                cursor: "pointer",
              }}
            >
              Termos e Condições
            </a>
            <a
              style={{
                marginRight: 30,
                fontSize: 17,
                color: "#62757A",
                cursor: "pointer",
              }}
            >
              Política de Privacidade
            </a>
          </div>
          <div
            style={{
              maxWidth: 300,
              backgroundColor: "rgba(1, 180, 224, 0.1)",
              padding: 16,
              paddingRight: 60,
            }}
          >
            <span style={{ fontSize: 15, color: "#62757A" }}>
              Pretende criar uma conta para aceder à plataforma?{" "}
              <Card.Link
                as={Link}
                to={Routes.Signup.path}
                className="fw-bold"
                style={{ cursor: "pointer" }}
              >
                Saiba mais.
              </Card.Link>
            </span>
          </div>
        </div> */}
      </main>
    )
  }
}
