import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap"
import { Link } from "react-router-dom"
import { Routes } from "../../routes"
import iconPowerdot from "../../assets/tv/power-dot-in-partnership-uber-artboard-1.png"
import Auth from "../../Service/Auth"
import AlertModal from "./../../components/AlertModal"
export default class Password extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      showModal: false,
      modalTitle: "",
      modalDesc: "",
      modalType: false,
    }
    this.recover = this.recover.bind(this)
  }

  closeModal() {
    this.setState({ showModal: false })
    if (this.state.modalTitle != "" && this.state.modalType == true) {
      window.location.href = "/"
    }
  }

  recover() {
    let data = {
      email: this.state.email,
    }
    Auth.recover(data, (res) => {
      if (!res.error) {
        this.setState({
          showModal: true,
          modalTitle: window.strings.successMsg,
          modalDesc: res.message,
          modalType: true,
        })
      } else {
        this.setState({
          showModal: true,
          modalTitle: window.strings.errorMsg,
          modalDesc: res.message,
          modalType: false,
        })
      }
    })
  }

  handleEmail(event) {
    this.setState({ email: event.target.value })
  }
  renderLostPassword() {
    return (
      <Form className="mt-4">
        <Form.Group id="email" className="mb-4">
          <Form.Label>Email</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faEnvelope} />
            </InputGroup.Text>
            <Form.Control
              onChange={this.handleEmail.bind(this)}
              autoFocus
              required
              type="email"
              placeholder={window.strings.insert + " email"}
            />
          </InputGroup>
        </Form.Group>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <Button
            variant="primary"
            type="submit"
            style={{ flex: 1 }}
            onClick={this.recover}
          >
            <Card.Link
              as={Link}
              // to={Routes.Overview.path}
              className="fw-bold text-white"
              style={{ fontSize: 17 }}
            >
              {`${
                window.strings.recover
              } ${window.strings.password.toLowerCase()}`}
            </Card.Link>
          </Button>
          <Card.Link
            style={{
              flex: 1,
              padding: 25,
              textAlign: "center",
              color: "#62757A",
              fontSize: 14,
              textTransform: "uppercase",
            }}
            as={Link}
            to={Routes.Signin.path}
          >
            Voltar
          </Card.Link>
        </div>
        <AlertModal
          showModal={this.state.showModal}
          closeModal={this.closeModal.bind(this)}
          title={this.state.modalTitle}
          desc={this.state.modalDesc}
          success={this.state.modalType}
        />
      </Form>
    )
  }

  render() {
    return (
      <main>
        <span>
          <img
            style={{ resize: "contain", marginLeft: 50, marginTop: 50 }}
            src={iconPowerdot}
          />
        </span>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center"></p>
            <Row className="justify-content-center form-bg-image">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500"
                  style={{ minHeight: 550 }}
                >
                  <div className="text-center text-md-center mb-5 mt-md-0">
                    <h3 className="mb-0">{`${window.strings.recover} ${window.strings.password}`}</h3>
                    <span>{window.strings.passwordEmailSent}</span>
                  </div>
                  {this.renderLostPassword()}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <div
        style={{
          margin: 50,
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <a
            style={{
              marginRight: 30,
              fontSize: 17,
              color: "#62757A",
              cursor: "pointer",
            }}
          >
            Termos e Condições
          </a>
          <a
            style={{
              marginRight: 30,
              fontSize: 17,
              color: "#62757A",
              cursor: "pointer",
            }}
          >
            Política de Privacidade
          </a>
        </div>
        <div
          style={{
            maxWidth: 300,
            backgroundColor: "rgba(1, 180, 224, 0.1)",
            padding: 16,
            paddingRight: 60,
          }}
        >
          <span style={{ fontSize: 15, color: "#62757A" }}>
            Pretende criar uma conta para aceder à plataforma?{" "}
            <Card.Link
              as={Link}
              to={Routes.Signup.path}
              className="fw-bold"
              style={{ cursor: "pointer" }}
            >
              Saiba mais.
            </Card.Link>
          </span>
        </div>
      </div> */}
      </main>
    )
  }
}
