import React from "react"
import { Spinner } from "@themesberg/react-bootstrap"

export default (props) => {
  const { show, fullPage } = props

  const className = [
    "preloader",
    fullPage ? "full-page" : "",
    "flex-column",
    "justify-content-center",
    "align-items-center",
    "bg-white",
    show ? "" : "show",
  ]

  return (
    <div className={className.join(" ")}>
      <Spinner
        animation="grow"
        style={{ backgroundColor: "rgb(0, 184, 235)" }}
      />
      <p className="mt-3" style={{ color: "#506690" }}>
        {window.strings.loading}
      </p>
    </div>
  )
}
