import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from "@themesberg/react-bootstrap";

const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number,
}

const defaultProps = {
    initialPage: 1,
    pageSize: 10
}

class Paginator extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {} };
    }

    UNSAFE_componentWillMount() {
        // set page if items array isn't empty
        if (this.props.items && this.props.items.length) {
            this.setPage(this.props.initialPage);
        }
    }

    setPage(page) {
        var { items, pageSize } = this.props;
        var pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(items.length, page, pageSize);

        // get new page of items from items array

        // update state
        this.setState({ pager: pager });

        // call change page function in parent component
        this.props.onChangePage(page);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        var pager = this.state.pager;
        var nrPages = Math.ceil(this.props.items.length / this.props.pageSize);
        pager.totalPages = nrPages;
        pager.endPage = nrPages;
        if(pager.currentPage > pager.totalPages) {
            pager.currentPage = 1;
        }
        if (nrPages <= 10) {
          // less than 10 total pages so show all
          pager.startPage = 1;
          pager.endPage = nrPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (pager.currentPage <= 6) {
              pager.startPage = 1;
              nrPages > 10 ? pager.endPage = 10 : pager.endPage = nrPages;
            } else if (pager.currentPage + 4 >= nrPages) {
              pager.startPage = nrPages - 9;
              pager.endPage = nrPages;
            } else {
              pager.startPage = pager.currentPage - 5;
              pager.endPage = pager.currentPage + 4;
            }
        }


        var pages = [...Array((pager.endPage + 1) - pager.startPage).keys()].map(i => pager.startPage + i);
        pager.pages = pages;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }

        return (
            <Pagination>


                {pager.currentPage === 1 ?
                  <Pagination.First disabled/>
                :
                  <Pagination.First onClick ={()=>{this.setPage(1)}}/>
                }
                {pager.currentPage === 1 ?
                  <Pagination.Prev disabled/>
                :
                  <Pagination.Prev onClick ={()=>{this.setPage(pager.currentPage - 1)}}/>
                }


                {pager.pages.map((page, index) =>{
                    return(
                      <div key={index}>
                      {pager.currentPage === page ? 
                      
                      <Pagination.Item active>{page}</Pagination.Item>
                      :
                      <Pagination.Item onClick = {()=>{this.setPage(page)}}>{page}</Pagination.Item>

                      }
                      </div>
                    )
                    
                  }
                )}
                {pager.currentPage === pager.totalPages ?
                  <Pagination.Next disabled/>
                :
                  <Pagination.Next onClick ={()=>{this.setPage(pager.currentPage + 1)}}/>
                }
                {pager.currentPage === pager.totalPages ?
                  <Pagination.Last disabled/>
                :
                  <Pagination.Last onClick ={()=>{this.setPage(pager.totalPages)}}/>
                }

            </Pagination> 
        );
    }
}

Paginator.propTypes = propTypes;
Paginator.defaultProps = defaultProps;
export default Paginator;