import React,{Component} from 'react';
import {
    Modal,
    Image
  } from "@themesberg/react-bootstrap";
import powerdotW from "../../assets/tv/powerdotW.png"
import hand_img from "../../assets/tv/group-7@2x.png"
import arrow from "../../assets/tv/icons-arrow-white.png"
export default class Popup_step1 extends Component {
constructor(props) {
    super(props);
    this.state = {
        showStep1:this.props.showStep1,
    };
    this.oncloseModal = this.oncloseModal.bind(this);
  }

  oncloseModal(step) {
    this.props.closeStep1Modal(step,()=>{
      this.setState({ showStep1: false });
    })
  } 
    
    render() {
        return (
            <Modal
            as={Modal.Dialog}
            show={this.props.showStep1}
            id={"step-modal"}
            onHide={this.oncloseModal.bind(this,false)}
            >
                <Modal.Header style={{alignItems:"flex-start",backgroundColor:"#00b4e0",borderBottomColor:"rgba(0, 104, 133, 0.2)",padding:"30px 30px 25px 30px"}} >
                <div style={{flex:1,display:"flex",flexDirection:"row"}}>
                <div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-end"}}>
                <Image src={powerdotW} style={{width:85}}/>
                <span style={{fontSize:19,fontWeight:"bold", marginTop: 12, color: "#ffffff"}}>
                {window.strings.welcomeToPowerdot}
                </span>
                </div>
                <Image src={hand_img} style={{resize:"contains",maxWidth:100}}/>
                </div>
              </Modal.Header>
            <Modal.Body style={{padding:"25px 30px 0px 30px", backgroundColor:"#00b4e0"}}>
            <span style={{fontSize:15, color: "#ffffff"}}>
            {window.strings.step1_desc}
            </span>
            </Modal.Body>
            <Modal.Footer style={{padding:0, margin:0, backgroundColor:"#00b4e0",borderTopColor:"#00b4e0" }}>
            <div
                style={{flex:1,padding:"25px 25px 25px 25px",cursor:"pointer",display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}
                onClick={this.oncloseModal.bind(this,true)}
              >
             <span style={{fontSize:17,fontWeight:"bold", color: "#ffffff",marginRight:4}}>
           {window.strings.continue}
            </span>
            <Image src={arrow}/>
                  </div>
             </Modal.Footer>
          </Modal>

        );
    }
}
