export const Routes = {
  // pages
  Signin: { path: "/Signin" },
  LostPassword: { path: "/password" },
  ChangePassword: { path: "/change-password/:token?" },

  Overview: { path: "/" },
  Charges: { path: "/dashboard/charges" },
  Consumed: { path: "/dashboard/consumed" },
  Minutes: { path: "/dashboard/minutes" },
  Spared: { path: "/dashboard/spared" },

  Users: { path: "/users" },
  Chargers: { path: "/chargers" },
  Locations: { path: "/locations" },
  Partners: { path: "/partners" },

  SendFeedback: { path: "/Send-Feedback" },

  // Signin: { path: "/examples/sign-in" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars/" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
