import React,{Component} from 'react';
import {
    Button,
    Modal,
    Image
  } from "@themesberg/react-bootstrap";
import powerdotB from "../../assets/tv/power-dot-Black.png"
import arrow from "../../assets/tv/icons-arrow-grey.png"
import powerdotModal from "../../assets/tv/powerdot-modal.png"

export default class Popup_step2 extends Component {
constructor(props) {
    super(props);
    this.state = {
        showStep2:this.props.showStep2,
    };
    this.oncloseModal = this.oncloseModal.bind(this);
  }

  oncloseModal(step) {
    this.props.closeStep2Modal(step,()=>{
      this.setState({ showStep2: false });
    })
  } 
    
    render() {
        return (
            <Modal
            as={Modal.Dialog}
            show={this.props.showStep2}
            id="step2-modal"
            centered
            onHide={this.oncloseModal.bind(this,false)}
          >
                <Modal.Header style={{alignItems:"flex-start",backgroundColor:"#ffffff",borderBottomColor:"rgba(98, 117, 122, 0.2)",padding:0,margin:0}} >
                <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                <div style={{
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"flex-start",
                backgroundColor:"#ffffff",
                backgroundImage: `url(${powerdotModal})`, /* fallback */
                minHeight:300,
                backgroundSize:"contain",
                }}>
                   <Button
                style={{padding:36,color:"ffffff"}}
                variant="close"
                aria-label="Close"
                className={"white"}
                onClick={this.oncloseModal.bind(this,false)}
              />
                </div>
                
                <div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-end",padding:"30px 30px 25px 30px"}}>
                <Image src={powerdotB} style={{width:85}}/>
                <span style={{fontSize:19,fontWeight:"bold", marginTop: 12, color: "#041114"}}>
                {window.strings.step2_title}
                </span>
                </div>
                </div>
              </Modal.Header>
            <Modal.Body style={{padding:"25px 30px 0px 30px", backgroundColor:"#ffffff"}}>
            <span style={{fontSize:15, color: "#62757A"}}>
            {window.strings.step2_desc}
            </span>
            </Modal.Body>
            <Modal.Footer style={{padding:"10px 10px 10px 10px", margin:0, backgroundColor:"#ffffff",borderTopColor:"#ffffff" }}>
            <div
                style={{flex:1,padding:"15px 15px 15px 15px",cursor:"pointer",display:"flex",flexDirection:"row",alignItems:"flex-start",justifyContent:"flex-start"}}
                onClick={this.oncloseModal.bind(this,null)}
              >
            <span style={{fontSize:15,fontWeight:"bold", color: "#62757A"}}>
            {window.strings.jumpIntro}
            </span>
            </div>
            <div
                style={{padding:"15px 15px 15px 15px",cursor:"pointer",display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}
                onClick={this.oncloseModal.bind(this,true)}
              >
            <span style={{fontSize:15,fontWeight:"bold", color: "#041114",marginRight:4}}>
            {window.strings.start}
            </span>
            <Image src={arrow}/>
                  </div>
             </Modal.Footer>
          </Modal>

        );
    }
}
