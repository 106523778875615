import React,{Component} from 'react';
import {
    Button,
    Modal,
  } from "@themesberg/react-bootstrap";

export default class AlertModal extends Component {
constructor(props) {
    super(props);
    this.state = {
        showModal:this.props.showModal,
    };
    this.oncloseModal = this.oncloseModal.bind(this);
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.showModal === true){
      setTimeout(() => {
         this.oncloseModal();
        }
        , 2000);
    }
     
    return true;
  }

  oncloseModal() {
    this.props.closeModal(()=>{
      this.setState({ showModal: false });
    })
  }
  
    render() {
        return (
            <Modal
            id="alert-modal"
            as={Modal.Dialog}
            show={this.props.showModal}
            contentClassName={"auth-modal"}
            onHide={this.oncloseModal.bind(this)}
          >
            <Modal.Body style={{padding:0,borderRadius:2}}>
              <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                <div style={{display:"flex",flexDirection:"row",flex:1}}>
                <div style={{width:8,backgroundColor:this.props.success ? "#07B865" : "#EB132C",height:"100%",position:"absolute"}}> </div>
                <div style={{marginLeft:30, minHeight:100,display:"flex",flexDirection:"column",paddingTop:18,paddingBottom:18}}>
              <Modal.Title className="h6">{this.props.title}</Modal.Title>
              <span>{this.props.desc}</span>
              </div>
              </div>
              <Button
                style={{padding:36}}
                variant="close"
                aria-label="Close"
                onClick={this.oncloseModal.bind(this)}
              />
              </div>
            </Modal.Body>
          </Modal>

        );
    }
}
