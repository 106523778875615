import React, { Component } from "react"
import { Navbar, Container, Accordion } from "@themesberg/react-bootstrap"
import User from "../Service/User"
import Tools from "../utils/Tools"
import EditModal from "./EditModal"
import AlertModal from "./AlertModal"
import Popup_step1 from "../components/onBoarding/Popup_step1"
import Popup_step2 from "../components/onBoarding/Popup_step2"
import Popup_step3 from "../components/onBoarding/Popup_step3"

export default class DashboardsNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: this.props.profile,
      accordion: 0,
      overlay: 0,
      inc: 0,
      showEditModal: false,
      showModal: false,
      modalTitle: "",
      modalDesc: "",
      modalType: false,
      langs: undefined,
      //onBoarding
      showStep1: false,
      showStep2: false,
      showStep3: false,
      showStep3_1: true,
      showStep3_2: false,
      showStep3_3: false,
    }
    this.accordionEvent = this.accordionEvent.bind(this)
    this.getTrackedUrl = this.getTrackedUrl.bind(this)
  }

  componentDidMount() {
    User.getLangs((res) => {
      if (!res.error) {
        this.setState({
          langs: res.locales.map((l) => l.value.toUpperCase()),
        })
      }
    })
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.profile !== undefined) {
      if (nextProps.profile !== this.props.profile) {
        if (nextProps.profile.tutorial_done === 0) {
          this.setState({ showStep1: true })
        }
      }
    }
    return true
  }

  accordionEvent() {
    let overlay = this.state.overlay
    let inc = this.state.inc

    if (overlay === 1 || overlay === null) {
      this.setState({
        accordion: 1 + inc,
        overlay: 0,
        inc: this.state.inc + 1,
      })
    } else {
      this.setState({ accordion: 0, overlay: 1 })
    }
  }

  closeEditUserModal(type, msg) {
    if (msg != undefined) {
      if (type) {
        this.setState({
          showEditModal: false,
          showModal: true,
          modalTitle: window.strings.successMsg,
          modalDesc: msg,
          modalType: true,
        })
      } else {
        this.setState({
          showEditModal: false,
          showModal: true,
          modalTitle: window.strings.errorMsg,
          modalDesc: msg,
          modalType: false,
        })
      }
    } else {
      this.setState({
        showEditModal: false,
      })
    }
  }

  logout() {
    User.logout((res) => {
      if (!res.error) {
        Tools.removeData("token")
        window.location.href = "/Signin"
      } else {
        // alert("Erro ao fazer logout!");
        console.log("Erro ao fazer logout!")
        window.location.href = "/Signin"
      }
    })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  closeStep1Modal(step) {
    this.setState({
      showStep1: false,
      showStep2: step,
    })
  }
  closeStep2Modal(step) {
    if (step == null) {
      User.completeOnboarding((res) => {
        if (!res.error) {
          this.setState({
            showStep2: false,
          })
        }
      })
    } else {
      this.setState({
        showStep2: false,
        showStep3: step,
      })
    }
  }

  closeStep3Modal(step) {
    if (step == 1) {
      this.setState({
        showStep3: false,
        showStep3_1: false,
        showStep3_2: true,
      })
    } else if (step == 2) {
      this.setState({
        showStep3: false,
        showStep3_1: false,
        showStep3_2: false,
        showStep3_3: true,
      })
    } else if (step == 3) {
      User.completeOnboarding((res) => {
        if (!res.error) {
          this.setState({
            showStep3: false,
            showStep3_3: false,
          })
        }
      })
    }

    if (step != 3) {
      this.setState({
        showStep3: true,
      })
    }
  }

  changeLang(lang) {
    let data = {
      lang,
    }
    User.setLang(data, (res) => {
      if (!res.error) {
        window.location.href = ""
      }
    })
  }

  getTrackedUrl(baseUrl) {
    const user = this.state.profile
    
    const emailParam = `email=${user.email}`
    const countryParam = `country=${user.locations[0].country}`
    const locationIdParam =
      user.locations.length === 1
        ? `&location_internal_id=${user.locations[0].ext_id}`
        : ""

    return `${baseUrl}?${emailParam}&${countryParam}${locationIdParam}`
  }

  render() {
    return (
      <div>
        <Navbar
          variant="light"
          style={{
            backgroundColor: "#ffffff",
            position: "relative",
            height: 80,
            // top: 0,
            zIndex: 999,
          }}
          expanded
          className="p-2"
        >
          <Container fluid className="px-0">
            <div
              className="d-flex justify-content-between w-100"
              style={{ flexDirection: "column" }}
            >
              <div
                className="d-flex align-items-center justify-content-end"
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  marginRight: 440,
                  gap: 30,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}></div>
                <a
                  className="text-black"
                  href={this.getTrackedUrl(window.strings.contactUsLink)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {window.strings.contactUs}
                </a>
                <a
                  className="text-black"
                  href={this.getTrackedUrl(window.strings.faqLink)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {window.strings.faq}
                </a>
              </div>

              {this.props.profile !== undefined && (
                <div
                  className="position-absolute"
                  style={{
                    marginLeft: 60,
                    right: 100,
                    top: 0,
                    zIndex: 99999,
                    maxWidth: 340,
                  }}
                >
                  <Accordion
                    defaultActiveKey={1}
                    onSelect={() => {
                      if (this.state.overlay === 0) {
                        this.setState({ overlay: 1 })
                      } else {
                        this.setState({ overlay: 0 })
                      }
                    }}
                    flush
                  >
                    <Accordion.Item eventKey={this.state.accordion}>
                      <Accordion.Button
                        className="accordion-custom-header"
                        style={{ minWidth: 205 }}
                      >
                        <div
                          style={{
                            marginRight: 12,
                            alignSelf: "center",
                            textAlign: "center",
                            minWidth: 50,
                            backgroundColor: "rgba(1, 180, 224, 0.1)",
                            padding: 10,
                          }}
                        >
                          <span
                            style={{
                              color: "#01B4E0",
                              fontSize: 17,
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            {this.props.profile.first_name[0]}
                            {this.props.profile.last_name[0]}
                          </span>
                        </div>
                        <span
                          style={{
                            marginRight: 50,
                            fontWeight: "bold",
                            fontSize: 17,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {this.props.profile.first_name}
                        </span>
                      </Accordion.Button>
                      <Accordion.Body
                        style={{
                          // height: 200,
                          width: "100%",
                          padding: 0,
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              padding: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                            }}
                          >
                            <span
                              onClick={() => {
                                this.setState({ showEditModal: true })
                              }}
                              style={{ fontWeight: "bold", cursor: "pointer" }}
                            >
                              {window.strings.editAccountData}
                            </span>
                          </div>
                          <div
                            style={{
                              padding: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                            }}
                          >
                            <span
                              onClick={() => {
                                window.location.href = "/Send-Feedback"
                              }}
                              style={{ fontWeight: "bold", cursor: "pointer" }}
                            >
                              {window.strings.sendFeedback}
                            </span>
                          </div>

                          <div
                            style={{
                              padding: 20,
                              display: "flex",
                              flexDirection: "column",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                            }}
                          >
                            <span style={{ color: "#62757A" }}>
                              {window.strings.idioma}
                            </span>
                            <Accordion defaultActiveKey={false}>
                              <Accordion.Item>
                                <Accordion.Button className="accordion-custom-header">
                                  <span style={{}}>
                                    {window.strings.langs[window.language]}
                                  </span>
                                </Accordion.Button>
                                <Accordion.Body
                                  style={{
                                    width: "100%",
                                    padding: 0,
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <div>
                                    {this.state.langs != undefined &&
                                      this.state.langs.map((lang, index) => {
                                        return (
                                          <div
                                            key={index}
                                            onClick={() => {
                                              this.changeLang(lang)
                                            }}
                                            style={{
                                              padding: 20,
                                              display: "flex",
                                              cursor: "pointer",
                                              alignItems: "center",
                                              flexDirection: "row",
                                              borderBottom:
                                                "1px solid rgba(162, 179, 184, 0.2)",
                                            }}
                                          >
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {window.strings.langs[lang]}
                                            </span>
                                          </div>
                                        )
                                      })}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div
                            style={{
                              padding: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              borderBottom:
                                "1px solid rgba(162, 179, 184, 0.2)",
                            }}
                          >
                            <span
                              onClick={this.logout}
                              style={{
                                fontWeight: "bold",
                                color: "#62757A",
                                cursor: "pointer",
                              }}
                            >
                              {window.strings.logout}
                            </span>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
              <div
                onClick={() => this.accordionEvent()}
                id={this.state.overlay === 1 ? "custom-overlay" : ""}
              ></div>
            </div>
          </Container>
        </Navbar>
        {this.state.showEditModal && (
          <EditModal
            user={this.props.profile}
            selected_locations={this.props.selected_locations}
            // active_corporate={this.props.active_corporate}
            showEditUser={this.state.showEditModal}
            closeEditUserModal={this.closeEditUserModal.bind(this)}
          />
        )}
        <AlertModal
          showModal={this.state.showModal}
          closeModal={this.closeModal.bind(this)}
          title={this.state.modalTitle}
          desc={this.state.modalDesc}
          success={this.state.modalType}
        />

        <Popup_step1
          showStep1={this.state.showStep1}
          closeStep1Modal={this.closeStep1Modal.bind(this)}
        />
        <Popup_step2
          showStep2={this.state.showStep2}
          closeStep2Modal={this.closeStep2Modal.bind(this)}
        />
        <Popup_step3
          showStep3={this.state.showStep3}
          showStep3_1={this.state.showStep3_1}
          showStep3_2={this.state.showStep3_2}
          showStep3_3={this.state.showStep3_3}
          closeStep3Modal={this.closeStep3Modal.bind(this)}
        />
      </div>
    )
  }
}
