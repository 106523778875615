import React, { Component } from "react"
import powerdotIcon from "../assets/tv/powerdot-Icon.png"
import { Image } from "@themesberg/react-bootstrap"
import chargerIcon from "../assets/tv/chargerIcon@2x.png"

export default class LocationsList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div
        className="dashboards-content"
        style={{ display: "flex", flexDirection: "column", paddingBottom: 350 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 32,
              color: "#041114",
              fontWeight: "bold",
              marginRight: 15,
            }}
          >
            {window.strings.locations}
          </span>
          <span
            style={{
              borderRadius: 16,
              backgroundColor: "#041114",
              fontSize: 15,
              textAlign: "center",
              color: "#ffffff",
              padding: "3px 10px",
            }}
          >
            {this.props.locations.length}
          </span>
        </div>
        <span
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "rgba(162, 179, 184, 0.2)",
            borderRadius: 1,
            margin: "15px 0px 32px 0px",
          }}
        />
        {this.props.locations !== undefined &&
        this.props.locations !== null &&
        this.props.locations.length > 0 ? (
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {this.props.locations.map((location, index) => {
              return (
                <div
                  onClick={() => {
                    this.props.changeNavLocation(location.id)
                  }}
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#ffffff",
                    minHeight: 280,
                    width: 300,
                    margin: 16,
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      padding: "50px 50px 24px 24px",
                    }}
                  >
                    <Image
                      src={
                        location.image === null ? powerdotIcon : location.image
                      }
                      style={{ width: 60 }}
                    />
                    <span
                      style={{
                        fontSize: 19,
                        color: "#041114",
                        fontWeight: "bold",
                        marginTop: 16,
                      }}
                    >
                      {location.name}
                    </span>
                    <span
                      style={{ fontSize: 15, color: "#62757A", marginTop: 12 }}
                    >
                      {location.address}
                    </span>
                  </div>
                  <span
                    style={{
                      height: 1,
                      width: "100%",
                      backgroundColor: "rgba(162, 179, 184, 0.2)",
                      borderRadius: 1,
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 12,
                      paddingLeft: 24,
                      alignItems: "center",
                    }}
                  >
                    <Image src={chargerIcon} style={{ width: 32 }} />
                    <span style={{ fontSize: 15, color: "#62757A" }}>
                      {location.number_chargers} {window.strings.chargers}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: 20,
                color: "#041114",
                fontWeight: "bold",
                marginTop: 100,
              }}
            >
              {window.strings.noLocations}
            </span>
            <span style={{ fontSize: 17, color: "#62757A", marginTop: 16 }}>
              {window.strings.noLocationsDesc}
            </span>
          </div>
        )}
      </div>
    )
  }
}
