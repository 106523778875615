import React, { Component } from "react"
import Preloader from "../../components/Preloader"
import { Col, Row } from "@themesberg/react-bootstrap"
import LineChart from "../../components/Charts/LineChart"
import LocationDataTable from "./components/LocationDataTable"
import Tools from "../../utils/Tools"
import AlertModal from "../../components/AlertModal"
export default class Charges extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: this.props.profile,
      compare: false,
      dashboards_data: this.props.dashboards_data,
      charges: [],
      popularCharger: {},
      from: this.props.from,
      to: this.props.to,
      showModal: false,
      modalTitle: "",
      modalDesc: "",
      modalType: false,
    }
  }

  onToggle() {
    if (!this.state.compare) {
      this.setState({ loading: true }, () => {
        this.props.updateLastPeriodResults((error) => {
          if (!error) {
            this.setState({
              compare: !this.state.compare,
              loading: false,
            })
          } else {
            this.setState({
              compare: false,
              loading: false,
              showModal: true,
              modalTitle: window.strings.errorInf,
              modalDesc: window.strings.lastYearNoData,
              modalType: false,
            })
          }
        })
      })
    } else {
      this.setState({ compare: !this.state.compare })
    }
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <>
        <div
          className="dashboards-content"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: 80,
          }}
        >
          {this.props.dashboards_data !== undefined &&
            this.props.dashboards_data !== {} &&
            this.props.dashboards_data.res !== undefined && (
              <>
                <Row>
                  <LineChart
                    totalTitle={window.strings.totalCharges}
                    total={
                      Math.round(
                        this.props.dashboards_data.res.data
                          .total_numero_de_carregamentos * 10
                      ) / 10
                    }
                    unit={window.strings.charges}
                    lastMonth={
                      this.props.dashboards_data.charges_stats.lastMonth
                    }
                    evo={
                      Math.round(
                        this.props.dashboards_data.charges_stats
                          .lastMonth_perc * 10
                      ) / 10
                    }
                    frequency={this.props.dashboards_data.frequency}
                    exportCharges={this.props.exportCharges}
                    currentMonth={true}
                    compare={this.state.compare}
                    onToggle={this.onToggle}
                    labels={this.props.dashboards_data.labels}
                    datasets={[
                      {
                        type: "line",
                        label: window.strings.charges,
                        borderColor: "#00637E",
                        borderWidth: 4,
                        backgroundColor: [
                          "#f38b4a",
                          "#56d798",
                          "#ff8397",
                          "#6970d5",
                        ],
                        fill: false,
                        data: this.props.dashboards_data.charges,
                        yAxisID: "left-y-axis",
                        pointRadius: 7,
                        pointBorderWidth: 2,
                        pointHoverRadius: 14,
                        pointHoverBorderWidth: 4,
                        pointBackgroundColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#00B8EB", last: "#fff" }
                        ),
                        pointHoverBorderColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#fff", last: "#00637E" }
                        ),
                        pointBorderColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#fff", last: "#00B8EB" }
                        ),
                        pointHoverBackgroundColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#00637E", last: "#fff" }
                        ),
                        freq: this.props.dashboards_data.frequency,
                        evo: this.props.dashboards_data.charges_var,
                      },
                      {
                        type: "line",
                        label: window.strings.chargersLastYear,
                        borderColor: "rgba(98, 117, 122, 0.6)",
                        borderWidth: 2,
                        borderDash: [7, 5],
                        pointRadius: 0,
                        fill: false,
                        data: this.state.compare
                          ? this.props.dashboards_data.last_freq_charges
                          : [],
                        yAxisID: "left-y-axis",
                        freq: this.props.dashboards_data.frequency,
                        evo: this.props.dashboards_data.charges_var,
                      },
                    ]}
                  />
                </Row>
                {this.props.navLocation && (
                  <Row style={{ marginTop: 20 }}>
                    <Col>
                      <LocationDataTable
                        metricTotalTitle={window.strings.totalCharges + ":"}
                        unit={""}
                        metricTotal={
                          Math.round(
                            this.props.dashboards_data.res.data
                              .total_numero_de_carregamentos * 10
                          ) / 10
                        }
                        subtitle={""}
                        lastMonth={
                          this.props.dashboards_data.charges_stats.lastMonth
                        }
                        evo={
                          Math.round(
                            this.props.dashboards_data.charges_stats
                              .lastMonth_perc * 10
                          ) / 10
                        }
                        frequency={this.props.dashboards_data.frequency}
                      />
                    </Col>
                    <Col>
                      <LocationDataTable
                        metricTotalTitle={
                          (this.props.dashboards_data.frequency == "horaria"
                            ? window.strings.hour
                            : this.props.dashboards_data.frequency == "dia"
                            ? window.strings.day
                            : this.props.dashboards_data.frequency == "semanal"
                            ? window.strings.week
                            : this.props.dashboards_data.frequency == "mês"
                            ? window.strings.month
                            : window.strings.year) +
                          " " +
                          window.strings.withMoreCharges +
                          ":"
                        }
                        unit={""}
                        metricTotal={
                          this.props.dashboards_data.frequency == "horaria"
                            ? this.props.dashboards_data.best_charges_date.date
                                .hora + "h "
                            : this.props.dashboards_data.frequency == "dia"
                            ? this.props.dashboards_data.best_charges_date.date
                                .dia +
                              " " +
                              Tools.getMonthString(
                                this.props.dashboards_data.best_charges_date
                                  .date.mes
                              )
                            : this.props.dashboards_data.frequency == "semanal"
                            ? this.props.dashboards_data.best_charges_date.date
                                .week
                            : this.props.dashboards_data.frequency == "mês"
                            ? Tools.getMonthString(
                                this.props.dashboards_data.best_charges_date
                                  .date.mes
                              )
                            : this.props.dashboards_data.best_charges_date.date
                                .ano
                        }
                        subtitle={
                          this.props.dashboards_data.frequency == "ano"
                            ? ""
                            : this.props.dashboards_data.best_charges_date.date
                                .ano
                        }
                        lastMonth={
                          this.props.dashboards_data.charges_stats.lastMonth
                        }
                        // evo={this.props.dashboards_data.charges_stats.lastMonth_perc}
                        // frequency={this.props.dashboards_data.frequency}
                        evo={undefined}
                        frequency={undefined}
                      />
                    </Col>
                    <Col>
                      {this.props.bestCharger_data !== undefined &&
                        this.props.bestCharger_data !== {} &&
                        this.props.bestCharger_data.data !== undefined &&
                        this.props.bestCharger_data.data !== {} && (
                          <LocationDataTable
                            metricTotalTitle={
                              window.strings.mostUsedCharger + ":"
                            }
                            unit={""}
                            metricTotal={
                              this.props.bestCharger_data.data.box_id
                            }
                            subtitle={
                              this.props.bestCharger_data.data
                                .number_of_charges +
                              " " +
                              window.strings.charges
                            }
                            lastMonth={
                              this.props.bestCharger_data.data.freq_evoltion
                            }
                            evo={
                              this.props.bestCharger_data.data
                                .perc_freq_evoltion
                            }
                            frequency={this.props.dashboards_data.frequency}
                          />
                        )}
                    </Col>
                  </Row>
                )}
              </>
            )}
        </div>
        {this.state.loading && <Preloader show={true} />}
        <AlertModal
          showModal={this.state.showModal}
          closeModal={this.closeModal.bind(this)}
          title={this.state.modalTitle}
          desc={this.state.modalDesc}
          success={this.state.modalType}
        />
      </>
    )
  }
}
