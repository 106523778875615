import React, { useState } from "react"
import SimpleBar from "simplebar-react"
import { CSSTransition } from "react-transition-group"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartPie, faTimes, faTable } from "@fortawesome/free-solid-svg-icons"
import {
  Nav,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap"
import { Link } from "react-router-dom"
import User from "../../Service/User"
import Tools from "../../utils/Tools"
import { Routes } from "../../routes"
import logoNegative from "../../assets/img/brand/logo-negative.png"
import NavItem from "./NavItem"
import CollapsableNavItem from "./CollapsableNavItem"

export default (props = {}) => {
  const { profile, locations, navLocation, changeNavLocation, totalChargers } =
    props

  const [show, setShow] = useState(false)
  const showClass = show ? "show" : ""
  const onCollapse = () => setShow(!show)

  return (
    <>
      <Navbar
        expand={false}
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Overview.path}>
          <Image src={logoNegative} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-white text-primary`}
        >
          <div className="sidebar-inner">
            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
              <FontAwesomeIcon icon={faTimes} />
            </Nav.Link>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                link={Routes.Overview.path}
                header={true}
                setShow={setShow}
              />
              <NavItem
                title={window.strings.hello}
                subtitle={profile !== undefined ? profile.first_name : ""}
                link={Routes.Overview.path}
                icon={faChartPie}
                setShow={setShow}
              />

              <CollapsableNavItem
                eventKey=""
                title={window.strings.overview}
                icon={faTable}
              >
                <NavItem
                  withActiveClass={true}
                  title={window.strings.charges2}
                  subTab={true}
                  link={Routes.Charges.path}
                  setShow={setShow}
                />
                <NavItem
                  withActiveClass={true}
                  title={"kWh" + " " + window.strings.consumed}
                  subTab={true}
                  link={Routes.Consumed.path}
                  setShow={setShow}
                />
                <NavItem
                  withActiveClass={true}
                  title={window.strings.minutesCharged}
                  subTab={true}
                  link={Routes.Minutes.path}
                  setShow={setShow}
                />
                <NavItem
                  withActiveClass={true}
                  title={"CO2 " + window.strings.spared}
                  subTab={true}
                  link={Routes.Spared.path}
                  setShow={setShow}
                />
              </CollapsableNavItem>
              <CollapsableNavItem
                eventKey=""
                title={window.strings.locations}
                withNumber={locations?.length ?? 0}
                icon={faTable}
              >
                <div className="locations-scroll">
                  {locations.map((location, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          let data = location == navLocation ? null : location
                          changeNavLocation(data)
                        }}
                      >
                        <NavItem
                          key={index}
                          withActiveClass={location == navLocation}
                          subTab={true}
                          title={location.name}
                          link={Routes.Overview.path}
                          setShow={setShow}
                        />
                      </div>
                    )
                  })}
                </div>

                <div
                  onClick={() => {
                    window.location.href = "/locations"
                  }}
                  style={{
                    border: "1px solid #A2B3B8",
                    margin: 10,
                    padding: 10,
                    cursor: "pointer",
                    borderRadius: 16,
                    fontSize: 15,
                    fontWeight: "bold",
                    color: "#041114",
                    textAlign: "center",
                  }}
                >
                  {window.strings.seeAll}
                </div>
              </CollapsableNavItem>

              <NavItem
                title={window.strings.chargers}
                withNumber={totalChargers}
                link={Routes.Chargers.path}
                icon={faChartPie}
                setShow={setShow}
              />
              <NavItem
                title={window.strings.users}
                link={Routes.Users.path}
                icon={faChartPie}
                setShow={setShow}
              />
              <Dropdown.Divider className="my-3 mt-4" />

              <div
                onClick={() => {
                  User.logout((res) => {
                    if (!res.error) {
                      Tools.removeData("token")
                      window.location.href = "/Signin"
                    } else {
                      console.log("Erro ao fazer logout!")
                      window.location.href = "/Signin"
                    }
                  })
                }}
              >
                <NavItem
                  external
                  title={window.strings.logout}
                  setShow={setShow}
                />
              </div>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  )
}
