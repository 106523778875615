import React, { Component } from "react"
import { ReactSearchAutocomplete } from "react-search-autocomplete"
export default class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chargers: this.props.chargers,
    }
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleOnClear = this.handleOnClear.bind(this)
    this.formatResult = this.formatResult.bind(this)
  }

  handleOnSelect(item) {
    console.log("item: ", item)
    if (this.props.chargers !== undefined) {
      this.props.selectCharger(item)
    } else {
      this.props.selectUser(item)
    }
  }

  handleOnClear() {
    if (this.props.chargers !== undefined) {
      this.props.selectCharger(null)
    } else {
      this.props.selectUser(null)
    }
  }

  formatResult(item) {
    return (
      <div className="result-wrapper">
        <span className="result-span">
          {this.props.chargers !== undefined
            ? `${item.box_id} | ${item.location}`
            : `${item.first_name} | ${item.email}`}
        </span>
      </div>
    )
  }

  render() {
    return (
      <ReactSearchAutocomplete
        items={
          this.props.chargers !== undefined
            ? this.props.chargers
            : this.props.users
        }
        value
        fuseOptions={{
          keys:
            this.props.chargers != undefined
              ? ["box_id", "location"]
              : ["first_name", "email"],
        }} // Search on both fields
        resultStringKeyName={
          this.props.chargers != undefined ? "box_id" : "email"
        } // String to display in the results
        onSelect={this.handleOnSelect}
        onClear={this.handleOnClear}
        maxResults={3}
        placeholder={
          this.props.chargers !== undefined
            ? window.strings.searchForCharger
            : window.strings.searchForUser
        }
        autoFocus
        formatResult={this.formatResult}
        styling={{
          border: "0px",
          borderRadius: "0px",
          fontSize: "16px",
        }}
      />
    )
  }
}
