import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkerAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons"
import CalendarModal from "./../../../components/CalendarModal"
import Preloader from "./../../../components/Preloader"
import chargerImg from "./../../../assets/tv/group-26@3x.png"

export default class PopularCharger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popularCharger: this.props.popularCharger,
      frequency: "diario",
      selectedDateString: null,
      selectedDatesObj: this.props.selectedDatesObj,
      selectedDates: this.props.selectedDates,
      showCalendarModal: false,
    }
    this.closeCalendarModal = this.closeCalendarModal.bind(this)
  }

  selectDates(date, freq, callback) {
    let selectedDateString = this.getPeriodString(date, freq)
    this.setState(
      {
        selectedDates: date,
        selectedDateString,
        frequency: freq,
      },
      () =>
        callback(
          this.props.updateDate(
            this.state.selectedDates.from,
            this.state.selectedDates.to,
            this.state.selectedDates.fromHour,
            this.state.selectedDates.toHour,
            () => {
              this.props.filterPopular()
            }
          )
        )
    )
  }

  closeCalendarModal() {
    this.setState({ showCalendarModal: false })
  }

  getPeriodString(dates) {
    return (
      window.strings.from +
      " " +
      dates.from.split(" ")[0] +
      " " +
      window.strings.from +
      " " +
      dates.to.split(" ")[0]
    )
  }

  render() {
    if (this.props.popularCharger.error) {
      return null
    }

    if (this.props.popularCharger.loading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#ffffff",
            padding: "16px 40px 0px 40px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 3,
          }}
        >
          <Preloader show={true} />
        </div>
      )
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#ffffff",
            padding: "16px 40px 0px 40px",
            alignItems: "flex-end",
            borderRadius: 3,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 40,
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 184, 235, 0.1)",
                padding: "40px 40px 0px 40px",
                borderRadius: "13px 13px 0px 0px",
              }}
            >
              <img
                src={chargerImg}
                style={{ position: "relative", bottom: -30 }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 3,
              marginBottom: 40,
            }}
          >
            <span style={{ color: "#041114", fontSize: 20, marginBottom: 4 }}>
              {this.props.popularCharger.data?.name ?? ""}
            </span>
            <span style={{ color: "#62757A", fontSize: 17, marginBottom: 24 }}>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ color: "#62757A", marginRight: 4 }}
              />
              {this.props.popularCharger.data?.location ?? ""}
            </span>
            <span style={{ color: "#041114", fontSize: 17 }}>
              {this.props.popularCharger.data?.charges ?? ""}{" "}
              {window.strings.charges}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 2,
              marginBottom: 32,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: 40,
              }}
            >
              <div
                onClick={() => {
                  this.setState({ showCalendarModal: true })
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: 16,
                  borderWidth: 1,
                  borderColor: "#A2B3B8",
                  borderStyle: "solid",
                  padding: "10px 20px",
                  minWidth: 250,
                  maxWidth: 450,
                }}
              >
                <span
                  style={{
                    flex: 1,
                    marginRight: 20,
                    fontWeight: "bold",
                    color: "#62757A",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: 15,
                  }}
                >
                  {this.state.selectedDateString == null
                    ? window.strings.last30days
                    : this.state.selectedDateString}
                </span>
                <FontAwesomeIcon color={"#A2B3B8"} icon={faCalendarAlt} />
              </div>
            </div>
            {this.props.popularCharger.data ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    this.props.popularCharger.data.lastMonth > 0
                      ? "rgba(7,184,101,0.08)"
                      : "rgba(208, 52, 44, 0.08)",
                  border:
                    this.props.popularCharger.data.lastMonth > 0
                      ? "1px solid rgba(7,184,101,0.08)"
                      : "1px solid rgba(208, 52, 44, 0.08)",
                  borderRadius: 2,
                  padding: "6px 12px",
                  marginBottom: 4,
                }}
              >
                <span
                  style={{
                    flex: 1,
                    fontSize: 17,
                    color:
                      this.props.popularCharger.data.lastMonth > 0
                        ? "#07B865"
                        : "#D0342C",
                    fontWeight: "bold",
                  }}
                >
                  {this.props.popularCharger.data.evo > 0
                    ? "+ " + this.props.popularCharger.data.lastMonth
                    : this.props.popularCharger.data.evo < 0
                    ? this.props.popularCharger.data.lastMonth
                        .toString()
                        .slice(0, 1) +
                      " " +
                      this.props.popularCharger.data.lastMonth
                        .toString()
                        .slice(1)
                    : this.props.popularCharger.data.lastMonth}
                </span>
                <span style={{ fontSize: 14, color: "#537A68" }}>
                  {window.strings.compareToLastPeriod}
                </span>
              </div>
            ) : null}
            {this.props.popularCharger.data ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    this.props.popularCharger.data.evo > 0
                      ? "rgba(7,184,101,0.08)"
                      : "rgba(208, 52, 44, 0.08)",
                  border:
                    this.props.popularCharger.data.evo > 0
                      ? "1px solid rgba(7,184,101,0.08)"
                      : "1px solid rgba(208, 52, 44, 0.08)",
                  borderRadius: 2,
                  padding: "6px 12px",
                  marginBottom: 4,
                }}
              >
                <span
                  style={{
                    flex: 1,
                    fontSize: 17,
                    color:
                      this.props.popularCharger.data.evo > 0
                        ? "#07B865"
                        : "#D0342C",
                    fontWeight: "bold",
                  }}
                >
                  {this.props.popularCharger.data.evo > 0
                    ? "+ " + this.props.popularCharger.data.evo + "%"
                    : this.props.popularCharger.data.evo < 0
                    ? this.props.popularCharger.data.evo
                        .toString()
                        .slice(0, 1) +
                      " " +
                      this.props.popularCharger.data.evo.toString().slice(1) +
                      "%"
                    : this.props.popularCharger.data.evo + "%"}
                </span>
                <span style={{ fontSize: 14, color: "#537A68" }}>
                  {window.strings.evolution}
                </span>
              </div>
            ) : null}
          </div>
        </div>

        <CalendarModal
          frequency={this.state.frequency}
          selectedDates={this.state.selectedDatesObj}
          selectDates={this.selectDates.bind(this)}
          showModal={this.state.showCalendarModal}
          closeModal={this.closeCalendarModal.bind(this)}
        />
      </>
    )
  }
}
