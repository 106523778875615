import React, { Component } from "react";
import {  Image } from '@themesberg/react-bootstrap';
import powerdotIcon from "../assets/tv/powerdot-Icon.png";
export default class LocationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
        <div style={{ flexDirection:"row",display:"flex",justifyContent:"flex-start",alignItems:"center",paddingLeft:115,paddingRight:100,paddingTop:50 }}>
       <Image src={this.props.navLocation.image == null ? powerdotIcon : this.props.navLocation.image} style={{width:60,marginRight:30}} />
        <div style={{ flexDirection:"column",display:"flex" }}>
          <span style={{ fontSize: 24, color: "#041114", fontWeight: "bold" }}>
            {this.props.navLocation.name}
          </span>
          <span style={{ fontSize: 15, color: "#62757A", marginTop: 4 }}>
            {this.props.navLocation.address}
          </span>
        </div>
      </div>
    );
  }
}
