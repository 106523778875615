import React,{Component} from 'react';
import {
    Modal,
    Image
  } from "@themesberg/react-bootstrap";
import arrow from "../../assets/tv/icons-arrow-grey.png"
export default class Popup_step3 extends Component {
constructor(props) {
    super(props);
    this.state = {
        showStep3:this.props.showStep3,
    };
    this.oncloseModal = this.oncloseModal.bind(this);
  }

  oncloseModal(step) {
    this.props.closeStep3Modal(step,()=>{
      this.setState({ showStep3: false });
    })
  } 



  renderPopupStep3_1(){
    return(
      <Modal
            as={Modal.Dialog}
            show={this.props.showStep3}
            id={"step3-modal"}
            onHide={this.oncloseModal.bind(this,1)}
            >
                <Modal.Header style={{alignItems:"flex-start",backgroundColor:"#CCF1FB",borderBottomColor:"rgba(0, 104, 133, 0.2)",padding:"30px 30px 25px 30px"}} >
                <div style={{flex:1,display:"flex",flexDirection:"row",marginTop:20}}>
                <span style={{width:4,backgroundColor:"#00B8EB",borderRadius:2}}></span>
                <span style={{marginLeft:9,fontSize:19,fontWeight:"bold", color: "#041114"}}>
                {window.strings.overview}
                </span>
                </div>
                <div style={{color:"#ffffff",fontSize:17,fontWeight:"bold",padding:"6px 8px", borderRadius: 8, backgroundColor:"#00B8EB" }}>1 / 3</div>
              </Modal.Header>
            <Modal.Body style={{padding:"25px 30px 0px 30px", backgroundColor:"#ffffff"}}>
            <span style={{fontSize:15, color: "#62757A"}}>
          {window.strings.step3_1_desc}
            </span>
            </Modal.Body>
            <Modal.Footer style={{padding:0, margin:0, backgroundColor:"#ffffff",borderTopColor:"#ffffff" }}>
            <div
                style={{flex:1,padding:"25px 25px 25px 25px",cursor:"pointer",display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}
                onClick={this.oncloseModal.bind(this,1)}
              >
             <span style={{fontSize:17,fontWeight:"bold", color: "#62757A",marginRight:4}}>
             {window.strings.continue}
            </span>
            <Image src={arrow}/>
                  </div>
             </Modal.Footer>
          </Modal>
    )
  }

  renderPopupStep3_2(){
    return(
      <Modal
      as={Modal.Dialog}
      show={this.props.showStep3}
      id={"step3-modal2"}
      onHide={this.oncloseModal.bind(this,2)}
      >
          <Modal.Header style={{alignItems:"flex-start",backgroundColor:"#CCF1FB",borderBottomColor:"rgba(0, 104, 133, 0.2)",padding:"30px 30px 25px 30px"}} >
          <div style={{flex:1,display:"flex",flexDirection:"row",marginTop:20}}>
          <span style={{width:4,backgroundColor:"#00B8EB",borderRadius:2}}></span>
          <span style={{marginLeft:9,fontSize:19,fontWeight:"bold", color: "#041114"}}>
          {window.strings.locations}
          </span>
          </div>
          <div style={{color:"#ffffff",fontSize:17,fontWeight:"bold",padding:"6px 8px", borderRadius: 8, backgroundColor:"#00B8EB" }}>2 / 3</div>
        </Modal.Header>
      <Modal.Body style={{padding:"25px 30px 0px 30px", backgroundColor:"#ffffff"}}>
      <span style={{fontSize:15, color: "#62757A"}}>
      {window.strings.step3_2_desc}
      </span>
      </Modal.Body>
      <Modal.Footer style={{padding:0, margin:0, backgroundColor:"#ffffff",borderTopColor:"#ffffff" }}>
      <div
          style={{flex:1,padding:"25px 25px 25px 25px",cursor:"pointer",display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}
          onClick={this.oncloseModal.bind(this,2)}
        >
       <span style={{fontSize:17,fontWeight:"bold", color: "#62757A",marginRight:4}}>
       {window.strings.continue}
      </span>
      <Image src={arrow}/>
            </div>
       </Modal.Footer>
    </Modal>
    )
  }


  
  renderPopupStep3_3(){
    return(
      <Modal
      as={Modal.Dialog}
      show={this.props.showStep3}
      id={"step3-modal3"}
      onHide={this.oncloseModal.bind(this,3)}
      >
          <Modal.Header style={{alignItems:"flex-start",backgroundColor:"#CCF1FB",borderBottomColor:"rgba(0, 104, 133, 0.2)",padding:"30px 30px 25px 30px"}} >
          <div style={{flex:1,display:"flex",flexDirection:"row",marginTop:20}}>
          <span style={{width:4,backgroundColor:"#00B8EB",borderRadius:2}}></span>
          <span style={{marginLeft:9,fontSize:19,fontWeight:"bold", color: "#041114"}}>
          {window.strings.chargers}
          </span>
          </div>
          <div style={{color:"#ffffff",fontSize:17,fontWeight:"bold",padding:"6px 8px", borderRadius: 8, backgroundColor:"#00B8EB" }}>3 / 3</div>
        </Modal.Header>
      <Modal.Body style={{padding:"25px 30px 0px 30px", backgroundColor:"#ffffff"}}>
      <span style={{fontSize:15, color: "#62757A"}}>
      {window.strings.step3_3_desc}      
      </span>
      </Modal.Body>
      <Modal.Footer style={{padding:0, margin:0, backgroundColor:"#ffffff",borderTopColor:"#ffffff" }}>
      <div
          style={{flex:1,padding:"25px 25px 25px 25px",cursor:"pointer",display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}
          onClick={this.oncloseModal.bind(this,3)}
        >
       <span style={{fontSize:17,fontWeight:"bold", color: "#62757A",marginRight:4}}>
      {window.strings.continue}
      </span>
      <Image src={arrow}/>
            </div>
       </Modal.Footer>
    </Modal>
    )
  }

    
    render() {
        return (
           <div>
          {this.props.showStep3_1 ?
          this.renderPopupStep3_1()
          : this.props.showStep3_2 ?
          this.renderPopupStep3_2()
          :
          this.renderPopupStep3_3()
          }

           </div>

        );
    }
}