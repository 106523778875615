import React from "react"
import { Switch } from "react-router-dom"
import { Routes } from "../../routes"

// pages
import Signin from "../auth/Signin"
import SendFeedback from "../feedback/SendFeedback"
import LostPassword from "../auth/Password"
import ChangePassword from "../auth/ChangePassword"

import NotFound from "../examples/NotFound"
import Overview from "../dashboard/Overview"
import Consumed from "../dashboard/Consumed"
import Spared from "../dashboard/Spared"
import Minutes from "../dashboard/Minutes"
import Charges from "../dashboard/Charges"
import Users from "../Users/Users"
import Chargers from "../Chargers/Chargers"
import LocationsList from "../LocationsList"

import stringfile from "../../utils/stringfile"

import RouteWithSidebarWithFilters from "./RouteWithSidebarWithFilters"
import RouteWithLoader from "./RouteWithLoader"
import RouteWithSidebar from "./RouteWithSidebar"

window.strings = stringfile.getStrings()

export default () => (
  <Switch>
    <RouteWithSidebarWithFilters
      exact
      path={Routes.Overview.path}
      component={Overview}
    />
    <RouteWithSidebarWithFilters
      exact
      path={Routes.Charges.path}
      component={Charges}
    />
    <RouteWithSidebarWithFilters
      exact
      path={Routes.Consumed.path}
      component={Consumed}
    />
    <RouteWithSidebarWithFilters
      exact
      path={Routes.Minutes.path}
      component={Minutes}
    />
    <RouteWithSidebarWithFilters
      exact
      path={Routes.Spared.path}
      component={Spared}
    />
    <RouteWithSidebar exact path={Routes.Users.path} component={Users} />
    <RouteWithSidebar exact path={Routes.Chargers.path} component={Chargers} />
    <RouteWithSidebar
      exact
      path={Routes.Locations.path}
      component={LocationsList}
    />

    <RouteWithLoader
      exact
      path={Routes.SendFeedback.path}
      screenWithUserLang={true}
      component={SendFeedback}
    />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader
      exact
      path={Routes.LostPassword.path}
      component={LostPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ChangePassword.path}
      component={ChangePassword}
    />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFound} />
  </Switch>
)
