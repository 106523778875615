import React from 'react';
import { Nav, Accordion } from "@themesberg/react-bootstrap";
import { useLocation } from "react-router-dom";


export default function CollapsableNavItem(props) {
  const { eventKey, withNumber, title, children = null } = props;

  const { pathname } = useLocation();
  
  const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

  return (
    <Accordion
      style={{ backgroundColor: "#ffffff", marginBottom: 10 }}
      defaultActiveKey={defaultKey}
      flush
    >
      <Accordion.Item
        style={{ color: "#000000", backgroundColor: "#ffffff" }}
        eventKey={eventKey}
      >
        <Accordion.Button
          as={Nav.Link}
          style={{
            color: "#000000",
            borderWidth: 0,
            borderBottomWidth: 1,
            borderRadius: 0,
            fontWeight: "bold",
            backgroundColor: "#ffffff",
          }}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-text text-black">{title}</span>
            {withNumber && (
              <span
                style={{
                  borderRadius: 16,
                  backgroundColor: "#041114",
                  fontSize: 14,
                  textAlign: "center",
                  color: "#ffffff",
                  padding: "3px 9px",
                  marginLeft: 5,
                }}
              >
                {withNumber}
              </span>
            )}
          </span>
        </Accordion.Button>

        <Accordion.Body className="multi-level" style={{ marginLeft: 20 }}>
          <Nav className="flex-column">{children}</Nav>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}