import React, { Component } from "react"
import Preloader from "../../components/Preloader"
import { Col, Row } from "@themesberg/react-bootstrap"
import LineChart from "../../components/Charts/LineChart"
import LocationDataTable from "./components/LocationDataTable"
import Tools from "../../utils/Tools"
export default class Minutes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: this.props.profile,
      compare: false,
      dashboards_data: this.props.dashboards_data,
    }
  }

  onToggle() {
    if (!this.state.compare) {
      this.setState({ loading: true }, () => {
        this.props.updateLastPeriodResults(() => {
          this.setState({
            compare: !this.state.compare,
            loading: false,
          })
        })
      })
    } else {
      this.setState({ compare: !this.state.compare })
    }
  }

  render() {
    return (
      <>
        <div
          className="dashboards-content"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: 80,
          }}
        >
          {this.props.dashboards_data !== undefined &&
            this.props.dashboards_data !== {} &&
            this.props.dashboards_data.res !== undefined && (
              <>
                <Row>
                  <LineChart
                    totalTitle={window.strings.minutesCharged + ":"}
                    total={
                      Math.round(
                        this.props.dashboards_data.res.data
                          .total_duracao_carregamento * 10
                      ) / 10
                    }
                    unit={"min"}
                    lastMonth={this.props.dashboards_data.time_stats.lastMonth}
                    evo={
                      Math.round(
                        this.props.dashboards_data.time_stats.lastMonth_perc *
                          10
                      ) / 10
                    }
                    frequency={this.props.dashboards_data.frequency}
                    currentMonth={true}
                    exportCharges={this.props.exportCharges}
                    compare={this.state.compare}
                    onToggle={this.onToggle}
                    labels={this.props.dashboards_data.labels}
                    datasets={[
                      {
                        type: "line",
                        label: window.strings.minutes,
                        borderColor: "#00637E",
                        borderWidth: 4,
                        backgroundColor: [
                          "#f38b4a",
                          "#56d798",
                          "#ff8397",
                          "#6970d5",
                          "#f38b4a",
                          "#56d798",
                          "#ff8397",
                          "#6970d5",
                          "#f38b4a",
                          "#56d798",
                          "#ff8397",
                          "#6970d5",
                        ],
                        fill: false,
                        data: this.props.dashboards_data.time,
                        yAxisID: "left-y-axis",
                        pointRadius: 7,
                        pointBorderWidth: 2,
                        pointHoverRadius: 14,
                        pointHoverBorderWidth: 4,
                        pointBackgroundColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#00B8EB", last: "#fff" }
                        ),
                        pointHoverBorderColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#fff", last: "#00637E" }
                        ),
                        pointBorderColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#fff", last: "#00B8EB" }
                        ),
                        pointHoverBackgroundColor: this.props.pointsList(
                          this.props.dashboards_data.charges,
                          { default: "#00637E", last: "#fff" }
                        ),
                        freq: this.props.dashboards_data.frequency,
                        evo: this.props.dashboards_data.time_var,
                      },
                      {
                        type: "line",
                        label: window.strings.chargersLastYear,
                        borderColor: "rgba(98, 117, 122, 0.6)",
                        borderWidth: 2,
                        borderDash: [7, 5],
                        pointRadius: 0,
                        fill: false,
                        data: this.state.compare
                          ? this.props.dashboards_data.last_freq_time
                          : [],
                        yAxisID: "left-y-axis",
                        freq: this.props.dashboards_data.frequency,
                        evo: this.props.dashboards_data.time_var,
                      },
                    ]}
                  />
                </Row>
                {this.props.navLocation && (
                  <Row style={{ marginTop: 20 }}>
                    <Col>
                      <LocationDataTable
                        metricTotalTitle={
                          window.strings.totalMinutesCharged + ":"
                        }
                        unit={"min"}
                        subtitle={""}
                        metricTotal={
                          Math.round(
                            this.props.dashboards_data.res.data
                              .total_duracao_carregamento * 10
                          ) / 10
                        }
                        lastMonth={
                          this.props.dashboards_data.time_stats.lastMonth
                        }
                        evo={
                          Math.round(
                            this.props.dashboards_data.time_stats
                              .lastMonth_perc * 10
                          ) / 10
                        }
                        frequency={this.props.dashboards_data.frequency}
                      />
                    </Col>
                    <Col>
                      <LocationDataTable
                        metricTotalTitle={
                          (this.props.dashboards_data.frequency == "horaria"
                            ? window.strings.hour
                            : this.props.dashboards_data.frequency == "dia"
                            ? window.strings.day
                            : this.props.dashboards_data.frequency == "semanal"
                            ? window.strings.week
                            : this.props.dashboards_data.frequency == "mês"
                            ? window.strings.month
                            : window.strings.year) +
                          " " +
                          window.strings.withMoreMinutes +
                          ":"
                        }
                        unit={""}
                        metricTotal={
                          this.props.dashboards_data.frequency == "horaria"
                            ? this.props.dashboards_data.best_time_date.date
                                .hora + "h "
                            : this.props.dashboards_data.frequency == "dia"
                            ? this.props.dashboards_data.best_time_date.date
                                .dia +
                              " " +
                              Tools.getMonthString(
                                this.props.dashboards_data.best_time_date.date
                                  .mes
                              )
                            : this.props.dashboards_data.frequency == "semanal"
                            ? this.props.dashboards_data.best_time_date.date
                                .week
                            : this.props.dashboards_data.frequency == "mês"
                            ? Tools.getMonthString(
                                this.props.dashboards_data.best_time_date.date
                                  .mes
                              )
                            : this.props.dashboards_data.best_time_date.date.ano
                        }
                        subtitle={
                          this.props.dashboards_data.frequency == "ano"
                            ? ""
                            : this.props.dashboards_data.best_time_date.date.ano
                        }
                        lastMonth={
                          this.props.dashboards_data.time_stats.lastMonth
                        }
                        evo={undefined}
                        frequency={undefined}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
        </div>
        {this.state.loading && <Preloader show={true} />}
      </>
    )
  }
}
