import React, { Component } from "react"
import ChargersList from "./components/ChargersList"
import ChargeList from "./../dashboard/components/ChargeList"
import SearchBar from "../components/SearchBar"
import PopularCharger from "./components/PopularCharger"
import Chargers_Service from "../../Service/Chargers"
import Charges_Service from "../../Service/Charges"
export default class Chargers extends Component {
  constructor(props) {
    super(props)
    let today_temp = new Date()
    let today = new Date(
      today_temp.getFullYear(),
      today_temp.getMonth(),
      today_temp.getDate(),
      0,
      0,
      0
    )
    let datelastYear = new Date(
      today_temp.getFullYear(),
      today_temp.getMonth() - 1,
      today_temp.getDate(),
      0,
      0,
      0
    )
    let datelastYearStr = datelastYear
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "")
    let todayStr = today.toISOString().replace(/T/, " ").replace(/\..+/, "")

    this.state = {
      profile: this.props.profile,
      chargers: [],
      popularCharger: { data: null, loading: true, error: undefined },
      selectedCharger: null,
      selectedDatesObj: {
        from: {
          year: datelastYear.getFullYear(),
          month: datelastYear.getMonth() + 1,
          day: datelastYear.getDate(),
        },

        to: {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate(),
        },
      },
      selectedDates: {
        from: datelastYearStr,
        fromHour: 0,
        to: todayStr,
        toHour: 0,
      },
      charges: [],
      from: "",
      to: "",
      loading: true,
      mount: false,
    }

    this.chargesRef = React.createRef()
    this.getChargesByCharger.bind(this)
  }

  componentDidMount() {
    if (this.props.profile !== null) {
      this.setState(
        {
          locations: this.props.locations,
        },
        () => {
          this.mount(this.props.locations)
        }
      )
    }
  }

  mount() {
    console.log("mount")
    this.selectCharger(null)

    this.bestCharger()
  }

  updateChargersResultForPage(page, callback) {
    const data_chargers = {
      page: page,
      items_per_page: 10,
      locations_id: JSON.stringify(
        this.state.locations.map((value) => value.id)
      ),
    }
    Chargers_Service.getChargersByLocation(data_chargers, (res) => {
      if (!res.error) {
        this.setState({
          chargers: res.data,
        })
      } else {
        console.log(window.strings.errorInf)
      }
      callback && callback()
    })
  }

  updateDate(from, to, fromHour, toHour, callback) {
    let selectedDates = this.state.selectedDates
    selectedDates.from = from
    selectedDates.to = to
    selectedDates.fromHour = fromHour
    selectedDates.toHour = toHour
    this.setState({ selectedDates }, () => {
      callback && callback()
    })
  }

  bestCharger() {
    let data = {
      from: this.state.selectedDates.from,
      to: this.state.selectedDates.to,
      locations_id: JSON.stringify(
        this.props.locations.map((value) => value.id)
      ),
      fromHour: this.state.selectedDates.fromHour,
      toHour: this.state.selectedDates.toHour,
    }

    Chargers_Service.getBestCharger(data, (res) => {
      if (!res.exception) {
        this.setState({
          popularCharger: {
            ...this.state.popularCharger,
            data: {
              name: res.data.box_id,
              location: res.data.location_name,
              charges: res.data.number_of_charges,
              evo: res.data.perc_freq_evoltion,
              lastMonth: res.data.freq_evoltion,
            },
            loading: false,
          },
        })
      } else {
        this.setState({
          popularCharger: {
            ...this.state.popularCharger,
            error: true,
            loading: false,
          },
        })

        console.log(window.strings.errorInf)
      }
    })
  }

  getChargesByCharger(data, successState, callback) {
    Charges_Service.getChargesByCharger(data, (res) => {
      if (!res.error) {
        if (res.data.length > 0 && this.state.mount != false) {
          this.chargesRef.current.scrollIntoView()
        }

        this.setState({
          loading: false,
          mount: true,
          charges: res.data,
          total_charges: res.total_data,
          ...successState,
        })
        callback && callback()
      } else {
        console.log(window.strings.errorInf)
      }
    })
  }

  selectCharger(charger, page, callback) {
    console.log("this.state.chargers: ", this.state.chargers)
    let selectedCharger = charger
    if (page === undefined) page = 1

    if (charger) {
      let data = {
        from: this.state.selectedDates.from,
        to: this.state.selectedDates.to,
        box_names: JSON.stringify([charger.box_id]),
        page: page,
        items_per_page: 10,
      }

      this.getChargesByCharger(data, { selectedCharger }, callback)
    }
  }

  filterPopular() {
    this.setState({
      loading: true,
    })
    this.bestCharger()
    this.selectCharger(null)
  }

  closeSelected() {
    this.setState(
      {
        loading: true,
        selectedCharger: null,
      },
      () => {
        this.selectCharger(null)
      }
    )
  }

  render() {
    return (
      <div
        className="dashboards-content"
        style={{ display: "flex", flexDirection: "column", paddingBottom: 80 }}
      >
        <span
          style={{
            fontSize: 32,
            color: "#041114",
            fontWeight: "bold",
            marginBottom: 50,
          }}
        >
          {window.strings.chargers}
        </span>

        {this.state.popularCharger.error || (
          <span
            ref={this.chargesRef}
            style={{ fontSize: 20, color: "#041114", fontWeight: "bold" }}
          >
            {window.strings.mostUsedCharger}
          </span>
        )}

        <PopularCharger
          filterPopular={this.filterPopular.bind(this)}
          popularCharger={this.state.popularCharger}
          selectedDatesObj={this.state.selectedDatesObj}
          selectedDates={this.state.selectedDates}
          updateDate={this.updateDate.bind(this)}
        />

        <span
          style={{
            fontSize: 20,
            color: "#041114",
            fontWeight: "bold",
            marginTop: 50,
          }}
        >
          {window.strings.allChargers}{" "}
          <span
            style={{
              borderRadius: 16,
              backgroundColor: "#041114",
              fontSize: 15,
              textAlign: "center",
              color: "#ffffff",
              padding: "3px 8px",
            }}
          >
            {this.state.chargers.length}
          </span>
        </span>
        <span
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "rgba(162, 179, 184, 0.2)",
            borderRadius: 1,
            margin: "15px 0px 32px 0px",
          }}
        />
        <SearchBar
          chargers={this.state.chargers}
          selectCharger={this.selectCharger.bind(this)}
        />
        <span style={{ marginTop: 20 }} />

        <ChargersList
          closeSelected={this.closeSelected.bind(this)}
          selectCharger={this.selectCharger.bind(this)}
          selectedCharger={this.state.selectedCharger}
          chargers={this.state.chargers}
          total_results={
            this.state.selectedCharger ? 1 : this.props.totalChargers
          }
          updateChargersResultForPage={this.updateChargersResultForPage.bind(
            this
          )}
        />

        {this.state.charges.length > 0 && (
          <>
            <span
              ref={this.chargesRef}
              style={{
                fontSize: 20,
                color: "#041114",
                fontWeight: "bold",
                marginTop: 50,
              }}
            >
              {window.strings.historyCharges}
            </span>

            <span
              style={{
                height: 1,
                width: "100%",
                backgroundColor: "rgba(162, 179, 184, 0.2)",
                borderRadius: 1,
                margin: "15px 0px 32px 0px",
              }}
            ></span>
            {this.state.charges !== undefined &&
              this.state.charges.length > 0 && (
                <ChargeList
                  charges={this.state.charges}
                  total_results={this.state.total_charges}
                  updateResultsForPage={(page, callback) =>
                    this.selectCharger(
                      this.state.selectedCharger,
                      page,
                      callback
                    )
                  }
                />
              )}
          </>
        )}
      </div>
    )
  }
}
