export default {
  successMsg: "Sucesso",
  errorMsg: "Erro",
  passwordsNotMatching: "As senhas não são iguais",
  insertPassword: "Inserir senha",
  insertEmail: "Inserir email",
  repeat: "Repetir",
  complete: "Completar",
  password: "Senha",
  definePassword: "Definir senha",
  passwordEmailSent:
    "Foi enviado um email com os dados de definição de senha e ativação de conta",
  terms: "Termos e Condições",
  privacyPolicy: "Política de Privacidade",
  recover: "Recuperar",
  insert: "Inserir",
  errorInf: "Erro ao obter informação!",
  chargers: "Carregadores",
  allChargers: "Todos os carregadores",
  historyCharges: "Histórico de carregamentos",
  name: "Nome",
  type: "Tipo",
  location: "Espaço",
  last: "Últimos",
  days: "dias",
  from: "De",
  to: "até",
  revenue: "Receita",
  mostUsedCharger: "Carregador mais utilizado",
  charges: "carregamentos",
  charges2: "Carregamentos",
  compareToLastPeriod: "face ao período anterior",
  evolution: "evolução",
  searchForCharger: "Pesquisar por um carregador",
  searchForUser: "Pesquisar por um utilizador",
  totalCharges: "Total de carregamentos",
  hour: "Hora",
  day: "Dia",
  week: "Semana",
  metrics: "Métricas",
  month: "Mês",
  year: "Ano",
  consumed: "Consumidos",
  minutesCharged: "Minutos carregados",
  chargersLastYear: "Carregamentos (último ano)",
  totalMinutesCharged: "Total de minutos carregados",
  totalCO2Spared: "Total de CO2 poupado",
  spared: "Poupado",
  user: "utilizador",
  users: "Utilizadores",
  charger: "carregador",
  date: "data",
  time: "Tempo",
  consumption: "Consumo",
  sendFeedback: "Enviar feedback",
  subject: "Assunto",
  subjectPlaceholder: "Seleccione um assunto",
  message: "Mensagem",
  goback: "Voltar",
  sendMessage: "Enviar mensagem",
  newUser: "Novo utilizador",
  allUsers: "Todos os utilizadores",
  emptyUsersList: "Comece por adicionar utilizadores à sua equipa",
  emptyUsersListDesc:
    "Após adicionar um utilizador será enviado um email para confirmação e ativação da conta ",
  warningInvalidName: "Nome Inválido!",
  warningInvalidSurName: "Apelido Inválido!",
  warningInvalidEmail: "Email Inválido!",
  warningLocations: "Introduza pelo menos um espaço!",
  createUser: "Criar um novo utilizador",
  surname: "Apelido",
  endEmail: "Endereço de email",
  manageLocations: "Espaços a gerir",
  chooseOption: "Escolha uma opção",
  createUser2: "Criar utilizador",
  cancel: "Cancelar",
  editUser: "Editar utilizador",
  saveChanges: "Guardar Alterações",
  state: "Estado",
  removeUser: "Remover utilizador",
  resendPassword: "Reenviar senha",
  noLocations: "Não existem espaços disponíveis",
  noLocationsDesc: "Não existem espaços disponíveis",
  corporate: "Parceiro",
  corporates: "Parceiros",
  hello: "Olá",
  overview: "Visão Geral",
  locations: "Espaços",
  location2: "Espaço",
  seeAll: "Ver todos",
  logout: "Terminar sessão",
  applyFilter: "Aplicar filtro",
  selectLocations: "Selecione os espaços a visualizar",
  anyLocation: "Todos os espaços",
  resume: "Resumo",
  period: "Período",
  editAccountData: "Editar dados de conta",
  editData: "Editar dados",
  idioma: "Idioma",
  portuguese: "Português",
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  weekDays: [
    { name: "Domingo", short: "Dom", isWeekend: true },
    { name: "Segunda", short: "Seg" },
    { name: "Terça", short: "Ter" },
    { name: "Quarta-feira", short: "Qua" },
    { name: "Quinta", short: "Qui" },
    { name: "Sexta", short: "Sex" },
    { name: "Sábado", short: "Sab", isWeekend: true },
  ],
  nextMonth: "Próximo mês",
  previousMonth: "Mês passado",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Seleccionar",
  freq1: "Anual",
  freq2: "Mensal",
  freq3: "Semanal",
  freq4: "Diária",
  freq5: "Horária",
  freq: "Frequência",
  apply: "Aplicar",
  selectTime: "Selecione uma frequência",
  welcomeToPowerdot: "Bem-vindo à nova PowerDot TV!",
  step1_desc:
    "Neste portal será possível consultar métricas de carregamento na sua location! ",
  continue: "Continuar",
  step2_title: "Conheça as métricas",
  step2_desc:
    "Saiba qual o carregador com a melhor performance, qual a hora do dia com mais carregamentos ou a quantidade de c02 poupado num determinado período. Organize as suas métricas por frequência e tenha visibilidade sobre os carregamentos efetuados no seu espaço. ",
  jumpIntro: "Saltar tutorial",
  start: "Próximo",
  step3_1_desc:
    "No ecrã de visão gerao poderá consultar informação absoluta e variações relativas sobre o numéro de carregamentos, energia consumida, minutos carregados e C02 poupado!",
  step3_2_desc:
    "Veja a informação filtrada por espaço com métricas especificas. ",
  step3_3_desc:
    "Veja a informação filtrada por carregador. Saiba qual o carregador com a melhor performance. ",
  export: "Exportar",
  sameTimeofLastYear: "Mesmo período do ano anterior",
  contact: "Contato telefónico",
  management: "Gestão",
  langs: {
    PT: "Português",
    EN: "Inglês",
    FR: "Francês",
    PL: "Polaco",
    ES: "Espanhol",
  },
  signinWarningTitle: "Email ou palavra-chave incorretos.",
  signinWarningDesc:
    "Os seus dados de login estão incorretos. Por favor tente novamente.",
  signin: "Iniciar Sessão",
  emailSent:
    "Utilize as suas credenciais para efetuar login, caso ainda não tenha acesso por favor contacte a Power Dot",
  saveEmail: "Guardar o meu email",
  forgotPassword: "Esqueceu-se da sua senha?",
  compareToLastPoint: "Face ao ponto anterior",
  sendFeedbackType1: "Problema com dados",
  sendFeedbackType2: "Problema com a utilização da plataforma",
  sendFeedbackType3: "Problema com acessos",
  sendFeedbackType4: "Outros",
  sendFeedbackMsgPlaceholder: "Escreva a sua mensagem",
  last30days: "Últimos 30 dias",
  withMoreCharges: "com + carregamentos",
  withMorekWh: "com + kWh consumidos",
  withMoreMinutes: "com + minutos carregados",
  withMoreSpared: "com + CO2 poupado",
  lastYearNoData: "Não existem dados em relação ao período do ano anterior.",
  active: "Activo",
  inactive: "Inactivo",
  loading: "Carregando",
  faq: "Perguntas Frequentes",
  faqLink: "https://5796726.hs-sites.com/pt-pt/faqs",
  contactUs: "Contacte-nos",
  contactUsLink: "https://5796726.hs-sites.com/pt-pt/kb-tickets/new",
}
