import React, { Component } from "react"

export default class Metric extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#ffffff",
          padding: 6,
          paddingRight: 0,
        }}
      >
        <span
          style={{
            width: 4,
            borderRadius: 2,
            backgroundColor: "#00B8EB",
            marginRight: 22,
          }}
        ></span>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingTop: "16px",
              backgroundColor: "#ffffff",
            }}
          >
            <span style={{ fontSize: 17, color: "#62757A" }}>
              {this.props.metricTotalTitle}
            </span>
            <span style={{ fontSize: 40, color: "#041114" }}>
              {this.props.metricTotal.toLocaleString()}
              <span style={{ fontSize: 20, color: "#62757A" }}>
                {" "}
                {this.props.unit}
              </span>
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor:
                this.props.evo >= 0
                  ? "rgba(7,184,101,0.08)"
                  : "rgba(208, 52, 44, 0.08)",
              border:
                this.props.evo >= 0
                  ? "1px solid rgba(7,184,101,0.08)"
                  : "1px solid rgba(208, 52, 44, 0.08)",
              borderRadius: 2,
              padding: "6px 12px",
              marginRight: 32,
              marginBottom: 4,
            }}
          >
            <span
              style={{
                flex: 1,
                fontSize: 17,
                color: this.props.evo > 0 ? "#07B865" : "#D0342C",
                fontWeight: "bold",
              }}
            >
              <span>
                {this.props.evo > 0
                  ? "+ " + this.props.lastMonth.toLocaleString()
                  : this.props.evo < 0
                  ? this.props.lastMonth.toString().slice(0, 1) +
                    " " +
                    this.props.lastMonth.toString().slice(1).toLocaleString()
                  : this.props.lastMonth.toLocaleString()}
              </span>
              <span style={{ margin: "0 5px 0 5px" }}>{this.props.unit}</span>
              <span>
                (
                {this.props.evo > 0
                  ? "+ " + this.props.evo + "%"
                  : this.props.evo < 0
                  ? this.props.evo.toString().slice(0, 1) +
                    " " +
                    this.props.evo.toString().slice(1) +
                    "%"
                  : this.props.evo + "%"}
                )
              </span>
            </span>
          </div>
        </div>
      </div>
    )
  }
}
