import React, { useState, useEffect } from "react"
import { Route } from "react-router-dom"
import { hotjar } from "react-hotjar"

import Preloader from "../../components/Preloader"

import stringfile from "../../utils/stringfile"

export default function RouteWithLoader({
  screenWithUserLang,
  component: Component,
  ...rest
}) {
  const [loaded, setLoaded] = useState(false)

  window.strings = stringfile.getStrings()

  useEffect(() => {
    if (screenWithUserLang !== true) {
      if (window.location.href.split(".")[2] !== undefined) {
        let lang = (
          window.location.href.split(".")[2][0] +
          window.location.href.split(".")[2][[1]]
        ).toUpperCase()
        window.language = lang
        window.strings = stringfile.getStrings()
      }
    }

    if (window.location.href.split(".")[2] !== undefined) {
      let lang = (
        window.location.href.split(".")[2][0] +
        window.location.href.split(".")[2][[1]]
      ).toUpperCase()

      if (lang === "FR") {
        hotjar.initialize(2732277, 6)
      } else if (lang === "ES") {
        hotjar.initialize(2763140, 6)
      } else if (lang === "PL") {
        hotjar.initialize(2763135, 6)
      } else {
        hotjar.initialize(2649909, 6)
      }
    } else {
      hotjar.initialize(2649909, 6)
    }
    setLoaded(true)
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />

          <Component {...props} />
        </>
      )}
    />
  )
}
