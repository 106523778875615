export default {
  successMsg: "Éxito",
  errorMsg: "Error",
  passwordsNotMatching: "Las contraseñas no coinciden",
  insertPassword: "Insertar contraseña",
  insertEmail: "insertar correo electrónico",
  repeat: "Repetir",
  complete: "Completo",
  password: "Contraseña",
  definePassword: "Definir la contraseña",
  passwordEmailSent:
    "Se ha enviado un correo electrónico con los detalles de la activación de la cuenta.",
  terms: "Términos y condiciones",
  privacyPolicy: "Política de privacidad",
  recover: "Recuperar",
  insert: "Insertar",
  errorInf: "Error obteniendo información!",
  chargers: "Cargadores",
  allChargers: "Todos los cargadores",
  historyCharges: "Historial de sesión",
  name: "Nombre",
  type: "Escribe",
  location: "Localización",
  last: "Último",
  days: "dias",
  from: "de",
  to: "para",
  revenue: "ingresos",
  mostUsedCharger: "Cargador más utilizado",
  charges: "Sesiones",
  charges2: "Sesiones",
  compareToLastPeriod: "comparado con el periodo anterior",
  evolution: "evolución",
  searchForCharger: "Cargador de filtro",
  searchForUser: "Buscar usuario",
  totalCharges: "Sesiones totales",
  hour: "Hora",
  day: "Día",
  week: "Semana",
  metrics: "Métrica",
  month: "Mes",
  year: "Año",
  consumed: "Consumido",
  minutesCharged: "Minutos cargados",
  chargersLastYear: "Sesiones (el año pasado)",
  totalMinutesCharged: "Minutos cobrados totales",
  totalCO2Spared: "Total de CO2 salvado",
  spared: "salvado",
  user: "usuario",
  users: "Usuarios",
  charger: "Estación de carga",
  date: "Fecha",
  time: "Tiempo",
  consumption: "Consumo",
  sendFeedback: "Enviar comentarios",
  subject: "Tema",
  subjectPlaceholder: "Seleccione un tema",
  message: "Mensaje",
  goback: "Atrás",
  sendMessage: "Enviar mensaje",
  newUser: "Nuevo Usuario",
  allUsers: "Todos los usuarios",
  emptyUsersList: "Comience a agregar usuarios a su equipo.",
  emptyUsersListDesc:
    "Después de agregar un usuario, un correo electrónico con los detalles de la activación de la cuenta se enviarán automáticamente",
  warningInvalidName: "¡Nombre inválido!",
  warningInvalidSurName: "Apellido inválido!",
  warningInvalidEmail: "¡Email inválido!",
  warningLocations: "¡Introduce al menos una ubicación!",
  createUser: "Crea un nuevo usuario",
  surname: "Apellido",
  endEmail: "Dirección de correo electrónico",
  manageLocations: "Ubicaciones para gestionar",
  chooseOption: "Elija una opción",
  createUser2: "Crear usuario",
  cancel: "Cancelar",
  editUser: "Editar usuario",
  saveChanges: "Guardar cambios",
  state: "Estado",
  removeUser: "Eliminar usuario",
  resendPassword: "Reenviar la contraseña",
  noLocations: "No hay ubicaciones disponibles.",
  noLocationsDesc: "No hay ubicaciones disponibles.",
  corporate: "Pareja",
  corporates: "Parejas",
  hello: "Hola",
  overview: "Visión general",
  locations: "Ubicaciones",
  location2: "Ubicaciones",
  seeAll: "Ver todo",
  logout: "Cerrar sesión",
  applyFilter: "Aplicar filtro",
  selectLocations: "Seleccione las ubicaciones para ver",
  anyLocation: "Todos los espacios",
  resume: "Resumen",
  period: "Período",
  editAccountData: "Editar datos de la cuenta",
  editData: "editar datos",
  idioma: "Idioma",
  portuguese: "portugués",
  months: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "Mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
  weekDays: [
    { name: "domingo", short: "sol", isWeekend: true },
    {
      name: "lunes",
      short: "Lun",
    },
    {
      name: "martes",
      short: "mar",
    },
    {
      name: "miércoles",
      short: "Mie",
    },
    {
      name: "jueves",
      short: "Jue",
    },
    {
      name: "viernes",
      short: "Vie",
    },
    {
      name: "sábado",
      short: "Sab",
      isWeekend: true,
    },
  ],
  nextMonth: "Próximo mes",
  previousMonth: "El mes pasado",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Seleccione",
  freq1: "Anual",
  freq2: "Mensual",
  freq3: "Semanalmente",
  freq4: "Diario",
  freq5: "Cada hora",
  freq: "Frecuencia",
  apply: "Solicitar",
  selectTime: "Seleccione la frecuencia",
  welcomeToPowerdot: "Bienvenido al nuevo TV PowerDot!",
  step1_desc:
    "¡En este portal puede ver las métricas de carga en su ubicación!",
  continue: "Continuar",
  step2_title: "Conocer las métricas",
  step2_desc:
    "Averigüe qué cargador tuvo el mejor rendimiento, a qué hora del día con más sesiones o la cantidad de C02 ahorró en un período determinado. Organice sus métricas por frecuencia y tenga visibilidad sobre las sesiones en su ubicación.",
  jumpIntro: "Saltar tutorial",
  start: "próximo",
  step3_1_desc:
    "En la pantalla de la Vista general, puede verificar las métricas absolutas y relativas sobre el número de sesiones, la energía consumida, los minutos cargados y C02 guardados!",
  step3_2_desc:
    "Consulte la información filtrada por ubicaciones con métricas específicas.",
  step3_3_desc:
    "Vea la información filtrada por el cargador. Averigüe qué cargador con el mejor rendimiento.",
  export: "Exportar",
  sameTimeofLastYear: "Mismo periodo del año anterior",
  contact: "Número de teléfono",
  management: "Gestión",
  langs: {
    PT: "Portugués",
    EN: "Inglés",
    FR: "Francés",
    PL: "Polaco",
    ES: "Español",
  },
  signinWarningTitle: "Correo o contraseña incorrectos",
  signinWarningDesc:
    "Sus credenciales son incorrectas, intente nuevamente o recupere la contraseña",
  signin: "Iniciar sesión Power Dot TV",
  emailSent:
    "Usa tus credenciales para iniciar sesión. Esta es una plataforma exclusiva de socio, si no tiene acceso, póngase en contacto con Power Dot",
  saveEmail: "guardar mi correo electrónico",
  forgotPassword: "¿Olvidaste tu contraseña?",
  compareToLastPoint: "comparado con el punto anterior",
  sendFeedbackType1: "Problema con los datos",
  sendFeedbackType2: "Problema con el uso de la plataforma",
  sendFeedbackType3: "Problema de acceso",
  sendFeedbackType4: "Otros",
  sendFeedbackMsgPlaceholder: "Escriba su mensaje",
  last30days: "Últimos 30 días",
  withMoreCharges: "con + sesiones",
  withMorekWh: "con + kWh consumidos",
  withMoreMinutes: "con + minutos cargados",
  withMoreSpared: "con + CO2 ahorrado",
  lastYearNoData: "No hay datos para el período del año anterior.",
  active: "Activo",
  inactive: "Inactivo",
  minutes: "Minutos",
  loading: "Cargando",
  faq: "Preguntas frecuentes",
  faqLink: "https://5796726.hs-sites.com/es/faqs",
  contactUs: "Contáctenos",
  contactUsLink: "https://5796726.hs-sites.com/es/kb-tickets/new",
}
