export default {
  successMsg: "Success",
  errorMsg: "Error",
  passwordsNotMatching: "Passwords do not match",
  insertPassword: "Insert password",
  insertEmail: "insert email",
  repeat: "Repeat",
  complete: "Complete",
  password: "Password",
  definePassword: "Define password",
  passwordEmailSent: "An email with account activation details has been sent",
  terms: "Terms and conditions",
  privacyPolicy: "Privacy Policy",
  recover: "Recover",
  insert: "Insert",
  errorInf: "Error obtaining information!",
  chargers: "Chargers",
  allChargers: "All chargers",
  historyCharges: "Session history",
  name: "Name",
  type: "Type",
  location: "Location",
  last: "Last",
  days: "days",
  from: "from",
  to: "to",
  revenue: "Revenue",
  mostUsedCharger: "Most used charger",
  charges: "Sessions",
  charges2: "Sessions",
  compareToLastPeriod: "compared with previous period",
  evolution: "evolution",
  searchForCharger: "Filter charger",
  searchForUser: "Search for user",
  totalCharges: "Total sessions",
  hour: "Hour",
  day: "Day",
  week: "Week",
  metrics: "Metrics",
  month: "Month",
  year: "Year",
  consumed: "Consumed",
  minutesCharged: "Charged minutes",
  chargersLastYear: "Sessions (last year)",
  totalMinutesCharged: "Total charged minutes",
  totalCO2Spared: "Total of CO2 saved",
  spared: "saved",
  user: "user",
  users: "Users",
  charger: "Charging Station",
  date: "Date",
  time: "Time",
  consumption: "Consumption",
  sendFeedback: "Send feedback",
  subject: "Subject",
  subjectPlaceholder: "Select a subject",
  message: "Message",
  goback: "Back",
  sendMessage: "Send Message",
  newUser: "New user",
  allUsers: "All users",
  emptyUsersList: "Start by adding users to your team",
  emptyUsersListDesc:
    "After you add a user an email with account activation details will be automatically sent",
  warningInvalidName: "Invalid name!",
  warningInvalidSurName: "Invalid Surname!",
  warningInvalidEmail: "Invalid email!",
  warningLocations: "Enter at least one location!",
  createUser: "Create a new user",
  surname: "Surname",
  endEmail: "Email address",
  manageLocations: "Locations to manage",
  chooseOption: "Choose an option",
  createUser2: "Create User",
  cancel: "Cancel",
  editUser: "Edit user",
  saveChanges: "Save changes",
  state: "Status",
  removeUser: "Remove User",
  resendPassword: "Resend password",
  noLocations: "There are no locations available",
  noLocationsDesc: "There are no locations available",
  corporate: "Partner",
  corporates: "Partners",
  hello: "Hello",
  overview: "Overview",
  locations: "Locations",
  location2: "Locations",
  seeAll: "See all",
  logout: "Log out",
  applyFilter: "Apply filter",
  selectLocations: "Select the locations to view",
  anyLocation: "All spaces",
  resume: "Summary",
  period: "Period",
  editAccountData: "Edit Account Data",
  editData: "edit data",
  idioma: "Language",
  portuguese: "Portuguese",
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  weekDays: [
    {
      name: "Sunday",
      short: "Sun",
      isWeekend: true,
    },
    {
      name: "Monday",
      short: "Mon",
    },
    {
      name: "Tuesday",
      short: "Tue",
    },
    {
      name: "Wednesday",
      short: "Wed",
    },
    {
      name: "Thursday",
      short: "Thu",
    },
    {
      name: "Friday",
      short: "Fri",
    },
    {
      name: "Saturday",
      short: "Sat",
      isWeekend: true,
    },
  ],
  nextMonth: "Next month",
  previousMonth: "Last month",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Select",
  freq1: "Yearly",
  freq2: "Monthly",
  freq3: "Weekly",
  freq4: "Daily",
  freq5: "Hourly",
  freq: "Frequency",
  apply: "Apply",
  selectTime: "Select frequency",
  welcomeToPowerdot: "Welcome to the new PowerDot TV!",
  step1_desc: "In this portal you can see charge metrics on your location!",
  continue: "Continue",
  step2_title: "Get to know the metrics",
  step2_desc:
    "Find out which charger had the best performance, what time of day with more sessions or the amount of C02 saved in a certain period. Organize your metrics by frequency and have visibility over sessions in your location.",
  jumpIntro: "Skip tutorial",
  start: "Next",
  step3_1_desc:
    "In the general view screen you can check absolute and relative metrics on the number of sessions, consumed energy, charged minutes and C02 saved!",
  step3_2_desc:
    "See the information filtered by locations with specific metrics.",
  step3_3_desc:
    "See the information filtered by charger. Find out which charger with the best performance.",
  export: "Export",
  sameTimeofLastYear: "Same period of the previous year",
  contact: "Phone number",
  management: "Management",
  langs: {
    PT: "Portuguese",
    EN: "English",
    FR: "French",
    PL: "Polish",
    ES: "Spanish",
  },
  signinWarningTitle: "Incorrect email or password",
  signinWarningDesc:
    "Your credentials are incorrect, please try again or recover password",
  signin: "Login Power Dot TV",
  emailSent:
    "Use your credentials to login. This is a partner exclusive platform, If you do not have access please contact Power Dot",
  saveEmail: "save my email",
  forgotPassword: "Forgot your password?",
  compareToLastPoint: "compared with previous point",
  sendFeedbackType1: "Problem with data",
  sendFeedbackType2: "Problem using the platform",
  sendFeedbackType3: "Problem with access",
  sendFeedbackType4: "Other",
  sendFeedbackMsgPlaceholder: "Write your message",
  last30days: "Last 30 days",
  withMoreCharges: "with + sessions",
  withMorekWh: "with + kWh consumed",
  withMoreMinutes: "with + minutes charged",
  withMoreSpared: "with + CO2 spared",
  lastYearNoData: "There are no data for the period of the previous year.",
  active: "Active",
  inactive: "Inactive",
  minutes: "Minutes",
  loading: "Loading",
  faq: "FAQ",
  faqLink: "https://5796726.hs-sites.com/faqs",
  contactUs: "Contact us",
  contactUsLink: "https://5796726.hs-sites.com/kb-tickets/new",
}
