import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons"
import {
  Col,
  Row,
  Form,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap"
import ReactLoading from "react-loading"
import PasswordStrengthBar from "react-password-strength-bar"
import iconPowerdot from "../../assets/tv/power-dot-in-partnership-uber-artboard-1.png"
import Auth from "../../Service/Auth"
import AlertModal from "./../../components/AlertModal"

export default class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      remember: false,
      setPassword: true,
      token: this.props.match.params.token,
      loginLoading: false,
      showModal: false,
      modalTitle: "",
      modalDesc: "",
      modalType: false,
      warningConfirmPassword: "",
    }
    this.register = this.register.bind(this)
  }

  register() {
    this.setState({ loginLoading: true })
    if (this.state.password === this.state.confirmPassword) {
      let data = {
        recover_token: this.state.token,
        password: this.state.password,
      }
      Auth.changePassword(data, (res) => {
        if (!res.error) {
          this.setState({
            loginLoading: false,
            showModal: true,
            modalTitle: window.strings.successMsg,
            modalDesc: res.message,
            modalType: true,
          })
        } else {
          this.setState({
            loginLoading: false,
            showModal: true,
            modalTitle: window.strings.errorMsg,
            modalDesc: res.message,
            modalType: false,
          })
        }
      })
    } else {
      this.setState({
        loginLoading: false,
        warningConfirmPassword: window.strings.passwordsNotMatching,
      })
    }
  }

  handlePassword(event) {
    this.setState({ password: event.target.value })
  }
  handleConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value })
  }

  closeModal() {
    this.setState({ showModal: false })
    if (this.state.modalTitle != "" && this.state.modalType == true) {
      window.location.href = "/Signin"
    }
  }

  renderSetPassword() {
    return (
      <Form className="mt-4">
        <Form.Group id="password" className="mb-4">
          <Form.Label>{window.strings.password}</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faUnlockAlt} />
            </InputGroup.Text>
            <Form.Control
              onChange={this.handlePassword.bind(this)}
              required
              type="password"
              placeholder={window.strings.insertPassword}
            />
          </InputGroup>
        </Form.Group>
        <PasswordStrengthBar
          style={{ height: 25 }}
          scoreWordStyle={{ fontWeight: "bold", fontSize: 14 }}
          password={this.state.password}
        />
        <Form.Group id="password" className="mb-4">
          <Form.Label>
            {window.strings.repeat}
            {" Password"}
          </Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faUnlockAlt} />
            </InputGroup.Text>
            <Form.Control
              onChange={this.handleConfirmPassword.bind(this)}
              required
              type="password"
              placeholder={window.strings.insertPassword}
            />
          </InputGroup>
          {this.state.warningConfirmPassword.length > 0 && (
            <Form.Label style={{ color: "#D0342C" }}>
              {this.state.warningConfirmPassword}
            </Form.Label>
          )}
        </Form.Group>
        <div className="auth-button" onClick={this.register}>
          {window.strings.complete}
          {this.state.loginLoading && (
            <ReactLoading
              className="auth-loading"
              type={"spin"}
              color={"#ffffff"}
              height={25}
              width={25}
            />
          )}
        </div>
      </Form>
    )
  }

  render() {
    return (
      <main>
        <span>
          <img
            style={{ resize: "contain", marginLeft: 50, marginTop: 50 }}
            src={iconPowerdot}
          />
        </span>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center"></p>
            <Row className="justify-content-center form-bg-image">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500"
                  style={{ minHeight: 550 }}
                >
                  <div className="text-center text-md-center mb-5 mt-md-0">
                    <h3 className="mb-0">{window.strings.definePassword}</h3>
                    <span>{window.strings.passwordEmailSent}</span>
                  </div>
                  {this.renderSetPassword()}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <div
          style={{
            margin: 50,
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <a
              style={{
                marginRight: 30,
                fontSize: 17,
                color: "#62757A",
                cursor: "pointer",
              }}
            >
             {window.strings.terms}
            </a>
            <a
              style={{
                marginRight: 30,
                fontSize: 17,
                color: "#62757A",
                cursor: "pointer",
              }}
            >
              {window.strings.privacyPolicy}
            </a>
          </div>
          <div
            style={{
              maxWidth: 300,
              backgroundColor: "rgba(1, 180, 224, 0.1)",
              padding: 16,
              paddingRight: 60,
            }}
          >
            <span style={{ fontSize: 15, color: "#62757A" }}>
              Pretende criar uma conta para aceder à plataforma?{" "}
              <Card.Link
                as={Link}
                to={Routes.Signup.path}
                className="fw-bold"
                style={{ cursor: "pointer" }}
              >
                Saiba mais.
              </Card.Link>
            </span>
          </div>
        </div> */}
        <AlertModal
          showModal={this.state.showModal}
          closeModal={this.closeModal.bind(this)}
          title={this.state.modalTitle}
          desc={this.state.modalDesc}
          success={this.state.modalType}
        />
      </main>
    )
  }
}
